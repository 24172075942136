/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
// @mui
import { Stack, Box, Card, Typography } from '@mui/material';
import { Iconify } from '../../../components';
import { NotFound } from '../../../components/empty-content';
import { tableHeaderStyle, tableCellStyle, typeIconBGStyle, referralIconBGStyle, textStyle, sourceTopCardStyle } from '../../../styles/MetaSourceStyle';
// redux
import { useSelector } from '../../../redux/store';

// ------------------------------------------------------------------

export default function MetaSource({ leadDetail }) {
    const metaData = leadDetail && leadDetail?.lead_metadata;
    const leadSourceMetaData = leadDetail && leadDetail?.lead_source && leadDetail?.lead_source?.metadata;
    const { referral, sourceType } = useSelector((state) => state.schema);
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    return (
        <Stack>
            <Box>
                <Card sx={sourceTopCardStyle}>
                    <Box sx={{ width: '50%', borderRight: '1px solid #DFE3E8' }}>
                        <Typography variant="h6" sx={{ textAlign: 'center', color: '#000', mb: '10px' }}>Lead Source Type</Typography>
                        <Box sx={typeIconBGStyle}>
                            {sourceType?.map((e1, index) => {
                                if (leadDetail?.lead_source?.type === e1.value) {
                                    return <Iconify key={index} icon={e1.icon} color={'#FF6C40'} width={29} height={29} />;
                                }
                                return '';
                            })}
                        </Box>
                        <Typography sx={textStyle}>
                            {sourceType?.map((e1) => {
                                let latestData = '';
                                if (leadDetail?.lead_source?.type === e1.value) {
                                    latestData = e1.label;
                                }
                                return latestData;
                            })}
                        </Typography>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <Typography variant="h6" sx={{ textAlign: 'center', color: '#000', mb: '10px' }}>Lead Source Referral</Typography>
                        <Box sx={referralIconBGStyle}>
                            {referral?.length > 0 && referral?.map((e1, index) => {
                                if (leadDetail?.lead_source?.referral === e1.value) {
                                    return <Iconify icon={e1.icon} key={index} color={'#E02D69'} width={29} height={29} />;
                                }
                                return '';
                            })}
                        </Box>
                        <Typography sx={textStyle}>
                            {referral?.length > 0 && referral?.map((e1) => {
                                let latestData = '';
                                if (leadDetail?.lead_source?.referral === e1.value) {
                                    latestData = e1.label;
                                }
                                return latestData;
                            })}
                        </Typography>
                    </Box>
                </Card>
                <Card>
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={tableHeaderStyle(isLight)}>UTM parameter</Typography>
                        <Typography sx={tableHeaderStyle(isLight)}>Value</Typography>
                    </Box>
                    {metaData && metaData?.map((el) => (
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={tableCellStyle}>{el.key}</Typography>
                            <Typography sx={tableCellStyle}>{el.value}</Typography>
                        </Box>
                    ))}
                    {leadSourceMetaData && Object.entries(leadSourceMetaData).map((el) => el[1] !== '' && (
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={tableCellStyle}>{el[0]}</Typography>
                            <Typography sx={tableCellStyle}>{el[1]}</Typography>
                        </Box>
                    ))}
                    {metaData && metaData.length === 0 && Object.entries(leadSourceMetaData ?? {}).length === 0 && <NotFound />}
                </Card>
            </Box>
        </Stack>
    );
}

MetaSource.propTypes = {
    leadDetail: PropTypes.any,
};