import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// components
import { setIsLoadingWidgetCounter } from 'src/redux/slices/widgetCounter';
import WidgetButtons from './WidgetButtons';
import CustomWidgetCard from './CustomWidgetCard';
// redux
import { dispatch, useSelector } from '../../redux/store';
// hooks
import { useToggle } from '../../hooks';
// utils
import groupedTreatments from '../../utils/groupedTreatments';

const BookedTableReportsWidget = ({ onWidgetClick, dateRangeProps }) => {
    const { toggle, onOpen, onClose } = useToggle(true);
    const { treatmentsList: { results } } = useSelector((state) => state.practiceTreatment);

    const [currentWidget, setCurrentWidget] = useState('');

    const treatments = groupedTreatments(results);

    const handleOpen = () => onOpen();

    const handleClose = () => onClose();

    const widgetArray = [
        { key: 'BOOKED', icon: '', value: '0', text: 'Attendance need to be recorded' },
        { key: '2', icon: '', value: 'invisalign', text: 'Consultation booked for Invisalign', isTreatment: true },
        { key: '3', icon: '', value: 'bonding', text: 'Consultation booked for Bonding', isTreatment: true },
        { key: '4', icon: '', value: 'implants', text: 'Consultation booked for Implants', isTreatment: true },
        { key: '5', icon: '', value: 'whitening', text: 'Consultation booked for Whitening', isTreatment: true },
    ];

    useEffect(() => {
        dispatch(setIsLoadingWidgetCounter(true))
    })
    const handleWidgetClick = (value) => {
        setCurrentWidget(value);
        onWidgetClick(treatments.filter((ele) => ele.name.toLowerCase().includes(value)));
    };

    return (
        <>
            <WidgetButtons
                toggle={toggle}
                dateRangeProps={dateRangeProps}
                handleOpen={handleOpen}
                handleClose={handleClose}
                type="ConsultantBooked"
            />
            {toggle && (
                <CustomWidgetCard handleWidgetClick={handleWidgetClick} widgetArray={widgetArray} currentWidget={currentWidget} />
            )}
        </>
    );
};

export default BookedTableReportsWidget;

BookedTableReportsWidget.propTypes = {
    onWidgetClick: PropTypes.func,
    dateRangeProps: PropTypes.any,
};
