/* eslint-disable react/prop-types */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import getStyles from 'src/styles/whatsappStyles';
import useWindowSize from 'src/hooks/windowSizeListner';
import { Box, CircularProgress } from '@mui/material';
import { dispatch } from 'src/redux/store';
import { initializeFacebookAuthToken } from 'src/redux/slices/whatsapp';
import { shallowEqual, useSelector } from 'react-redux';
import MessageContainer from './MessageContainer';

function WhatsAppDetailsView(props) {
    const { leadDetail } = props;
    const [width, height] = useWindowSize();
    const theme = useTheme();
    const styles = getStyles(theme, { width, height });
    const { isFacebookAuthTokenLoading } =
        useSelector(
            (state) => ({
                isFacebookAuthTokenLoading: state?.whatsApp?.isFacebookAuthTokenLoading,
            }),
            shallowEqual
        );

    React.useEffect(() => {
        dispatch(initializeFacebookAuthToken());
    }, []);

    if (isFacebookAuthTokenLoading) {
        return (
            <Box sx={styles.initPageStyles}>
                <CircularProgress />
            </Box>
        );
    }

    return <Box sx={styles.singleChatContainerDetail}>
        <MessageContainer
            windowDimension={{ width, height }}
            chatScreenData={{
                name: `${leadDetail?.first_name} ${leadDetail?.last_name}`,
                phoneNumber: leadDetail?.lead_contact?.phone ?? '',
                leadId: leadDetail?.id,
            }}
            setChatScreenData={() => { }}
        />
    </Box>;
}

export default WhatsAppDetailsView;