/* eslint-disable no-unneeded-ternary */
/* eslint-disable arrow-body-style */
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import {
    Box, Typography, MenuItem
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { MENU_OPTIONS } from '../constants/SettingsMenuOptions';
import { Iconify } from '.';
import { useGetCurrentUser } from '../hooks';
import { getMenuOptions } from '../utils/settingUtils';
import { settingsTitleStyle, settingsMenuListBoxStyle, settingsIconStyle, settingsMenuItemStyle } from '../styles/SettingsMenuStyle';

// ----------------------------------------------------------------------------------------------------------
const SettingsMenu = ({ selected }) => {
    const theme = useTheme();
    const { isSuperUser } = useGetCurrentUser();

    return (
        <Box sx={{ '@media(max-width: 1200px)': { display: 'none' } }}>
            <Typography sx={settingsTitleStyle}>Settings</Typography>
            <Box sx={settingsMenuListBoxStyle}>
                {getMenuOptions(MENU_OPTIONS, isSuperUser)?.map((option) => (
                    <MenuItem selected={selected === option.value ? true : false} key={option.label} to={option.linkTo} component={RouterLink} className='icon' sx={settingsMenuItemStyle(alpha, theme)}>
                        <Iconify icon={option.icon} width={22} height={22} sx={settingsIconStyle} />
                        <Typography sx={{ color: '#637381', fontSize: '14px' }}>{option.label}</Typography>
                    </MenuItem>
                ))}
            </Box>
        </Box>
    );
};

SettingsMenu.propTypes = {
    selected : PropTypes.string,
};

export default SettingsMenu;