import { Box }  from '@mui/material';
import capitalizeFirstLetter from './capitalizedFunc';

export const getStatusColour = (status, value) => {
    if(status === 'FAILED_TO_ATTEND_CONSULTATION' ){
        if(value === 'background'){
            return 'rgba(255, 72, 66, 0.16)';
        }
        if(value === 'color'){
            return '#B72136';
        }
    }
    if(status === 'NEW'){
        if(value === 'background'){
            return 'rgba(24, 144, 255, 0.16)';
        }
        if(value === 'color'){
            return '#0C53B7';
        }
    }
    if(status === 'CONSULTATION_BOOKED' || status === 'CONSULTATION_ATTENDED' ){
        if(value === 'background'){
            return 'rgba(255, 193, 7, 0.16)';
        }
        if(value === 'color'){
            return '#B78103';
        }
    }
    if(status === 'CREDIT_CHECK' ){
        if(value === 'background'){
            return 'rgba(255, 72, 66, 0.16)';
        }
        if(value === 'color'){
            return '#B72136';
        }
    }
    if(status === 'CLOSED' || status === 'TREATMENT_FINISHED' ){
        if(value === 'background'){
            return 'rgba(145, 158, 171, 0.16)';
        }
        if(value === 'color'){
            return '#637381';
        }
    }
    if(status === 'TREATMENT_BOOKED' ){
        if(value === 'background'){
            return 'rgba(84, 214, 44, 0.16)';
        }
        if(value === 'color'){
            return '#229A16';
        }
    }
    if(status === 'TREATMENT_STARTED' ){
        if(value === 'background'){
            return 'rgba(24, 144, 255, 0.16)';
        }
        if(value === 'color'){
            return '#0C53B7';
        }
    }
    return null;
};

export const getMessage = (value, firstName, lastName, treatment, group, method) => {
    const content = (() => {
        if (group === 'TODO') {
            return `${capitalizeFirstLetter(method === 'OTHER' ? 'Discuss with' : method)} <b>${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}</b> about <b>${treatment}</b>`;
        }
        switch (value) {
            case 'NEW':
                return `${capitalizeFirstLetter(method === 'OTHER' ? 'Discuss with' : method)} <b>${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}</b> about <b>${treatment}</b>`;
            case 'CONSULTATION_BOOKED':
                return `<b>Record</b> if <b>${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)} attended</b> their <b>${treatment} consultation</b>`;
            case 'CONSULTATION_ATTENDED':
                return `<b>${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}</b> is <b>considering ${treatment}. Follow up</b> to discuss.`;
            case 'FAILED_TO_ATTEND_CONSULTATION':
                return `<b>${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)} failed to attend</b> their <b>${treatment}</b> consultation. <b>Follow up.</b>`;
            case 'CLOSED':
                return `<b>${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)} completed</b> their <b>${treatment}</b> treatment.`;
            default:
                return `${capitalizeFirstLetter(method === 'OTHER' ? 'Discuss with' : method)} <b>${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}</b> about <b>${treatment}</b>`;
        }
    })();

    return (
        <Box sx={{ fontSize: '16px', lineHeight: '19px' }} dangerouslySetInnerHTML={{ __html: content }} />
    );
};