/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import moment from 'moment';
import GoogleAutocomplete from 'react-google-autocomplete';
import { useDebounce } from 'use-lodash-debounce';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Box, Card, Typography, FormLabel, MenuItem, Select, OutlinedInput, FormControlLabel, Checkbox, FormControl, InputLabel, FormGroup, FormHelperText } from '@mui/material';
// components
import { fetchMergeTagValueForLeads, fetchMergeTagValueForTreatments, resetTreatmentAndLead } from 'src/redux/slices/mergeTags';
import ReactHtmlParser from 'react-html-parser';
import TreatmentEditPopover from '../../pages/dashboard/tables/AllLeadTable/TreatmentEditPopover';
import UserSearchDropdown from '../UserSearchDropdown';
import TreatmentFlowComponent from '../treatmentComponents/TreatmentFlowComponent';
import Iconify from '../Iconify';
import { FormProvider, RHFTextField, RHFRadioGroup, RHFSelect } from '../hook-form';
import CustomRadioGroupComponent from '../followup-callScript/CustomRadioGroupComponent';
import CustomLeadInquiryFields from '../followup-callScript/CustomLeadInquiryFields';
import CustomScriptMobileDatePicker from '../followup-callScript/CustomScriptMobileDatePicker';
import ScriptDateTimePicker from '../followup-callScript/ScriptDateTimePicker';
import CustomCombinedFields from '../followup-callScript/CustomCombinedFields';
import CustomLeadNameAutocomplete from '../followup-callScript/CustomLeadNameAutocomplete';
// store
import { dispatch, useSelector } from '../../redux/store';
import { clearTaskLead } from '../../redux/slices/lead';
// utils/hooks/validation
import { useAddTreatment, useAuth } from '../../hooks';
import capitalizeFirstLetter from '../../utils/capitalizedFunc';
import { getAddressField, handleSearch, searchLeadHandler, setAddressData } from '../../utils/callScriptCommonFunc';
import { SingleScriptPreviewSchema } from '../../validations/validations';
import { inputKeys } from '../../constants/LeadDetailConst';
// style
import { common, grey, widget } from '../../theme/palette';
import { menuItemStyle } from '../../styles/PersonalDetailsStyle';
import { gridStyle } from '../../styles/AddAppointmentStyle';
import { closeIconStyle, singleScriptHeaderBoxStyle, titleDesktopStyle, fieldLabelStyle, requiredTextStyle, mainLabelBoxStyle, labelBoxStyle, testFieldWrapperStyle, nameTypoStyle } from '../../styles/CallScripts';

const SingleScriptPreview = ({ scriptData }) => {
    const { user } = useAuth();
    const [expand, setExpand] = useState(false);
    const fieldData = scriptData?.sections?.map((section) => {
        const updatedFields = section?.fields?.filter((field) => field?.is_enabled);
        return updatedFields && updatedFields.length !== 0 ? { ...section, fields: updatedFields } : null;
    }).filter(Boolean);
    const { practice } = useSelector((state) => state.practice);
    const [searchQuery, setSearchQuery] = useState('');
    const { taskSearchLead } = useSelector((state) => state.lead);
    const debouncedValue = useDebounce(searchQuery, 1000);
    const { referral } = useSelector((state) => state.schema);
    const mergeTags = useSelector((state) => state.mergeTags);
    const referralValue = (value, data) => referral?.length > 0 && referral?.filter((res) => res[value] === data.referral);
    const [newtreatmentData, setNewTreatment] = useState('');
    const [assignToState, setAssignToState] = useState([]);
    const [anchorEditTreatment, setAnchorEditTreatment] = useState(null);
    const [priceAmountError, setPriceAmountError] = useState(false);
    const [teethAmountError, setTeethAmountError] = useState(false);

    const {
        toggle: EditTreatment,
        onOpen: onEditTreatment,
        onClose: onCloseEditTreatment
    } = useAddTreatment();

    useEffect(() => {
        handleSearch(searchQuery);
    }, [debouncedValue]);

    useEffect(() => {
        if (searchQuery?.length === 0) {
            dispatch(clearTaskLead());
        }
    }, [taskSearchLead]);

    const defaultValues = {
        type: '',
        inquired: '',
        speaking_to: `${user?.salutation ? `${capitalizeFirstLetter(user?.salutation)}.` : ''} ${user?.first_name} ${user?.last_name}`,
        leadType: '',
        firstName: '',
        phone_number: '',
        email: '',
        treatment: '',
        teethAmount: null,
        treatmentValue: '',
        referral: '',
        bookedWith: [],
        title: 'MR',
        isRegister: false,
        preferredContactMethod: ''
    };

    const methods = useForm({
        resolver: yupResolver(SingleScriptPreviewSchema(fieldData, practice?.preferences?.is_accepting_nhs_patients)),
        defaultValues,
    });

    const {
        control,
        setValue,
        clearErrors,
        watch,
    } = methods;

    const values = watch();

    React.useEffect(() => {
        if (values?.lead_id) {
            dispatch(fetchMergeTagValueForLeads(values?.lead_id));
        }
        if (values?.lead_id && values?.treatment_id) {
            dispatch(fetchMergeTagValueForTreatments(values?.lead_id, values?.treatment_id));
        }
    }, [values?.lead_id, values?.treatment_id]);

    React.useEffect(() => () => dispatch(resetTreatmentAndLead()), [])

    const handleSearchLead = (value) => {
        if (value) {
            setValue('leadName', `${value?.first_name} ${value?.last_name}`);
            setSearchQuery(`${value?.first_name} ${value?.last_name}`);
            setValue('lead_id', value?.id);
            setValue('treatment_id', value?.lead_treatments[0]?.id);
        }
        clearErrors('leadName');
        const leadArr = value;
        if (leadArr) {
            setValue('treatmentValue', leadArr?.lead_treatments[0]?.price.toString());
            setValue('teethAmount', leadArr?.lead_treatments[0]?.leadtreatment_specification?.teeth_count);
            clearErrors('treatmentValue');
            setNewTreatment(leadArr?.lead_treatments?.length === 0 ? '' : leadArr?.lead_treatments[0]?.treatment);
            setValue('treatment', leadArr?.lead_treatments?.length === 0 ? '' : leadArr?.lead_treatments[0]?.treatment);
            setValue('type', leadArr?.healthcare_provider);
            setValue('leadType', leadArr?.lead_source?.type);
            setValue('preferredContactMethod', leadArr?.lead_contact?.preferred_contact_method);
            setValue('bookedWith', leadArr?.assignees?.length > 0 ? leadArr?.assignees : []);
            setAssignToState(leadArr?.assignees?.length > 0 ? leadArr?.assignees : []);
            setValue('referral', leadArr?.lead_source?.referral || '');
            setValue('firstName', leadArr?.first_name);
            setValue('lastName', leadArr?.last_name);
            setValue('email', leadArr?.lead_contact?.email);
            setValue('phone_number', leadArr?.lead_contact?.phone);
            setValue('title', leadArr?.salutation || 'MR');
            setValue('dob', leadArr?.dob ? new Date(moment(leadArr?.dob, 'DD/MM/YYYY')) : null);
            setValue('address_line_1', leadArr?.lead_address?.address_line_1 || '');
            setValue('address_line_2', leadArr?.lead_address?.address_line_2 || '');
            setValue('county', leadArr?.lead_address?.county || '');
            setValue('city', leadArr?.lead_address?.city || '');
            setValue('postcode', leadArr?.lead_address?.postcode || '');
            setValue('isRegister', leadArr?.is_registered_patient || false);
            clearErrors();
        } else {
            setValue('type', '');
            setValue('referral', '');
            setValue('leadType', '');
        }
    };

    const handleChange = (event, data) => setValue(data, event.target.value);

    const handleReferral = (event, data) => {
        if (data) setValue('referral', data.value);
    };

    const handleClickAwayLister = () => onCloseEditTreatment();

    const handleSaveTreatment = (data) => {
        setNewTreatment(data.selectedTreatment);
        setValue('treatmentValue', data.treatmentPrice);
        setValue('treatment', data.selectedTreatment);
        setValue('teethAmount', data.teethAmount);
        onCloseEditTreatment();
    };

    const handleTreatment = (event) => {
        onEditTreatment();
        setAnchorEditTreatment(event.currentTarget);
    };

    const amountHandleChange = (value) => {
        setValue('treatmentValue', value);
        if (value > 0) {
            setPriceAmountError(false);
            document.body.classList.remove('points-event');
            return;
        }
        setPriceAmountError(true);
    };

    const handleChangeTeeth = (e) => {
        const value = e.target.value > 0 ? e.target.value : '';
        setValue('teethAmount', value);
        setTeethAmountError(value === 0);
    };

    const numberKeyDownFunc = (evt) => {
        const isControlC = (evt.ctrlKey && evt.key === 'c') || (evt.metaKey && evt.key === 'c');
        const isControlV = (evt.ctrlKey && evt.key === 'v') || (evt.metaKey && evt.key === 'v');
        const isAllowedKey = inputKeys.includes(evt.key) || (/^[0-9]*$/).test(evt.key);
        if (!(isControlC || isControlV || isAllowedKey)) {
            evt.preventDefault();
        }
    };

    const [dataMap, setDataMap] = React.useState({});

    React.useEffect(() => {
        setDataMap(prv => ({ ...prv, ...(mergeTags?.mtValPractice ?? {}), ...(mergeTags?.mtValUser ?? {}) }))
        if (!mergeTags?.mtValLoadingLeads && !mergeTags?.mtValLoadingTreatments) {
            setDataMap(prv => ({ ...prv, ...(mergeTags?.mtValTreatments ?? {}), ...(mergeTags?.mtValLeads ?? {}) }))
        }
    }, [mergeTags?.mtValLoadingLeads, mergeTags?.mtValLoadingTreatments]);

    const parseMergeTags = (datalabels) => (
        // Use regex to find all words starting with '$' followed by alphanumeric characters or underscores
        datalabels?.replace(/\$[a-zA-Z0-9_]+/g, (match) => {
            // Remove the '$' sign to get the actual key
            const key = match.slice(1);

            // Return the value from the dataMap if it exists, otherwise return the original field
            return Object.prototype.hasOwnProperty.call(dataMap, key) ? dataMap[key] : match;
        }) ?? datalabels);


    return (
        <FormProvider methods={methods}>
            <Card sx={{ maxWidth: expand ? '100%' : '1000px', mx: 'auto' }}>
                <Box sx={singleScriptHeaderBoxStyle}>
                    <Typography sx={titleDesktopStyle}>Default Enquiry Follow-up Script</Typography>
                    <Box sx={closeIconStyle}>
                        <Iconify onClick={() => setExpand(!expand)} icon={'eva:expand-fill'} width={'18px'} height={'18px'} sx={{ mr: '0', cursor: 'pointer' }} />
                    </Box>
                </Box>
                <Box sx={{ p: '24px 24px 0' }}>
                    {fieldData?.map((item, index) => (
                        <Box sx={{ mb: '30px' }} key={index}>
                            {item.fields?.length > 0 && <Box sx={{ mb: '30px', borderBottom: `1px solid ${common.platinum}`, pb: '8px' }}>
                                <Typography sx={{ fontSize: '22px' }}>{(index === 0 ? 1 : index + 1)}. {item.name}</Typography>
                            </Box>}
                            {item.fields.map((data, index) => (
                                <Box sx={{ mb: '30px' }} key={index}>
                                    {data?.field_type &&
                                        <>
                                            <Box sx={mainLabelBoxStyle}>
                                                <Box sx={labelBoxStyle}>
                                                    <Iconify icon={'heroicons:chat-bubble-left-right-20-solid'} width={'24px'} height={'24px'} sx={{ mr: 1, color: common.lightSlateBlue }} /> {data?.field_type?.field_type === 'label' ? "Ask" : "Ask"}
                                                </Box>
                                                <FormLabel style={{ color: grey[900], fontSize: "1rem" }}>
                                                    {data?.field_type?.field_type === 'label' ? (
                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                                                            <div>
                                                                {ReactHtmlParser(parseMergeTags(data.description))}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        parseMergeTags(data?.label)
                                                    )}{data?.is_required && <span style={requiredTextStyle}>(Required)</span>}
                                                </FormLabel>
                                            </Box>
                                        </>
                                    }
                                    {data?.field_type?.field_type === 'text' && <>
                                        {data.description !== '' &&
                                            <Box sx={{ ...mainLabelBoxStyle, bgcolor: widget.notificationLightGreen }}>
                                                <FormLabel sx={{ ...fieldLabelStyle, fontWeight: '400' }}>
                                                    {data.description}
                                                </FormLabel>
                                            </Box>
                                        }
                                        {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                            {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                        </Typography>}
                                        <RHFTextField
                                            name={`text_${[data.id]}`}
                                            multiline rows={5}
                                        />
                                    </>}
                                    {data?.field_type?.field_type === 'label' && <>
                                        {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                            {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                        </Typography>}
                                    </>}
                                    {data?.field_type?.field_type === 'lead_search_select' && <>
                                        {data.description !== '' &&
                                            <Box sx={{ ...mainLabelBoxStyle, bgcolor: widget.notificationLightGreen }}>
                                                <FormLabel sx={{ ...fieldLabelStyle, fontWeight: '400' }}>
                                                    {data.description}
                                                </FormLabel>
                                            </Box>
                                        }
                                        {data?.field_type?.attribute === 'lead_search' &&
                                            <>
                                                {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                                    {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                                </Typography>}
                                                <CustomLeadNameAutocomplete
                                                    control={control}
                                                    handleSearchLead={(e, value) => handleSearchLead(value)}
                                                    searchLeadHandler={(event, value, reason) => searchLeadHandler(value, reason, setSearchQuery)}
                                                    searchQuery={searchQuery}
                                                />
                                                <CustomCombinedFields />
                                            </>
                                        }
                                    </>}
                                    {data?.field_type?.field_type === 'practice_treatment_search_select' && <>
                                        {data?.field_type?.attribute === 'treatment_search' &&
                                            <>
                                                {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                                    {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                                </Typography>}
                                                <Grid container spacing={1} sx={{ ...gridStyle('callScript'), display: 'flex', m: '10px 0 16px' }}  >
                                                    {EditTreatment && (
                                                        <TreatmentEditPopover
                                                            treatment={newtreatmentData || ''}
                                                            leadTreatmentSpecification={{ teeth_count: values.teethAmount }}
                                                            open={EditTreatment}
                                                            anchorEl={anchorEditTreatment}
                                                            handleClickAwayLister={handleClickAwayLister}
                                                            treatmentPrice={values.treatmentValue}
                                                            handleSaveTreatment={handleSaveTreatment}
                                                            isFlow
                                                        />
                                                    )}
                                                    <TreatmentFlowComponent treatmentData={newtreatmentData} teethAmount={values.teethAmount} handleTreatment={handleTreatment} amount={values.treatmentValue} amountError={priceAmountError} amountHandleChange={amountHandleChange} handleChangeTeeth={handleChangeTeeth} teethAmountError={teethAmountError} />
                                                </Grid>
                                            </>
                                        }
                                    </>}
                                    {data?.field_type?.field_type === 'practice_user_search_select' && <>
                                        {data?.field_type?.attribute === 'user_search' &&
                                            <>
                                                {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                                    {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                                </Typography>}
                                                <UserSearchDropdown name={'bookedWith'} control={control} placeholder='Assign to' sx={{ mt: '10px' }} assignToState={assignToState} setAssignTo={setAssignToState} />
                                            </>
                                        }
                                    </>}
                                    {data?.field_type?.field_type === 'char' && <>
                                        {data.description !== '' &&
                                            <Box sx={{ ...mainLabelBoxStyle, bgcolor: widget.notificationLightGreen }}>
                                                <FormLabel sx={{ ...fieldLabelStyle, fontWeight: '400' }}>
                                                    {data.description}
                                                </FormLabel>
                                            </Box>
                                        }
                                        {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                            {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                        </Typography>}
                                        {(data?.field_type?.attribute !== 'lead_phone' && data?.field_type?.attribute !== 'lead_email' && data?.field_type?.attribute !== 'lead_address') && <RHFTextField
                                            name={`char_${[data.id]}`}
                                            inputProps={{ maxLength: data?.field_type?.max_length }}
                                        />}
                                        {data?.field_type?.attribute === 'lead_phone' && <>
                                            <Box sx={testFieldWrapperStyle}>
                                                <RHFTextField
                                                    name={'phone_number'}
                                                    type='string'
                                                    inputProps={{ maxLength: 15 }}
                                                    onKeyDown={(evt) => numberKeyDownFunc(evt)}
                                                />
                                            </Box>
                                        </>}
                                        {data?.field_type?.attribute === 'lead_email' && <>
                                            <Box sx={testFieldWrapperStyle}>
                                                <RHFTextField
                                                    name={'email'}
                                                />
                                            </Box>
                                        </>}
                                        {data?.field_type?.attribute === 'lead_address' && <>
                                            <Box>
                                                <Box
                                                    sx={{
                                                        display: 'grid',
                                                        columnGap: 2,
                                                        rowGap: 3,
                                                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                                                        mb: 2,
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <Controller
                                                        name='address'
                                                        control={control}
                                                        render={({ field }) => (
                                                            <>
                                                                <Iconify icon={'eva:search-outline'} sx={{
                                                                    position: 'absolute',
                                                                    left: '15px',
                                                                    top: '16px'

                                                                }} color='grey.500' width={24} height={24} />
                                                                <GoogleAutocomplete
                                                                    apiKey='AIzaSyD_7FXxCWgBUUd6922n33WKqrfRDsXXxj8'
                                                                    onPlaceSelected={(place, inputRef) => {
                                                                        const addresses = place.address_components;
                                                                        setAddressData(addresses, inputRef.value, setValue);
                                                                        field.onChange(inputRef.value);
                                                                    }}
                                                                    style={{
                                                                        paddingLeft: '45px',
                                                                        fontSize: '0.875rem'
                                                                    }}
                                                                    options={{
                                                                        types: ['establishment', 'geocode'],
                                                                        fields: ['address_components', 'formatted_address'],
                                                                        componentRestrictions: { country: 'uk' }
                                                                    }}
                                                                    defaultValue={field.value}
                                                                    className='address-input'
                                                                    placeholder='Search address'
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Box>
                                                {getAddressField(values) && <Box
                                                    sx={{
                                                        display: 'grid',
                                                        columnGap: 2,
                                                        rowGap: 3,
                                                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                                        mb: 2,
                                                    }}
                                                >
                                                    <RHFTextField name='address_line_1' label='Address line 1' />
                                                    <RHFTextField name='address_line_2' label='Address line 2' />
                                                    <RHFTextField name='city' label='City' />
                                                    <RHFTextField name='county' label='County' />
                                                    <RHFTextField name='postcode' label='Postcode' />
                                                </Box>}
                                            </Box>
                                        </>}
                                    </>}
                                    {data?.field_type?.field_type === 'boolean' && <>
                                        {data.description !== '' &&
                                            <Box sx={{ ...mainLabelBoxStyle, bgcolor: widget.notificationLightGreen }}>
                                                <FormLabel sx={{ ...fieldLabelStyle, fontWeight: '400' }}>
                                                    {data.description}
                                                </FormLabel>
                                            </Box>
                                        }
                                        {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                            {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                        </Typography>}
                                        <RHFRadioGroup
                                            name={`boolean_${[data.id]}`}
                                            options={[
                                                { label: 'Yes', value: 'yes' },
                                                { label: 'No', value: 'no' },
                                            ]}
                                            size='small'
                                            sx={{ margin: '-6px 2px 0', width: '100%' }}
                                        />
                                    </>}
                                    {data?.field_type?.field_type === 'choice' &&
                                        <>
                                            {data.description !== '' &&
                                                <Box sx={{ ...mainLabelBoxStyle, bgcolor: widget.notificationLightGreen }}>
                                                    <FormLabel sx={{ ...fieldLabelStyle, fontWeight: '400' }}>
                                                        {data.description}
                                                    </FormLabel>
                                                </Box>
                                            }
                                            {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                                {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                            </Typography>}
                                            {data?.field_type?.attribute !== 'lead_source_type' &&
                                                <>
                                                    {data?.field_type?.ui_type === 'DROPDOWN' && <RHFSelect
                                                        name={`checkbox_${[data.id]}`}
                                                        label='Select dropdown'
                                                        SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                                    >
                                                        {(data?.field_type?.choices || data?.choices)?.map((option, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={option}
                                                                sx={menuItemStyle}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </RHFSelect>}
                                                    {data?.field_type?.ui_type === 'MULTI_SELECT' &&
                                                        <FormControl sx={{ width: '100%' }}>
                                                            <InputLabel id='demo-multiple-name-label'>Multi select dropdown</InputLabel>
                                                            <Controller
                                                                render={({ field, fieldState: { error } }) => (
                                                                    <>
                                                                        <Select
                                                                            labelId='demo-multiple-name-label'
                                                                            id='demo-multiple-name'
                                                                            name={`multiCheckbox_${[data.id]}`}
                                                                            multiple
                                                                            sx={{ width: '100%' }}
                                                                            input={<OutlinedInput label='Multi select dropdown' />}
                                                                            value={field.value || []}
                                                                            onChange={(event) => field.onChange(event.target.value)}
                                                                        >
                                                                            {(data?.field_type?.choices || data?.choices)?.map((options, index) => (
                                                                                <MenuItem
                                                                                    key={index}
                                                                                    value={options}
                                                                                >
                                                                                    {options}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                        {(!!error) && (
                                                                            <FormHelperText error={!!error}>{error ? error?.message : ''}</FormHelperText>
                                                                        )}
                                                                    </>
                                                                )}
                                                                control={methods.control}
                                                                name={`multiCheckbox_${[data.id]}`}
                                                            />
                                                        </FormControl>
                                                    }
                                                    {data?.field_type?.ui_type === 'CHECKBOX' && (
                                                        <>
                                                            {data?.field_type?.attribute !== 'lead_is_registered_patient' && <Controller
                                                                render={({ field, fieldState: { error } }) => (
                                                                    <FormGroup>
                                                                        {(data?.field_type?.choices || data?.choices)?.map((el, index) => (
                                                                            <FormControlLabel
                                                                                key={index}
                                                                                control={
                                                                                    <Checkbox
                                                                                        name={`typeCheckbox_${[data.id]}_${index}`}
                                                                                        checked={field.value.includes(el)}
                                                                                        onChange={(e) => {
                                                                                            const newValue = e.target.checked
                                                                                                ? [...field.value, el]
                                                                                                : field.value.filter((value) => value !== el);
                                                                                            field.onChange(newValue);
                                                                                        }}
                                                                                        value={el}
                                                                                    />
                                                                                }
                                                                                label={el}
                                                                                sx={{ width: '100%', display: 'flex', '.MuiTypography-root': { width: '100%' } }}
                                                                            />
                                                                        ))}
                                                                        {(!!error) && (
                                                                            <FormHelperText error={!!error}>{error ? error?.message : ''}</FormHelperText>
                                                                        )}
                                                                    </FormGroup>
                                                                )}
                                                                control={methods.control}
                                                                name={`typeCheckbox_${[data.id]}`}
                                                                defaultValue={[]}
                                                            />}
                                                            {data?.field_type?.attribute === 'lead_is_registered_patient' &&
                                                                <FormGroup sx={{ gap: 2 }}>
                                                                    <FormControlLabel
                                                                        key={1}
                                                                        control={
                                                                            <Controller
                                                                                control={control}
                                                                                name={'isRegister'}
                                                                                render={({ field }) => (
                                                                                    <Checkbox
                                                                                        onChange={e => {
                                                                                            field.onChange(e.target.checked);
                                                                                        }}
                                                                                        checked={field.value}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        }
                                                                        label={'Is registered patient'}
                                                                        sx={{ width: 'fit-content' }}
                                                                    />
                                                                </FormGroup>
                                                            }
                                                        </>
                                                    )}
                                                    <CustomRadioGroupComponent methods={methods} data={data} />
                                                </>
                                            }
                                            <CustomLeadInquiryFields values={values} data={data} handleChange={handleChange} handleReferral={handleReferral} referralValue={referralValue} />
                                        </>
                                    }
                                    {data?.field_type?.field_type === 'datetime' && <>
                                        {data.description !== '' &&
                                            <Box sx={{ ...mainLabelBoxStyle, bgcolor: widget.notificationLightGreen }}>
                                                <FormLabel sx={{ ...fieldLabelStyle, fontWeight: '400' }}>
                                                    {data.description}
                                                </FormLabel>
                                            </Box>
                                        }
                                        {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                            {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                        </Typography>}
                                        <ScriptDateTimePicker data={data} />
                                        <CustomScriptMobileDatePicker data={data} control={control} />
                                    </>}
                                </Box>
                            ))}
                        </Box>
                    ))}
                </Box>
            </Card>
        </FormProvider>
    );
};

export default SingleScriptPreview;

SingleScriptPreview.propTypes = {
    scriptData: PropTypes.object,
};