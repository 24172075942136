import axios from 'axios';
import { updateError } from '../redux/slices/user';
import { dispatch } from '../redux/store';
// config
import { HOST_API, ADMIN_HOST_API } from '../config';
import { setSession } from './jwt';

// ----------------------------------------------------------------------

axios.create({
    baseURL: HOST_API,
});

axios.interceptors.response.use(
    (response) => response,
    (error) => Promise.resolve((error.response && error.response) || 'Something went wrong')
);

const refreshToken = async () => new Promise((resolve, reject) => {
    const data = {
        refresh: localStorage.getItem('refreshToken')
    };
    try {
        const config = {
            method: 'post',
            url: `${HOST_API}/api/v1/auth/token/refresh/`,
            headers: {
                'Content-Type': 'application/json'
            },
            data
        };
        axios(config).then((res) => {
            setSession(res.data.access, data.refresh);
            resolve(res);
        }).catch((error) => {
            setSession(null, null);
            resolve(error.response);
        });
    } catch (error) {
        reject(error);
    }
});

const accessToken = () => localStorage.getItem('accessToken');

export const cacheTimer = 0.5 * 60 * 1000;

export async function callApi (uri, method = 'GET', data = null, isUnAuthorized = false) {
    try {
        const config = {
            method,
            url: `${HOST_API}${uri}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data
        }; 
        if (!isUnAuthorized) {
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken()}`;
        }
        let response = await axios(config).then((res) => res).catch((error) => error.response);
        const { status } = response;

        if (status === 500 || status === 503) {
            dispatch(updateError({
                message: 'Something went wrong!',
                success: false
            }));
            return false;
        }

        if (status === 401) {
            const success = await refreshToken();
            if (success.status === 200) {
                axios.defaults.headers.common.Authorization = `Bearer ${accessToken()}`;
                response = await axios(config).then((res) => res).catch((error) => error.response);
            } else if (success.status === 401) {
                window.location.href = '/';
                return success;
            } else {
                return success;
            }
        }
        return response;
    } catch (error) {
        return error.response;
    }
}

export async function callAdminApi (uri, method = 'GET', data = null) {
    try {
        const config = {
            method,
            url: `${ADMIN_HOST_API}${uri}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data
        };
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken()}`;
        let response = await axios(config).then((res) => res).catch((error) => error.response);
        const { status } = response;

        if (status === 500 || status === 503) {
            dispatch(updateError({
                message: 'Something went wrong!',
                success: false
            }));
            return false;
        }

        if (status === 401) {
            const success = await refreshToken();
            if (success.status === 200) {
                axios.defaults.headers.common.Authorization = `Bearer ${accessToken()}`;
                response = await axios(config).then((res) => res).catch((error) => error.response);
            } else if (success.status === 401) {
                window.location.href = '/';
                return success;
            } else {
                return success;
            }
        }
        return response;
    } catch (error) {
        return error.response;
    }
}

export const isCacheValid = async (cache, key, staleTime, currentTime, isCacheApi) => {
    
    if (cache && cache[key] && isCacheApi) {
        const { data, timestamp } = await cache[key];
        const isCacheExpired = (currentTime - timestamp) > staleTime;
        if (!isCacheExpired) {
            return data
        } 
            return null
        
    }
    return null
};