/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-return */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';
// @mui
import { Container } from '@mui/material';
// components
import PropTypes from 'prop-types';
import NewLead from '../../NewLead';
import { useSettingsContext } from '../../../../components/settings/context';
import { HeaderBreadcrumbs } from '../../../../components';
// redux
import { useSelector, dispatch } from '../../../../redux/store';
import { getNewLead, getCounter, selectLeads } from '../../../../redux/slices/lead';
import { getAllTagItem } from '../../../../redux/slices/tagsCreate';
import { clearTablePreferences, getTablePreferences } from '../../../../redux/slices/tablePreferences';
// routes/hooks/utils/constant
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { useReloadWidgetCounter } from '../../../../hooks';
import useColumnVisibilityChange from '../../../../hooks/useColumnVisibilityChange';
import { DASHBOARD_WIDGET_COUNTER, TAB_HEAD_FOLLOW_UP } from '../../../../constants/newLead';
import { AllLeadLabels } from '../../../../constants/TableDataConst';

const AllLeadsTables = ({ displayForCategorySummary, leadCategoryType, categoryId, dateRange }) => {
    const [leadData, setLead] = useState([]);
    const { userId, columnVisibilityModel, handleColumnVisibilityChange, setColumnVisibilityModel, handleColumnOrderChange, pinnedColumns, setPinnedColumns } =
        useColumnVisibilityChange(displayForCategorySummary ? 'Category Summary Table' : 'All Leads');

    const { lead, counter } = useSelector((state) => state.lead);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { reloadWidgetCounter } = useReloadWidgetCounter();

    const settings = useSettingsContext();

    const updateLeadPage = ({ limit, offset, filterStatus, order, orderId, searchQuery, treatment, startDate, endDate, tags, referral, isCacheApi, assigneeIds }) => {
        if (practiceId) {
            if (filterStatus === 'FOLLOW_UP') {
                if (displayForCategorySummary)
                    dispatch(getNewLead({ id: practiceId, isArchived: displayForCategorySummary, category: categoryId, limit, offset, filterStatus: leadCategoryType, order, orderId, searchQuery, treatment, startDate: dateRange?.startDate, endDate: dateRange?.endDate, contactedCount: 1, tags, referral, type: 'Dashboard', isCacheApi, assigneeIds }));
                else
                    dispatch(getNewLead({ id: practiceId, limit, offset, filterStatus, order, orderId, searchQuery, treatment, startDate, endDate, contactedCount: 1, tags, referral, type: 'Dashboard', isCacheApi, assigneeIds }));
                return;
            }
            if (displayForCategorySummary)
                dispatch(getNewLead({ id: practiceId, isArchived: displayForCategorySummary, category: categoryId, limit, offset, filterStatus: leadCategoryType, order, orderId, searchQuery, treatment, startDate: dateRange?.startDate, endDate: dateRange?.endDate, contactedCount: filterStatus === 'NEW' ? 0 : null, tags, referral, type: filterStatus === 'NEW' ? 'Dashboard' : '', isCacheApi, assigneeIds }));
            else
                dispatch(getNewLead({ id: practiceId, limit, offset, filterStatus, order, orderId, searchQuery, treatment, startDate, endDate, contactedCount: filterStatus === 'NEW' ? 0 : null, tags, referral, type: filterStatus === 'NEW' ? 'Dashboard' : '', isCacheApi, assigneeIds }));
        }
    };

    const reloadCounter = ({ limit, offset, order, orderId, searchQuery, treatment, startDate, endDate, tags, referral, assigneeIds, isCacheApi }) => {
        TAB_HEAD_FOLLOW_UP.forEach((data) => {
            if (displayForCategorySummary)
                dispatch(getCounter({ id: practiceId, isArchived: displayForCategorySummary, category: categoryId, limit, offset, filterStatus: data.value, order, orderId, searchQuery, treatment, startDate: dateRange?.startDate, endDate: dateRange?.endDate, contactedCount: data.value === 'FOLLOW_UP' ? 1 : data?.value === 'NEW' ? 0 : null, tags, referral, type: (data.value === 'FOLLOW_UP' || data?.value === 'NEW') ? 'Dashboard' : '', assigneeIds, isCacheApi }));
            else
                dispatch(getCounter({ id: practiceId, category: categoryId, limit, offset, filterStatus: data.value, order, orderId, searchQuery, treatment, startDate, endDate, contactedCount: data.value === 'FOLLOW_UP' ? 1 : data?.value === 'NEW' ? 0 : null, tags, referral, type: (data.value === 'FOLLOW_UP' || data?.value === 'NEW') ? 'Dashboard' : '', assigneeIds,isCacheApi }));
        });
        reloadWidgetCounter({ array: DASHBOARD_WIDGET_COUNTER, startDate, endDate, isCacheApi });
    };

    useEffect(() => {
        if (practiceId) {
            dispatch(getAllTagItem(practiceId, 30, 0));
            dispatch(selectLeads({ leadId: [], type: '', loading: false }));
        }
    }, [practiceId]);

    useEffect(() => {
        if (practiceId && userId) {
            const body = {
                practiceUserId: userId
            };
            dispatch(getTablePreferences(body));
        }
        return () => {
            dispatch(clearTablePreferences());
        };
    }, [practiceId, userId]);

    useEffect(() => {
        if (lead.results) {
            setLead(lead.results);
        }
    }, [lead]);

    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            {displayForCategorySummary ? <HeaderBreadcrumbs
                heading={TAB_HEAD_FOLLOW_UP?.filter(e => e?.value === leadCategoryType)?.[0]?.label}
                links={[{ name: 'Category Summary' },]}
            /> : <HeaderBreadcrumbs
                heading='All Leads'
                links={[
                    { name: 'Dashboard', href: PATH_DASHBOARD.root },
                    { name: 'All Leads' },
                ]}
            />}
            <NewLead
                title='New Leads'
                leadData={leadData !== null && leadData.length > 0 && practiceId ? leadData : []}
                updateLeadPage={updateLeadPage}
                practiceId={practiceId && practiceId?.toString()}
                pageCount={lead.count}
                type={'Dashboard'}
                tableTitle={displayForCategorySummary ? 'Category Summary Table' : 'All Leads'}
                counter={counter}
                reloadCounter={reloadCounter}
                tableLabels={AllLeadLabels}
                columnVisibilityModel={columnVisibilityModel}
                handleColumnVisibilityChange={handleColumnVisibilityChange}
                handleColumnOrderChange={handleColumnOrderChange}
                pinProps={{ pinnedColumns, setPinnedColumns }}
                setColumnVisibilityModel={setColumnVisibilityModel}
                displayForCategorySummary={displayForCategorySummary}
                leadCategoryType={leadCategoryType}
            />
        </Container>
    );
};

AllLeadsTables.propTypes = {
    displayForCategorySummary: PropTypes.bool,
    leadCategoryType: PropTypes.string,
    categoryId: PropTypes.number,
    dateRange: PropTypes.object
}

AllLeadsTables.defaultProps = {
    displayForCategorySummary: false,
    leadCategoryType: "",
    categoryId: null,
    dateRange: {}
}

export default AllLeadsTables;
