import { createSlice } from '@reduxjs/toolkit';

import { current } from 'immer';
import { callApi, isCacheValid } from '../../utils/apiCall';

import { updateError } from './user';

import { dispatch, store } from '../store';
import { getPracticeId } from './clincheck';



const initialState = {
    isLoading: false,
    widgetCounter: [],
    cacheTaskWidgetCounter: {},
    cacheWidgetCounter: {},
    cacheTreatmentWidgetPrice: {},
    isLoadingWidgetCounter: true
};

const slice = createSlice({
    name: 'widgetCounter',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        setWidgetCounter(state, action) {
            const currentState = current(state.widgetCounter);
            if (shouldUpdateState(currentState, action.payload)) {
                state.widgetCounter = action.payload;
            }

        },
        setCacheTaskWidgetCounter(state, action) {
            state.cacheTaskWidgetCounter = { ...state.cacheTaskWidgetCounter, ...action.payload };
        },
        setCacheWidgetCounter(state, action) {
            state.cacheWidgetCounter = { ...state.cacheWidgetCounter, ...action.payload };
        },
        setCacheTreatmentWidgetPrice(state, action) {
            state.cacheTreatmentWidgetPrice = { ...state.cacheTreatmentWidgetPrice, ...action.payload };
        },
        setIsLoadingWidgetCounter(state, action) {
            state.isLoadingWidgetCounter = action.payload;
        }

    }
});

export default slice.reducer;

export const { setWidgetCounter, reset, setIsLoadingWidgetCounter } = slice.actions;

const cacheTimer = 3 * 60 * 1000

const shouldUpdateState = (currentState, newState) => JSON.stringify(currentState) !== JSON.stringify(newState);
export const getDateRange = (date) => {
    const filteredDate = date.split(',');
    const customStartDate = `${filteredDate[0]} 00:00:00`;
    const customEndDate = `${filteredDate[1]} 23:59:00`;
    return `${customStartDate},${customEndDate}`;
};

export const getQuery = (data) => {
    let query = '?fields={}&is_archived=false';
    if (data?.dateRange) {
        query += `&created_at__range=${getDateRange(data?.dateRange)}`;
    }
    if (data.treatmentStatusForPrice === 'TREATMENT_STARTED' || data.status === 'TREATMENT_STARTED') {
        if (data.startDate && data.endDate) {
            query += `&state_transition_params={"state":"TREATMENT_STARTED","timestamp_gte":"${data.startDate} 00:00:00","timestamp_lte":"${data.endDate} 23:59:00"}`;
        }
        // else if (!data.appointmentDateRange) {
        //     query += '&state_transition_params={"state":"TREATMENT_STARTED"}';
        // }
    }
    if (data?.status) {
        query += `&lead_treatments__status=${data?.status}`;
    }
    if (data?.treatmentStatus) {
        query += `&lead_treatment__status=${data?.treatmentStatus}`;
    }
    if (data?.treatment) {
        query += `&lead_treatments__treatment__in=${data?.treatment}`;
    }
    if (data?.reminderContactedCount) {
        query += '&contacted_count=0';
    }
    if (data?.contactedCount) {
        query += `&lead_treatments__lead_contacted_counts__count__gte=${data?.contactedCount}`;
    }
    if (data?.clinCheckStatus) {
        query += `&lead_treatments__leadtreatment_clincheck__status__in=${data?.clinCheckStatus}`;
    }
    if (data?.isLeadNotes) {
        query += '&lead_notes__note__in=AWAITING_RESPONSE';
    }
    if (data.appointmentDateRange) {
        query += `&lead_treatments__appointments__datetime__range=${getDateRange(data.appointmentDateRange)}`;
    }
    if (data.treatmentPrice) {
        query += '&aggregate[Sum]=lead_treatments__custom_price';
    }
    if (data.isContactedCountNull) {
        query += '&lead_treatments__lead_contacted_counts__isnull=true';
    }
    if (data.isClinCheckTable) {
        query += '&lead_treatments__leadtreatment_clincheck__isnull=false';
    }
    if (data.group__in) {
        query += '&group__in=FOLLOW_UP,TODO';
        query += '&lead__is_archived=false';
    }
    if (data.completedFilter) {
        query += `&is_completed=${data.is_completed}`;
    }
    if (data.isTaskDateTimeRange) {
        query += `&datetime__date__range=${data?.taskDateRange}`;
    }
    if (data.treatmentStatusForPrice) {
        query += `&lead_treatments__status__in=${data?.treatmentStatusForPrice}`;
    }
    if (data.appStatus) {
        query += `&lead_treatments__appointments__status=${data?.appStatus}`;
    }
    if (data.appStatusForPrice) {
        query += `&lead_treatments__appointments__status__in=${data?.appStatusForPrice}`;
    }
    return query;
};

export const setCounter = (data, counter, teethCount) => {
    const { widgetCounter } = store.getState().widgetCounter;
    const existingItemIndex = widgetCounter.findIndex(item => item.key === data.key);
    if (existingItemIndex !== -1) {
        const updatedWidgetCounter = [...widgetCounter];
        updatedWidgetCounter[existingItemIndex] = {
            ...updatedWidgetCounter[existingItemIndex],
            count: counter,
            teethCount
        };
        dispatch(setWidgetCounter(updatedWidgetCounter));
    } else {
        dispatch(setWidgetCounter([
            ...widgetCounter,
            { key: data.key, count: counter, teethCount }
        ]));
    }
};


export function getWidgetCounter(data, isCacheApi) {
    return async () => {
        try {
            const { type } = data ?? {};
            const isTreatmentV2Enabled = type === "TREATMENT_STARTED";
            let query = getQuery(data);
            if (isTreatmentV2Enabled) {
                query = query.replaceAll("lead_treatments__", "");
                query = `${query}&healthcare_provider=PRIVATE`
            }

            const cacheKey = isTreatmentV2Enabled ?
                `api/v1/practices/${getPracticeId()}/lead-treatments-flat/${query}` :
                `api/v1/practices/${getPracticeId()}/leads/${query}`;

            let result;

            const { cacheWidgetCounter } = store.getState().widgetCounter;

            const currentTime = new Date().getTime();

            result = await isCacheValid(cacheWidgetCounter, cacheKey, cacheTimer, currentTime, isCacheApi);
            let teethCount = 0;

            if (!result || !isCacheApi) {

                dispatch(setIsLoadingWidgetCounter(true));
                const response = isTreatmentV2Enabled ?
                    await callApi(`/api/v1/practices/${getPracticeId()}/lead-treatments-flat/${query}&fields={leadtreatment_specification}`) :
                    await callApi(`/api/v1/practices/${getPracticeId()}/leads/${query}`);
                if (isTreatmentV2Enabled) {
                    teethCount = (response?.data?.results ?? [])?.reduce((sum, item) => sum + (item?.leadtreatment_specification?.teeth_count || 0), 0) ?? 0;
                }
                if (response.status === 200) {
                    result = response.data;
                    dispatch(slice.actions.setCacheWidgetCounter({ [cacheKey]: { data: result, timestamp: currentTime } }));
                } else {
                    dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
                }
            }
            setCounter(data, data.isTreatmentNotPresent ? 0 : result?.count, teethCount);
            dispatch(setIsLoadingWidgetCounter(false))

        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
export function getTreatmentWidgetPrice(data, isCacheApi) {
    return async () => {
        try {

            const cacheKey = `api/v1/practices/${getPracticeId()}/reports/leads/${getQuery(data)}`;

            let result;

            const { cacheTreatmentWidgetPrice } = store.getState().widgetCounter;

            const currentTime = new Date().getTime();


            result = await isCacheValid(cacheTreatmentWidgetPrice, cacheKey, cacheTimer, currentTime, isCacheApi);

            if (!result || !isCacheApi) {
                dispatch(setIsLoadingWidgetCounter(true))
                const response = await callApi(`/api/v1/practices/${getPracticeId()}/reports/leads/${getQuery(data)}`);

                if (response.status === 200) {
                    result = response.data;
                    dispatch(slice.actions.setCacheTreatmentWidgetPrice({ [cacheKey]: { data: result, timestamp: currentTime } }));
                } else {
                    dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
                }
            }

            setCounter(data, result?.data?.sum_lead_treatments__custom_price);
            dispatch(setIsLoadingWidgetCounter(false))

        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
export function getTaskWidgetCounter(data, isCacheApi) {
    return async () => {
        try {

            const cacheKey = `api/v1/practices/${getPracticeId()}/reminders/${getQuery(data)}`;

            let result;

            const { cacheTaskWidgetCounter } = store.getState().widgetCounter;

            const currentTime = new Date().getTime();

            result = await isCacheValid(cacheTaskWidgetCounter, cacheKey, cacheTimer, currentTime, isCacheApi);

            if (!result || !isCacheApi) {

                dispatch(setIsLoadingWidgetCounter(true))

                const response = await callApi(`/api/v1/practices/${getPracticeId()}/reminders/${getQuery(data)}`);

                if (response.status === 200) {
                    result = response.data;
                    dispatch(slice.actions.setCacheTaskWidgetCounter({ [cacheKey]: { data: result, timestamp: currentTime } }));
                } else {
                    dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
                }
            }

            setCounter(data, result?.count);
            dispatch(setIsLoadingWidgetCounter(false))

        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

