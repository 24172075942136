/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
// libraries
import { Box, Typography, Grid, Card, Divider } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { isEmpty } from 'lodash';
// components
import { UserSearchDropdown } from '../../../../components';
import { FormProvider, RHFRadioGroup } from '../../../../components/hook-form';
import TreatmentEditPopover from '../../../../pages/dashboard/tables/AllLeadTable/TreatmentEditPopover';
import TreatmentFlowComponent from '../../../../components/treatmentComponents/TreatmentFlowComponent';
import RHFToggleGroup from './RHFToggleGroup';
// store
import { useSelector, dispatch } from '../../../../redux/store';
import { addAppointment, UpdateAppointment, updateSteps, saveNewLeadDetail, updateCompleteStatus } from '../../../../redux/slices/lead';
// hooks/validation
import { useAddTreatment, useTreatmentAndAppointment } from '../../../../hooks';
import { TreatmentAndAppointmentSchema } from '../../../../validations/validations';
import { localStorageGetItem, localStorageSetItem } from '../../../../utils/storage-available';
// style
import { gridStyle, appointmentGridStyle, boxStyle } from '../../../../styles/TreatmentAndAppointmentStyle';

const TreatmentAndAppointment = forwardRef(({ isOverview, handleNext, type }, ref) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { newLeadDetail, tableRowLead } = useSelector((state) => state.lead);
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { contactMethod, appointmentType, appointmentMethod } = useSelector((state) => state.schema);
    const [treatmentData, setTreatment] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const { getTreatment, getAssignUser, getType, handleSuccessMessage } = useTreatmentAndAppointment({ isOverview, type });
    const [assignToState, setAssignToState] = useState(newLeadDetail?.lead_appointment && getAssignUser() || []);
    const [anchorEditTreatment, setAnchorEditTreatment] = useState(null);
    const [priceAmountError, setPriceAmountError] = useState(false);
    const [teethAmountError, setTeethAmountError] = useState(false);
    const localTreatmentData = localStorageGetItem('localTreatmentData') ? JSON.parse(localStorageGetItem('localTreatmentData')) || {} : {};

    const { toggle: EditTreatment, onOpen: onEditTreatment, onClose: onCloseEditTreatment } = useAddTreatment();

    const getDate = (date) => moment(date, 'DD/MM/YYYY HH:mm:ss');

    const [amount, setAmount] = useState(getTreatment('price') || 0.00);

    const defaultValues = {
        treatment: getTreatment('treatment'),
        treatmentValue: amount,
        teethAmount: getTreatment('teeth') || null,
        radioButton: getType(type),
        appoinmentDate: newLeadDetail && newLeadDetail.lead_appointment && new Date(getDate(newLeadDetail.lead_appointment.datetime)) || null,
        bookedWith: newLeadDetail?.lead_appointment && getAssignUser() || [],
        practiceContactedVia: newLeadDetail && newLeadDetail.lead_appointment && newLeadDetail.lead_appointment.contacted_via || 'PHONE',
        preferredContactMethod: newLeadDetail && newLeadDetail.lead_appointment && newLeadDetail.lead_appointment.method || 'IN_PRACTICE',
        appointmentType: newLeadDetail && newLeadDetail.lead_appointment && newLeadDetail.lead_appointment.type || 'CONSULTATION'
    };

    useEffect(() => {
        if (defaultValues.treatment) {
            setTreatment(defaultValues.treatment);
        }
    }, [defaultValues.treatment]);

    const methods = useForm({
        resolver: yupResolver(TreatmentAndAppointmentSchema(assignToState)),
        mode: 'onSubmit',
        defaultValues,
    });

    const { control, setValue, watch, getValues, formState: { errors }, handleSubmit, trigger } = methods;

    useEffect(() => {
        if (!isEmpty(localTreatmentData)) {
            if (localTreatmentData.treatment) {
                setTreatment(localTreatmentData.treatment);
                setValue('treatment', localTreatmentData.treatment);
                setValue('teethAmount', localTreatmentData.teethAmount);
            }
            if (localTreatmentData.price) {
                setAmount(localTreatmentData.price);
            }
        }
    }, []);

    useEffect(() => {
        if (assignToState?.length > 0) {
            trigger('bookedWith');
        }
    }, [assignToState, trigger]);

    const values = watch();

    const practiceUserId = (e) => e ? practiceUser?.results?.filter(el => e?.includes(`${el?.user?.salutation || ''} ${el?.user?.first_name || ''} ${el?.user?.last_name || ''}`))?.map((el) => el?.id) : [];

    const onSubmit = (data, status) => {
        if (data.radioButton === '') {
            enqueueSnackbar('Please select one of the option for appointment', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' }
            });
            return;
        }
        const detail = {};
        detail.addAppointment = false;
        detail.lead_treatment = {
            treatment: data.treatment,
            custom_price: amount,
            teethAmount: data.teethAmount
        };
        if (data.radioButton === 'Yes') {
            detail.addAppointment = true;
            detail.lead_appointment = {
                datetime: `${moment(getValues('appoinmentDate')).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
                method: data.preferredContactMethod,
                contacted_via: data.practiceContactedVia,
                assignees: assignToState?.map((el) => el?.id),
                type: data?.appointmentType
            };
        }
        dispatch(saveNewLeadDetail(detail));
        if (status === 'finish') {
            dispatch(updateCompleteStatus(true));
            return;
        }
        handleNext(true);
    };

    const handleSuccess = (data) => {
        if (data && data.appoiment === 'Yes') {
            if (data.treatmentId) {
                callAppointment(data.treatmentId);
                return;
            }
            if (type !== 'followUp') {
                handleSuccessMessage({ data });
                handleNext(true);
                return;
            }
        }
        handleSuccessMessage({ data });
        handleNext(true);
    };

    const callAppointment = (treatmentId) => {
        const contactedVia = getValues('practiceContactedVia');
        if (treatmentId && type === 'followUp') {
            if (newLeadDetail && newLeadDetail.lead_appointment) {
                if (moment(getDate(newLeadDetail.lead_appointment.datetime)).format('DD-MM-YYYY HH:mm:ss') !== moment(getValues('appoinmentDate')).format('DD-MM-YYYY HH:mm:ss') ||
                    newLeadDetail.lead_appointment.method !== getValues('preferredContactMethod') ||
                    newLeadDetail.lead_appointment.contacted_via !== getValues('practiceContactedVia')) {
                    const updateAppointment = {
                        lead_treatment: treatmentId,
                        datetime: `${moment(getValues('appoinmentDate')).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
                        method: getValues('preferredContactMethod'),
                        contacted_via: contactedVia[0],
                        assignees: practiceUserId(getValues('bookedWith')),
                        type: getValues('appointmentType')
                    };
                    updateAppointment.lead = tableRowLead.lead_id;
                    updateAppointment.id = newLeadDetail.lead_appointment.id;
                    dispatch(UpdateAppointment(updateAppointment, practiceId, handleSuccess));
                }
                else {
                    handleNext(true);
                }
            }
            else {
                const followUpAppointment = {
                    lead_treatment: treatmentId,
                    datetime: `${moment(getValues('appoinmentDate')).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
                    method: getValues('preferredContactMethod'),
                    contacted_via: contactedVia[0],
                    assignees: practiceUserId(getValues('bookedWith')),
                    type: getValues('appointmentType')
                };
                dispatch(addAppointment(followUpAppointment, practiceId, tableRowLead.lead_id, handleSuccess));
            }
        }
        else if (treatmentId) {
            if (newLeadDetail && newLeadDetail.lead_appointment) {
                if (moment(getDate(newLeadDetail.lead_appointment.datetime)).format('DD-MM-YYYY HH:mm:ss') !== moment(getValues('appoinmentDate')).format('DD-MM-YYYY HH:mm:ss') ||
                    !getValues('bookedWith')?.includes(newLeadDetail.lead_appointment.note) ||
                    newLeadDetail.lead_appointment.method !== getValues('preferredContactMethod') ||
                    newLeadDetail.lead_appointment.contacted_via !== getValues('practiceContactedVia')) {
                    const updateAppointment = {
                        lead_treatment: treatmentId,
                        datetime: `${moment(getValues('appoinmentDate')).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
                        method: getValues('preferredContactMethod'),
                        contacted_via: contactedVia[0],
                        assignees: practiceUserId(getValues('bookedWith')),
                        type: getValues('appointmentType')
                    };
                    updateAppointment.lead = newLeadDetail.lead_treatment.lead;
                    updateAppointment.id = newLeadDetail.lead_appointment.id;
                    dispatch(UpdateAppointment(updateAppointment, practiceId, handleSuccess));
                }
                else {
                    handleNext(true);
                }
            }
            else {
                const appointmentDetail = {
                    lead_treatment: treatmentId,
                    datetime: `${moment(getValues('appoinmentDate')).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
                    method: getValues('preferredContactMethod'),
                    contacted_via: contactedVia[0],
                    assignees: practiceUserId(getValues('bookedWith')),
                    type: getValues('appointmentType')
                };
                dispatch(addAppointment(appointmentDetail, practiceId, newLeadDetail.id, handleSuccess));
            }
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status), formErrorHandler)();
            }
        }),
        [amount, assignToState]
    );

    const radioHandler = (e) => {
        switch (e.target.value) {
            case 'Yes':
                setValue('radioButton', e.target.value);
                if (getValues('appoinmentDate') !== null) {
                    updateNewStep(getValues('appoinmentDate'));
                }
                break;
            case 'No':
                setValue('radioButton', e.target.value);
                dispatch(updateSteps(null));
                break;
            default:
                break;
        }
    };

    const updateNewStep = (value) => {
        const attaendence = moment(value).format('YYYY-MM-DD HH:mm:ss') > moment().format('YYYY-MM-DD HH:mm:ss');
        dispatch(updateSteps(attaendence ? 'payment' : 'attendance'));
    };

    useEffect(() => {
        if (newLeadDetail) {
            if (getValues('appoinmentDate') && newLeadDetail.lead_appointment && newLeadDetail.lead_appointment.datetime) {
                const date = getDate(newLeadDetail.lead_appointment.datetime);
                updateNewStep(date);
            }
        }
    }, [newLeadDetail]);

    const handleClickAwayLister = () => onCloseEditTreatment();

    const handleSaveTreatment = (data) => {
        setTreatment(data.selectedTreatment);
        setAmount(data.treatmentPrice);
        setValue('treatment', data.selectedTreatment);
        setValue('teethAmount', data.teethAmount);
        localStorageSetItem('localTreatmentData', JSON.stringify({ treatment: data.selectedTreatment, teethAmount: data.teethAmount, price: data.treatmentPrice }));
        onCloseEditTreatment();
    };

    const handleTreatment = (event) => {
        onEditTreatment();
        setAnchorEditTreatment(event.currentTarget);
    };

    const formErrorHandler = () => {
        if (type !== 'followUp') return;
        enqueueSnackbar('Required field is missing', {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
    };

    const handleSetAssign = (values) => {
        setAssignToState(values);
        setValue('bookedWith', values);
    };

    const amountHandleChange = (value) => {
        setAmount(value);
        localStorageSetItem('localTreatmentData', JSON.stringify({ treatment: treatmentData, teethAmount: values.teethAmount, price: value }));
        if (value > 0) {
            setPriceAmountError(false);
            document.body.classList.remove('points-event');
            return;
        }
        setPriceAmountError(true);
    };

    const handleChangeTeeth = (e) => {
        const value = e.target.value > 0 ? e.target.value : '';
        setValue('teethAmount', value);
        localStorageSetItem('localTreatmentData', JSON.stringify({ treatment: treatmentData, teethAmount: value, price: amount }));
        setTeethAmountError(value === 0);
    };

    const handleChangeDate = ({ newValue, field }) => {
        field.onChange(newValue);
        updateNewStep(newValue);
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{ p: 3, boxShadow: 'none' }}>
                <Typography variant='h6' sx={{ pb: 2 }}>
                    {type === 'followUp' ? 'Book an appointment' : 'Treatment'}
                </Typography>
                <Divider />
                <Grid container spacing={1} sx={gridStyle(type)} >
                    {EditTreatment && (
                        <TreatmentEditPopover
                            treatment={treatmentData || ''}
                            leadTreatmentSpecification={{ teeth_count: values.teethAmount }}
                            open={EditTreatment}
                            anchorEl={anchorEditTreatment}
                            handleClickAwayLister={handleClickAwayLister}
                            treatmentPrice={amount}
                            handleSaveTreatment={handleSaveTreatment}
                            isFlow
                        />
                    )}
                    <TreatmentFlowComponent treatmentData={treatmentData} teethAmount={values.teethAmount} handleTreatment={handleTreatment} amount={amount} errorMessage={errors.treatment?.message} amountError={priceAmountError} amountHandleChange={amountHandleChange} handleChangeTeeth={handleChangeTeeth} teethAmountError={teethAmountError} />
                </Grid>
                {type === 'followUp' ? null : <Box sx={{ mb: '40px', display: 'flex', flexDirection: 'column' }}>
                    Book an appointment now?
                    <RHFRadioGroup
                        name={'radioButton'}
                        onClick={(e) => radioHandler(e)}
                        options={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                        ]}
                        sx={{ mt: '2px', width: '100%', flexDirection: 'row' }}
                    />
                </Box>}
                <Box sx={{ mt: '0' }}>
                    {values.radioButton === 'Yes' ?
                        <>
                            {type === 'followUp' ? null :
                                <>
                                    <Typography variant='h6' sx={{ pb: 2 }}>Book an appointment</Typography>
                                    <Divider sx={{ mb: '34px' }} />
                                </>
                            }
                            <Grid sx={appointmentGridStyle}>
                                <Box>
                                    <Controller
                                        name='appoinmentDate'
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <DateTimePicker
                                                {...field}
                                                label='Appointment date (Required)'
                                                value={field.value || null}
                                                format='dd/MM/yyyy HH:mm'
                                                onChange={(newValue) => handleChangeDate({ newValue, field })}
                                                ampm={false}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error: !!error,
                                                        helperText: error?.message,
                                                        onKeyDown: (e) => e.preventDefault()
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                                <UserSearchDropdown name={'bookedWith'} control={control} label='Booked with (Required)' assignToState={assignToState} setAssignTo={handleSetAssign} error={errors?.bookedWith} />
                            </Grid>
                            <Box sx={{ ...boxStyle, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                                <RHFToggleGroup control={control} label='Appointment method (Required)' name='preferredContactMethod' options={appointmentMethod} />
                                <RHFToggleGroup control={control} label='Appointment type (Required)' name='appointmentType' options={appointmentType} />
                                <RHFToggleGroup control={control} label='Contact method (Required)' name='practiceContactedVia' options={contactMethod} />
                            </Box>
                        </>
                        : null
                    }
                </Box>
            </Card>
        </FormProvider>
    );
});

TreatmentAndAppointment.propTypes = {
    handleNext: PropTypes.func,
    type: PropTypes.string,
    isOverview: PropTypes.bool,
};

export default TreatmentAndAppointment;