/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { capitalCase } from 'change-case';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';
// @mui
import { Card, Box, Tabs, Tab, Button, List, ListItemText, useTheme, Typography, ListItem, ListItemIcon, Popover, MenuItem, styled, Tooltip, tooltipClasses } from '@mui/material';
import { isEmpty } from 'lodash';
// components
import { Iconify } from '../../../components';
import MailView from './mail/mail';
import MailCompose from './mail/mailCompose';
import RenderTabItem from './TabItem';
import AddLabelDialog from './mail/labelAddDialog';
import DeleteConfirmationDialog from './mail/deleteConfirmation';
import { LeadDetailCallScript, AutomatedMessages, ChatbotConversationsMessages } from '.';
import WhatsAppDetailsView from '../../../components/whatsappComponents/WhatsAppDetailsView';
import EmailForm from './EmailForm';
import CustomLabelCount from './CustomLabelCount';
// redux
import { getInboxLabels, getThreadMails, searchInbox, setSelectedMailInfo, deleteInboxLabel, setLabelName, clearMails, inboxEmailCounter, setSelectedEmailId } from '../../../redux/slices/mail';
import { store, useSelector } from '../../../redux/store';
import { updateShowSubjectList } from '../../../redux/slices/practiceMessage';
import { updatePersonalDetail } from '../../../redux/slices/lead';
// hooks/validations
import { useTabs } from '../../../hooks';
import { emailRoutes, emailTabs, tabs } from '../../../constants/commonConstants';
// style
import { grey } from '../../../theme/palette';
import { communicationCardStyle, emailTabWrapper, menuItemWrapper } from '../../../styles/LeadDetailView';
import { communicationTabStyle, communicationMainStackStyle, tabBGStyle, emailBoxStyle } from '../../../styles/Chat';

const Communication = ({ leadDetail, autoMatedMessages, sentTasks, upcommingTask, cancelTask, failedTasks, onUpdateStatus, handleLeadDetailClose }) => {
    const theme = useTheme();
    const { dispatch } = store;
    const { state, pathname } = useLocation();
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { practice } = useSelector((state) => state.practice);
    const { inComingMails, draftsMails, outgoingMails, isLoading, searchMessages, inboxLabels, starredMails, labelMails, labelName, inComingMailsCount } = useSelector((state) => state.mail);
    const { currentTab, onChangeTab } = useTabs('Whatsapp');
    const [isComposeVisible, setIsComposeVisible] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isFromEdit, setIsFromEdit] = useState(false);
    const [openAddLabel, setOpenAddLabel] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const open = Boolean(anchorEl);
    const [openDelete, setOpenDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const shouldRender = useRef(true);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setSelectedEmailId(''));
        if (state?.goToWhatsappTab) {
            handleTabClicked('', 'Whatsapp');
        } else {
            handleTabClicked('', currentTab);
        }
        setIsSearchActive(false);
        dispatch(getInboxLabels(practiceId, 25, 0));
    }, []);

    useEffect(() => {
        if (state?.shouldCallCommunicationApi) {
            getListingCalled(state.leadId);
            navigate(`${pathname}/?tab=All%20communications`);
        }
    }, [state]);

    useEffect(() => {
        if (!state?.shouldCallCommunicationApi && !isEmpty(practice) && shouldRender?.current) {
            shouldRender.current = false;
            getListingCalled();
        }
    }, [currentTab, practice]);

    const getListingCalled = (leadId) => {
        if (emailRoutes.includes(currentTab)) {
            dispatch(setLabelName(''));
            dispatch(getThreadMails({ practiceId, mailType: 'Incoming', leadId: leadId || leadDetail.id, limit: 25, offset: 0 }));
            dispatch(inboxEmailCounter(practiceId, leadId || leadDetail.id));
            dispatch(getThreadMails({ practiceId, mailType: 'Drafts', leadId: leadId || leadDetail.id, limit: 25, offset: 0 }));
            dispatch(getThreadMails({ practiceId, mailType: 'Outgoing', leadId: leadId || leadDetail.id, limit: 25, offset: 0 }));
            dispatch(getThreadMails({ practiceId, mailType: 'Starred', leadId: leadId || leadDetail.id, limit: 25, offset: 0 }));
        }
    };

    const handleBackClicked = () => {
        handleTabClicked('', currentTab);
        setIsSearchActive(false);
        getListingCalled();
    };

    const handleComposeClick = () => setIsComposeVisible(!isComposeVisible);

    const labelCount = (data) => {
        if (data.value) return <CustomLabelCount autoMatedMessages={autoMatedMessages} data={data} leadDetail={leadDetail} />;
        return capitalCase(data.value);
    };

    const renderMailType = (type) => {
        const commonProps = {
            mailType: type,
            leadDetail,
            isLoading,
            handleSearch: (id, val, type) => handleSearch(id, val, type),
            isSearchActive,
            handleBackClicked: () => handleBackClicked()
        };
        const types = ['Incoming', 'Outgoing', 'Drafts', 'Starred', 'Label'];

        if (types.includes(type) && !leadDetail?.lead_contact?.email) {
            return <Box sx={{ ...communicationMainStackStyle(), p: '0 16px' }}>
                <Box sx={emailBoxStyle}>
                    <EmailForm handleEmailSubmit={onSubmit} fieldWrapperSx={{ mb: 2 }} />
                </Box>
            </Box>;
        }
        switch (type) {
            case 'Incoming':
                return <MailView mailList={isSearchActive ? searchMessages : inComingMails} {...commonProps} />;
            case 'Outgoing':
                return <MailView mailList={isSearchActive ? searchMessages : outgoingMails} {...commonProps} practice={practice} />;
            case 'Drafts':
                return <MailView mailList={isSearchActive ? searchMessages : draftsMails} {...commonProps} successDone={() => handleTabClicked('', 'Drafts')} />;
            case 'Starred':
                return <MailView mailList={isSearchActive ? searchMessages : starredMails} {...commonProps} practice={practice} />;
            case 'Label':
                return <MailView mailList={isSearchActive ? searchMessages : labelMails} {...commonProps} practice={practice} />;
            default:
                return null;
        }
    };

    const renderMailTypeOrCompose = () => {
        if (isComposeVisible) {
            return <MailCompose
                onCloseCompose={handleComposeClick}
                leadDetail={leadDetail}
                practiceId={practiceId}
                successDone={() => handleTabClicked('', currentTab)}
            />;
        }
        return renderMailType(currentTab);
    };

    useEffect(() => {
        renderMailTypeOrCompose();
    }, [inComingMails, outgoingMails, outgoingMails, starredMails, labelMails]);

    const handleSearch = (id, value, type) => {
        if (value !== '') {
            setIsSearchActive(true);
            if (currentTab === 'Outgoing') {
                const sEmails = practice.mail_senders.map(item => item.sender_email);
                dispatch(searchInbox({ search: value, mailType: type, leadId: leadDetail.id, senderEmails: sEmails }));
            } else {
                dispatch(searchInbox({ search: value, mailType: type, leadId: leadDetail.id }));
            }
        } else if (emailRoutes.includes(currentTab)) {
            setIsSearchActive(false);
            if (currentTab === 'Outgoing') {
                const sEmails = practice.mail_senders.map(item => item.sender_email);
                if (sEmails.length > 0) {
                    dispatch(getThreadMails({ practiceId, mailType: currentTab, leadId: leadDetail.id, limit: 25, offset: 0 }));
                }
            } else {
                dispatch(getThreadMails({ practiceId, mailType: currentTab, leadId: leadDetail.id, limit: 25, offset: 0 }));
            }
        } else {
            setIsSearchActive(false);
        }
    };

    const renderView = () => {
        if (isComposeVisible) {
            return <>{renderMailTypeOrCompose()}</>;
        }
        return <Box sx={{ ...communicationMainStackStyle(emailTabs?.includes(currentTab)), maxWidth: emailTabs?.includes(currentTab) ? 'calc(100% - 216px)' : '100%', height: emailTabs?.includes(currentTab) ? '670px' : 'auto' }}>
            {currentTab === 'Automations' && <AutomatedMessages
                sentTasks={sentTasks}
                upcommingTask={upcommingTask}
                cancelTask={cancelTask}
                failedTasks={failedTasks}
                onUpdateStatus={onUpdateStatus}
            />}
            {renderMailTypeOrCompose()}
            {currentTab === 'CallScript' && <LeadDetailCallScript leadDetail={leadDetail} />}
            {currentTab === 'Chatbot' && <ChatbotConversationsMessages leadDetail={leadDetail} />}
            {currentTab === 'Whatsapp' && <WhatsAppDetailsView leadDetail={leadDetail} />}
        </Box>;
    };

    const handleTabClicked = (e, tabTitle) => {
        dispatch(clearMails());
        dispatch(setSelectedEmailId(''));
        shouldRender.current = true;
        if (isComposeVisible) {
            handleComposeClick();
        }
        setIsSearchActive(false);
        onChangeTab(e, tabTitle);
        dispatch(setSelectedMailInfo(null));
    };

    const handleLabelClick = (e, item) => {
        dispatch(clearMails());
        dispatch(setSelectedEmailId(''));
        shouldRender.current = true;
        if (isComposeVisible) {
            handleComposeClick();
        }
        dispatch(setLabelName(item?.name));
        setIsSearchActive(false);
        onChangeTab(e, 'Label');
        dispatch(getThreadMails({ practiceId, mailType: 'Label', leadId: leadDetail.id, limit: 25, offset: 0 }));
        dispatch(setSelectedMailInfo(null));
    };

    const handleDotsClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setSelectedIndex(index);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedIndex(null);
    };

    const handleDeleteLabel = async () => {
        await dispatch(deleteInboxLabel(practiceId, '', selectedItem.id));
        setOpenDelete(false);
        setAnchorEl(null);
        setSelectedIndex(null);
    };

    const handleScroll = (e) => {
        const bottom = parseInt(e.target.scrollHeight - e.target.scrollTop, 10) === e.target.clientHeight;
        if (bottom && inboxLabels?.count > inboxLabels?.results?.length) {
            dispatch(getInboxLabels(practiceId, 25, inboxLabels?.results?.length, false));
        }
    };

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
        },
    }));

    const onSubmit = (data) => {
        const detail = { lead_contact: { email: data.email } };
        if (leadDetail && leadDetail.id) {
            detail.id = leadDetail.id;
            dispatch(updatePersonalDetail(detail, practiceId, handleLeadDetailClose, 'leadDetail'));
        }
    };

    const handleDeleteClose = () => { setOpenDelete(false); handleClose(); };
    const handleLabelClose = () => { setOpenAddLabel(false); handleClose(); };
    const handleClickDeleteMenu = (item) => { setSelectedItem(item); setOpenDelete(true); };
    const handleClickEditMenu = (item) => { setIsFromEdit(true); setSelectedLabel(item); setOpenAddLabel(true); };

    const mailTabs = [
        { tabTitle: 'Incoming', count: inComingMailsCount?.count },
        { tabTitle: 'Outgoing', count: outgoingMails?.count },
        { tabTitle: 'Drafts', count: draftsMails?.count },
        { tabTitle: 'Starred', count: starredMails.count },
    ];

    return (
        <Card sx={{ borderRadius: '16px', p: '24px 24px 80px', bgcolor: 'common.white' }}>
            <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: '1.5rem', fontWeight: 700 }}>All communications</Typography>
            </Box>
            <Card
                onClick={() => dispatch(updateShowSubjectList(false))}
                sx={{ ...communicationCardStyle, p: 0, bgcolor: 'common.white' }}>
                <Tabs
                    orientation='vertical'
                    variant='scrollable'
                    value={currentTab}
                    onChange={(e, value) => handleTabClicked(e, value)}
                    sx={{ ...emailTabWrapper({ theme }) }}
                >



                    {/*
                    <LightTooltip title='Email' placement='right'>
                        <Tab
                            disableRipple
                            label={labelCount({ isNotText: true, value: 'Email', icon: 'ion:mail' })}
                            value='Incoming'
                            sx={emailTabs?.includes(currentTab) ? { ...communicationTabStyle, ...tabBGStyle(theme) } : communicationTabStyle} />
                    </LightTooltip>
                    */}
                    {tabs.map(tab => (
                        <LightTooltip key={tab.value} title={tab.title} placement='right'>
                            <Tab
                                disableRipple
                                label={labelCount({ isNotText: true, value: tab.title, icon: tab.icon })}
                                value={tab.value}
                                sx={currentTab === tab.value ? { ...communicationTabStyle, ...tabBGStyle(theme) } : communicationTabStyle}
                            />
                        </LightTooltip>
                    ))}
                </Tabs>
                <Card sx={{ ...communicationCardStyle, bgcolor: grey[200], p: '12px', width: 'calc(100% - 80px)' }}>
                    {emailTabs?.includes(currentTab) && <Box sx={{ width: '200px', minWidth: '200px', '.MuiTabs-indicator': { display: 'none' } }}>
                        <Box sx={{ mb: 2, width: '100%' }}>
                            <Typography sx={{ fontWeight: 700, fontSize: '18px' }}>Email</Typography>
                        </Box>
                        <Button
                            variant='contained'
                            onClick={handleComposeClick}
                            sx={{ mb: 1, width: '100%' }}
                        >
                            <Iconify width={20} height={20} icon={'mdi:edit'} sx={{ mr: 1 }} /> Compose Email
                        </Button>
                        <List sx={{ p: '0 0 16px' }}>
                            {mailTabs.map((ele, index) => <RenderTabItem
                                key={index}
                                currentTab={currentTab}
                                tabTitle={ele.tabTitle}
                                count={ele.count}
                                handleTabClicked={(e) => handleTabClicked(e, ele.tabTitle)}
                            />)}
                        </List>
                        <Box sx={{ mb: 2, pl: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>Labels</Typography>
                                <Box sx={{ lineHeight: 0, cursor: 'pointer' }} onClick={() => { setIsFromEdit(false); setSelectedLabel(); setOpenAddLabel(true); }}>
                                    <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                                </Box>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <Box sx={{ maxHeight: '300px', overflow: 'auto' }} onScroll={handleScroll}>
                                    {inboxLabels?.results?.map((item, index) => (
                                        <>
                                            <ListItem key={index} sx={{ px: 0, cursor: 'pointer', minHeight: '48px' }} onClick={(e) => handleLabelClick(e, item)}>
                                                <Iconify icon='material-symbols:label-outline' sx={{ mr: 1, minWidth: '20px', color: labelName === item?.name ? 'primary.main' : 'common.grey' }} />
                                                <ListItemText primary={item.name} sx={{ '.MuiTypography-root': { color: labelName === item?.name ? 'common.slateGray' : 'common.grey' } }} />
                                                <ListItemIcon onClick={(event) => handleDotsClick(event, index)} sx={{ mr: 0, cursor: 'pointer' }}>
                                                    <Iconify icon={'tabler:dots-vertical'} width={20} height={20} sx={{ color: 'common.grey' }} />
                                                </ListItemIcon>
                                            </ListItem>
                                            <Popover
                                                open={open && selectedIndex === index}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            >
                                                <Box style={menuItemWrapper}>
                                                    <MenuItem onClick={() => handleClickEditMenu(item)}>
                                                        <Iconify icon='solar:pen-bold' sx={{ mr: 1 }} /> Edit
                                                    </MenuItem>
                                                    <MenuItem onClick={() => handleClickDeleteMenu(item)} sx={{ color: 'error.main' }}>
                                                        <Iconify icon='solar:trash-bin-trash-bold' sx={{ mr: 1 }} /> Delete
                                                    </MenuItem>
                                                </Box>
                                            </Popover>
                                        </>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </Box>}
                    {renderView()}
                </Card>
                {openAddLabel === true && <AddLabelDialog
                    open={openAddLabel}
                    onClose={handleLabelClose}
                    practiceId={practiceId}
                    threadId={''}
                    isFromEdit={isFromEdit}
                    data={selectedLabel}
                    successDone={handleLabelClose}
                />}
                {openDelete === true && <DeleteConfirmationDialog
                    openDelete={openDelete}
                    title={selectedItem.name}
                    onDeleteClose={handleDeleteClose}
                    successDeleteDone={handleDeleteLabel}
                />}
            </Card>
        </Card>
    );
};

Communication.propTypes = {
    leadDetail: PropTypes.object,
    autoMatedMessages: PropTypes.object,
    sentTasks: PropTypes.array,
    upcommingTask: PropTypes.array,
    cancelTask: PropTypes.array,
    failedTasks: PropTypes.array,
    onUpdateStatus: PropTypes.func,
    handleLeadDetailClose: PropTypes.func
};

export default Communication;