import React from "react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
    useRoutes,
} from "react-router-dom";
import { wrapUseRoutes } from "@sentry/react";

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://6e2eb0942fb174e880057e2406184025@o1361470.ingest.us.sentry.io/4508273023582208",
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^\//, /^https:\/\/api\.dental-sem\.co\.uk/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

export const useSentryRoutes = wrapUseRoutes(useRoutes);