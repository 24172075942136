/* eslint-disable no-nested-ternary */
import { createSlice } from '@reduxjs/toolkit';
import { isEmpty, uniqBy } from 'lodash';
import { updateError } from './user';
import { dispatch, store } from '../store';
// utils
import { callApi, isCacheValid , cacheTimer} from '../../utils/apiCall';
import { getPracticeId } from './clincheck';
// constant
import { treatmentApiFields } from '../../constants/PracticeTreatmentsCategoryConst';

const initialState = {
    isLoading: false,
    practiceTreatment: {
        results: []
    },
    treatmentsList: {
        results: []
    },
    treatmentsListV2: {
        results: []
    },
    treatmentsListForWidget: {
        results: []
    },
    categoryList: {
        results: []
    },
    message: '',
    error: '',
    treatmentsName: [],
    choicesData: {
        results: []
    },
    cachePracticeTreatmentList: {}
};

const slice = createSlice({
    name: 'practiceTreatment',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
            state.message = '';
        },
        getPracticeTreatmentData(state, action) {
            state.isLoading = false;
            state.practiceTreatment = action.payload;
        },
        addTreatmentsList(state, action) {
            state.isLoading = false;
            state.treatmentsList = action.payload;
        },
        addTreatmentsListV2(state, action) {
            state.isLoading = false;
            state.treatmentsListV2 = action.payload;
        },
        addTreatmentsListForWidget(state, action) {
            if (isEmpty(state.treatmentsListForWidget?.results)) {
                state.treatmentsListForWidget = action.payload;
            }
        },
        clearTreatmentsListForWidget(state) {
            state.treatmentsListForWidget = [];
        },
        addTreatmentsName(state, action) {
            state.isLoading = false;
            state.treatmentsName = action.payload;
        },
        addMoreTreatmentsList(state, action) {
            state.isLoading = false;
            state.treatmentsList.results = uniqBy([
                ...state.treatmentsList.results,
                ...action.payload
            ], 'id');
        },
        addMoreTreatmentsListV2(state, action) {
            state.isLoading = false;
            state.treatmentsListV2.results = uniqBy([
                ...state.treatmentsListV2.results,
                ...action.payload
            ], 'id');
        },
        putPracticeTreatmentData(state, action) {
            state.isLoading = false;
            state.practiceTreatment = action.payload;
        },
        getUpdatedData(state) {
            state.isLoading = false;
        },
        getCategory(state, action) {
            state.categoryList = action.payload;
        },
        addMoreGetCategory(state, action) {
            state.isLoading = false;
            state.categoryList.results = [
                ...state.categoryList.results,
                ...action.payload
            ];
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        updateStatus(state, action) {
            state.isLoading = false;
            state.practiceTreatment.results = action.payload;
        },
        clearMessage(state) {
            state.message = '';
            state.error = '';
        },
        getChoicesData(state, action) {
            state.choicesData = action.payload;
        },
        updateCachePracticeTreatmentList(state, action) {
            state.cachePracticeTreatmentList = {
                ...state.cachePracticeTreatmentList, ...action.payload}
        }
    }
});

// Reducer
export default slice.reducer;

export const { clearMessage, addTreatmentsList, clearTreatmentsListForWidget, addTreatmentsListForWidget, reset, addTreatmentsName, startLoading, addTreatmentsListV2, addMoreTreatmentsListV2 } = slice.actions;

// Get Treatment List
export function getPracticeTreatmentList(practiceId, data, isCacheApi) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {

            const cacheKey = `api/v1/practices/${practiceId}/treatments/?fields={enabled,id,practice,price,colour,icon,is_starred,treatment_item{id,name,treatment_category{id,name}}}&ordering=treatment_item__name${data?.enable === 'Disable' ? '&enabled=false' : data?.enable === 'All' ? '' : '&enabled=true'}${data?.limit ? `&limit=${data?.limit}` : ''}${data?.offset === 0 || data?.offset ? `&offset=${data?.offset}` : ''}${data?.treatment ? `&treatment_item__name__icontains=${data?.treatment}` : ''}${data?.category ? `&treatment_item__treatment_category__name__icontains=${data?.category}` : ''}`;

            let result;

            const { cachePracticeTreatmentList } = store.getState().practiceTreatment;

            const currentTime = new Date().getTime();

            result = await isCacheValid(cachePracticeTreatmentList, cacheKey, cacheTimer, currentTime, isCacheApi);
            
            if(!result || !isCacheApi) {
                const response = await callApi(`/api/v1/practices/${practiceId}/treatments/?fields={enabled,id,practice,price,colour,icon,is_starred,treatment_item{id,name,treatment_category{id,name}}}&ordering=treatment_item__name${data?.enable === 'Disable' ? '&enabled=false' : data?.enable === 'All' ? '' : '&enabled=true'}${data?.limit ? `&limit=${data?.limit}` : ''}${data?.offset === 0 || data?.offset ? `&offset=${data?.offset}` : ''}${data?.treatment ? `&treatment_item__name__icontains=${data?.treatment}` : ''}${data?.category ? `&treatment_item__treatment_category__name__icontains=${data?.category}` : ''}`);

                if (response.status === 200) {
                    result = response?.data;
                        dispatch(slice.actions.updateCachePracticeTreatmentList({ [cacheKey]: { data: result, timestamp: currentTime } }));
                  
                } else {
                    dispatch(updateError({ message: JSON.stringify(response.detail) || 'Something went wrong', success: false }));
                }
            }
                      
            if (data?.callBack) {
                data.callBack();
            }
            if (data?.limit || data?.type === 'searchTreatment') {
                if (data?.offset === 0 || data?.type === 'practiceTreatment' || data?.type === 'searchTreatment') {
                    dispatch(slice.actions.addTreatmentsList(result));
                    dispatch(slice.actions.addTreatmentsListForWidget(result));
                } else {
                    dispatch(slice.actions.addMoreTreatmentsList(result?.results));
                }
                return;
            }
            dispatch(slice.actions.getPracticeTreatmentData(result));

        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

const getQuery = (data) => {
    let query = `${treatmentApiFields}&ordering=-is_starred,treatment_item__name`;
    if (data?.enable === 'Disable') {
        query += '&enabled=false';
    }
    if (data?.enable !== 'Disable' && data?.enable !== 'All') {
        query += '&enabled=true';
    }
    if (data?.limit) {
        query += `&limit=${data?.limit}`;
    }
    if (data?.offset === 0 || data?.offset) {
        query += `&offset=${data?.offset}`;
    }
    if (data?.treatment) {
        query += `&treatment_item__name__icontains=${data?.treatment}`;
    }
    if (data?.category) {
        query += `&treatment_item__treatment_category__name__icontains=${data?.category}`;
    }
    return query;
};

// Get Treatment List v2
export function getPracticeTreatmentListV2(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/treatments/${getQuery(data)}`);
            if (response.status === 200) {
                if (data?.callBack) {
                    data.callBack();
                }
                if (data?.offset === 0) {
                    dispatch(slice.actions.addTreatmentsListV2(response?.data));
                } else {
                    dispatch(slice.actions.addMoreTreatmentsListV2(response?.data?.results));
                }
            } else {
                dispatch(updateError({ message: JSON.stringify(response.detail) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

// treatment update detail
export function updatePracticeTreatment(data, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/treatments/${id}/`, 'put', data);
            if (response.status === 200) {
                handleSuccess({ success: true, message: 'Treatment updated successfully' });
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
            }
        }
        catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

// delete treatment
export function deletePracticeTreatment(id, handleSuccess) {
    return async () => {
        try {
            const { practiceId } = store.getState().practiceTreatmentList;
            const response = await callApi(`/api/v1/practices/${practiceId}/treatments/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(slice.actions.getUpdatedData());
                dispatch(updateError({ message: 'Treatment detail deleted successfully', success: true }));
                handleSuccess();
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

// treatment categories
export function getTreatmentCategory(dataValues) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/treatment-categories/?ordering=name${dataValues?.limit ? `&limit=${dataValues?.limit}` : ''}${(dataValues?.offset === 0 || dataValues?.offset) ? `&offset=${dataValues?.offset}` : ''}`);
            if (response.status === 200) {
                const allFilter = { name: 'All', id: '0' };
                const { data } = response;
                if (dataValues?.offset === 0) {
                    data.results.unshift(allFilter);
                    dispatch(slice.actions.getCategory(data));
                } else {
                    dispatch(slice.actions.addMoreGetCategory(data?.results));
                }
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function getChoicesList(practiceId) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/choices/`);
            if (response.status === 200) {
                dispatch(slice.actions.getChoicesData(response?.data));
            } else {
                dispatch(updateError({ message: JSON.stringify(response.detail) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}
