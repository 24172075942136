import { useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import { Dialog, Stack, Box, Typography, Button } from '@mui/material';
// components
import AllLeadsTables from 'src/pages/dashboard/tables/AllLeadTable/AllLeadsTable';
import { handleClearReportTable } from 'src/utils/reportsUtils';
import ReportsNewLead from '../../pages/dashboard/ReportsNewLead';
import ReportsAudienceDialog from './ReportsAudienceDialog';
import Iconify from '../Iconify';
import { Transition } from '.';
// redux
import { setAudienceIncludeArchive, updateSelectedAudience } from '../../redux/slices/audienceManager';
import { dispatch, useSelector } from '../../redux/store';
// hooks
import { useBoolean, useTabs } from '../../hooks';
// styles
import { common } from '../../theme/palette';
import { confirmationMainBoxStyle, whiteBGStyle } from '../../styles/DuplicateLeadStyle';
import { leadListingBox, leadListPopupStackStyle } from '../../styles/AddReminderPopup';
import {
    UpdateTableData,
} from '../../utils/allTableTab';

const LeadListPopup = ({
    open,
    data,
    type,
    onClose,
    updateLeadPage,
    practiceId,
    count,
    LeadLabelsForReports,
    rowStatus,
    selectedCategory,
    categoryId,
    dateRange,
    iscategorySummary
}) => {
    const generateAudience = useBoolean();
    const { currentTab, onChangeTab } = useTabs('By Conditions');
    const [confirmation, setConfirmation] = useState(false);
    const [audienceName, setAudienceName] = useState('');
    const { audienceData } = useSelector((state) => state.audienceManager);

    const handleCloseAudienceDialog = () => {
        generateAudience.onFalse();
        setAudienceName('');
    };

    const handleCreateAudience = () => {
        generateAudience.onTrue();
        dispatch(updateSelectedAudience({ ...audienceData }));
        dispatch(setAudienceIncludeArchive(false));
    };

    const handleCreateAudienceSuccess = (data) => {
        setConfirmation(true);
        generateAudience.onFalse();
        onClose();
        setAudienceName(data.name);
    };

    return (
        <>
            <Dialog TransitionComponent={Transition} open={open} onClose={onClose} sx={leadListingBox}>
                <Stack sx={leadListPopupStackStyle}>
                    {iscategorySummary ? <Box sx={{ position: "relative" }}>
                        <AllLeadsTables displayForCategorySummary leadCategoryType={rowStatus} categoryId={categoryId} dateRange={dateRange} />
                        <Box sx={{ textAlign: 'right', top: "0.625rem", right: 0, position: "absolute" }}>
                            <Button
                                variant='contained'
                                sx={{ marginRight: '16px' }}
                                onClick={handleCreateAudience}
                                disabled={data.length === 0}
                            >
                                <Iconify
                                    icon={'ic:baseline-plus'}
                                    width={20}
                                    height={20}
                                    sx={{ mr: 1 }}
                                />
                                Generate Audience
                            </Button>
                            <Button variant='outlined' onClick={() => handleClearReportTable({ onClose, UpdateTableData, type })}>Cancel</Button>
                        </Box>
                    </Box> : (
                        <ReportsNewLead
                            title='New Leads'
                            leadData={data !== null && data?.length > 0 && practiceId ? data : []}
                            updateLeadPage={updateLeadPage}
                            practiceId={practiceId}
                            pageCount={count}
                            type={type}
                            tableLabels={LeadLabelsForReports}
                            onClose={onClose}
                            rowStatus={rowStatus}
                            handleCreateAudience={handleCreateAudience}
                        />
                    )}
                </Stack>
            </Dialog>
            <ReportsAudienceDialog
                generateAudience={generateAudience}
                handleCloseAudienceDialog={handleCloseAudienceDialog}
                currentTab={currentTab}
                onChangeTab={onChangeTab}
                selectedCategory={selectedCategory}
                handleSave={selectedCategory}
                handleCreateAudienceSuccess={handleCreateAudienceSuccess}
            />
            <Dialog open={confirmation} onClose={() => setConfirmation(false)}>
                <Box sx={confirmationMainBoxStyle}>
                    <Box sx={{ mr: '12px' }}>
                        <Box sx={whiteBGStyle}>
                            <Iconify icon={'akar-icons:circle-check-fill'} sx={{ color: common.green }} width={24} height={24} />
                        </Box>
                    </Box>
                    <Box>
                        <Typography
                            sx={{ fontSize: '16px', fontWeight: '700' }}
                        >{`Audience ‘${audienceName}’ created successfully!`}</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '24px' }}>
                            <Button
                                variant='outlined'
                                onClick={() => setConfirmation(false)}
                            >
                                Ok
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </>
    );
};

export default LeadListPopup;

LeadListPopup.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    type: PropTypes.string,
    onClose: PropTypes.func,
    updateLeadPage: PropTypes.func,
    practiceId: PropTypes.number,
    count: PropTypes.number,
    LeadLabelsForReports: PropTypes.array,
    rowStatus: PropTypes.string,
    selectedCategory: PropTypes.string,
    categoryId: PropTypes.number,
    dateRange: PropTypes.object,
    iscategorySummary: PropTypes.bool
};
