/* eslint-disable no-constant-condition */
/* eslint-disable no-nested-ternary */
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
// constants
import { isArray } from 'lodash';
import {
    reportLeadStatuses,
    treatmentCategory,
    isLeadArchived,
    leadSourceType,
    leadSourceReferral,
    AllLeadData,
    AllTreatments,
    categorySummary,
    sourceTypeSummary,
    referralSummary,
    archiveSummary
} from '../../constants/ReportsLeadConst';
// utils
import { callApi, isCacheValid, cacheTimer } from '../../utils/apiCall';
import { updateError } from './user';
import { errorHandler } from '../../utils/errorHandler';
// store
import { dispatch, store } from '../store';
import { getPracticeId } from './clincheck';
import { setCounter } from './widgetCounter';

// ------------------------------------------------------------------------

const initialState = {
    isLoading: true,
    AllTargets: null,
    analytics: null,
    treatmentSummary: null,
    targetData: null,
    consultationTarget: null,
    startedTarget: null,
    targetTreatment: null,
    startTime: moment(new Date()).startOf('month').format('YYYY-MM-DD 00:00:00'),
    endTime: moment(new Date()).format('YYYY-MM-DD 23:59:00'),
    doctorsList: null,
    patientList: null,
    statusesReports: null,
    treatmentCategoryReports: null,
    archivedLeadReport: null,
    leadSourceTypeReports: null,
    sourceReferral: null,
    userLeads: null,
    reportsAllLead: 0,
    reportsAllTreatments: 0,
    categorySummaryData: null,
    enquiryColumnData: null,
    notBookedColumnData: null,
    reportStartTime: null,
    reportEndTime: null,
    sourceTypeSummaryData: null,
    referralSummaryData: null,
    archiveSummaryData: null,
    categoryArchivedData: null,
    treatmentArchivedData: null,
    sourceArchivedData: null,
    referralArchivedData: null,
    ScheduleSettingList: {},
    leads: {
        results: []
    },
    dateRange: {
        startDate: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD')
    },
    treatmentStartedCount: 0,
    reportAppointmentData: null,
    reportsLoading: false,
    cacheReportsAppointmentListing: {}
};

const slice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        // START LOADING
        startLoading(state) {
            state.isLoading = false;
        },
        stopLoading(state) {
            state.isLoading = false;
        },
        addAnalytics(state, action) {
            state.analytics = action.payload;
        },
        addTreatmentSummery(state, action) {
            state.isLoading = false;
            state.treatmentSummary = action.payload;
        },
        clearTreatmentSummary(state) {
            state.treatmentSummary = null;
        },
        addTargetDataSlice(state, action) {
            state.targetData = action.payload;
        },
        addAllTargets(state, action) {
            state.AllTargets = action.payload;
        },
        consultationTargetSlice(state, action) {
            state.consultationTarget = {
                ...state?.consultationTarget,
                ...action.payload
            };
        },
        startedTargetSlice(state, action) {
            state.startedTarget = {
                ...state?.startedTarget,
                ...action.payload
            };
        },
        addTargetTreatment(state, action) {
            state.targetTreatment = action.payload;
        },
        addStartTime(state, action) {
            state.startTime = action.payload;
        },
        addEndTime(state, action) {
            state.endTime = action.payload;
        },
        addDoctorslist(state, action) {
            state.doctorsList = action.payload;
        },
        addPatientList(state, action) {
            state.patientList = action.payload;
        },
        clearPatientList(state) {
            state.patientList = null;
        },
        addReportsLeadStatuses(state, action) {
            state.statusesReports = action.payload;
        },
        clearReportsLeadStatuses(state) {
            state.statusesReports = null;
        },
        clearReportsAppointments(state) {
            state.reportAppointmentData = null;
        },
        addTreatmentCategoryReport(state, action) {
            state.treatmentCategoryReports = action.payload;
        },
        clearTreatmentCategoryReport(state) {
            state.treatmentCategoryReports = null;
        },
        addArchivedLeadReport(state, action) {
            state.archivedLeadReport = action.payload;
        },
        addleadSourceType(state, action) {
            state.leadSourceTypeReports = action.payload;
        },
        clearleadSourceType(state) {
            state.leadSourceTypeReports = null;
        },
        addleadSourceReferral(state, action) {
            state.sourceReferral = action.payload;
        },
        clearleadSourceReferral(state) {
            state.sourceReferral = null;
        },
        addReportsAllLead(state, action) {
            state.reportsAllLead = action.payload;
        },
        addReportsAllTreatments(state, action) {
            state.reportsAllTreatments = action.payload;
        },
        addUserLeads(state, action) {
            state.userLeads = action.payload;
        },
        clearUserLeads(state) {
            state.userLeads = null;
        },
        addCategorySummary(state, action) {
            state.categorySummaryData = action.payload;
        },
        addEnquiryColumnData(state, action) {
            state.enquiryColumnData = action.payload;
        },
        addReportAppointmentData(state, action) {
            state.reportAppointmentData = action.payload;
        },
        addNotBookedColumnData(state, action) {
            state.notBookedColumnData = action.payload;
        },
        addReportStartTime(state, action) {
            state.reportStartTime = action.payload;
        },
        addReportEndTime(state, action) {
            state.reportEndTime = action.payload;
        },
        addSourceTypeSummary(state, action) {
            state.sourceTypeSummaryData = action.payload;
        },
        addReferralSummary(state, action) {
            state.referralSummaryData = action.payload;
        },
        addArchiveSummary(state, action) {
            state.archiveSummaryData = action.payload;
        },
        archivedCategoryData(state, action) {
            state.categoryArchivedData = action.payload;
        },
        archivedTreatmentCategory(state, action) {
            state.treatmentArchivedData = action.payload;
        },
        archivedCategory(state, action) {
            state.treatmentArchivedData = action.payload;
        },
        archivedSourceCategory(state, action) {
            state.sourceArchivedData = action.payload;
        },
        archivedReferralCategory(state, action) {
            state.referralArchivedData = action.payload;
        },
        getScheduleSettingList(state, action) {
            state.ScheduleSettingList = action.payload;
        },
        getLeadList(state, action) {
            state.leads = action.payload;
        },
        setDateRange(state, action) {
            state.dateRange = action.payload;
        },
        clearPopupLead(state) {
            state.leads = {
                results: []
            };
        },
        treatmentStartedReport(state, action) {
            state.treatmentStartedCount = action.payload;
        },
        setReportsLoading(state, action) {
            state.reportsLoading = action.payload;
        },
        updateCacheReportsAppointmentListing(state, action) {
            state.cacheReportsAppointmentListing = {
                ...state.cacheReportsAppointmentListing,
                ...action.payload
            };
        }
    }
});

export default slice.reducer;

export const {
    addAnalytics,
    addConsultationTarget,
    addGoAheadTarget,
    addTreatment,
    addTargetTreatment,
    addStartTime,
    addEndTime,
    clearTreatmentSummary,
    clearUserLeads,
    clearleadSourceReferral,
    clearleadSourceType,
    clearReportsLeadStatuses,
    clearReportsAppointments,
    clearTreatmentCategoryReport,
    clearPatientList,
    addReportStartTime,
    addReportEndTime,
    archivedCategoryData,
    archivedTreatmentCategory,
    archivedReferralCategory,
    archivedSourceCategory,
    getScheduleSettingList,
    getLeadList,
    reset,
    setDateRange,
    clearPopupLead,
    addEnquiryColumnData,
    addNotBookedColumnData,
    setReportsLoading
} = slice.actions;

export function getAnalytics(practiceId, id, after, before) {
    return async () => {
        try {
            const treatment = id ? `?treatment=${id}` : '';
            const afterParam = after ? `${id ? '&' : '?'}date_after=${after}` : '';
            const beforeParam = before ? `${id || after ? '&' : '?'}date_before=${before}` : '';
            const response = await callApi(`/api/v1/practices/${practiceId}/reports/lead-statistics/${treatment}${afterParam}${beforeParam}`);
            if (response.status === 200) {
                dispatch(slice.actions.addAnalytics(response.data));
            }
            else {
                errorHandler(response.data, '');
            }
        } catch (error) {
            errorHandler(error, '', updateError);
        }
    };
}

export function getTreatmentSummary(practiceId, id, after, before) {
    return async () => {
        try {
            const treatment = id ? `?treatment=${id}` : '';
            const afterParam = after ? `${id ? '&' : '?'}time_after=${after}` : '';
            const beforeParam = before ? `${id || after ? '&' : '?'}time_before=${before}` : '';
            const response = await callApi(`/api/v1/practices/${practiceId}/reports/lead-treatment-statistics/${treatment}${afterParam}${beforeParam}`);
            if (response.status === 200) {
                dispatch(slice.actions.addTreatmentSummery(response.data));
            }
            else {
                errorHandler(response.data, '');
            }
        } catch (error) {
            errorHandler(error, '', updateError);
        }
    };
}

export function getEventBasedAnalytics(practiceId, dataSetterCb) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/analytics-events/?name=practice_phone_number_clicked&ordering=-created_at`);
            if (response.status === 200) {
                dataSetterCb(response?.data?.results);
            } else {
                dataSetterCb([]);
            }
        } catch (error) {
            console.error("Error while fetching data for event based analytics");
            dataSetterCb([])
        }
    }
}

export function getTargetData(practiceId, id) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/treatments/${id}/targets/`);
            if (response.status === 200) {
                dispatch(slice.actions.addTargetDataSlice(response.data.results));
            }
        } catch (error) {
            errorHandler(error, '', updateError);
        }
    };
}

export function addTargetData(data, practiceId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/treatments/${id}/targets/`, 'post', data);
            if (response.status === 201) {
                if (response?.data?.lead_status === 'CONSULTATION_BOOKED') {
                    dispatch(slice.actions.consultationTargetSlice(response?.data));
                }
                if (response?.data?.lead_status === 'TREATMENT_STARTED') {
                    dispatch(slice.actions.startedTargetSlice(response?.data));
                }
                handleSuccess({ success: true, message: 'Target Added successfully' });
            }
            else {
                errorHandler(response.data, '', updateError);
            }
        } catch (error) {
            errorHandler(error, '', updateError);
        }
    };
}

export function removeTargetData(practiceId, treatment, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/treatments/${treatment}/targets/${id}/`, 'delete');
            if (response.status === 204) {
                handleSuccess({ success: true, message: 'Target deleted successfully' });
            }
            else {
                errorHandler(response.data, '', updateError);
            }
        } catch (error) {
            errorHandler(error, '', updateError);
        }
    };
}

export function getAllTargets(practiceId) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/targets/`);
            if (response.status === 200) {
                dispatch(slice.actions.addAllTargets(response?.data || []));
            }
            else {
                errorHandler(response.data, '', updateError);
            }
        } catch (error) {
            errorHandler(error, '', updateError);
        }
    };
}

export function treatmentStartedReports(data) {
    return async () => {
        try {
            const date = (data?.startTime && data?.endTime) ? `&state_transition_params={"state":"TREATMENT_STARTED","timestamp_gte":"${data?.startTime}","timestamp_lte":"${data?.endTime}"}` : '';
            const parameter = 'group_by[lead_treatments__state_transition_logs__state]&aggregate[Count]=id';
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/reports/leads/?${parameter}${date}`);
            if (response.status === 200) {
                dispatch(slice.actions.treatmentStartedReport(response?.data));
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data?.message) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function reportsLeadListing(data) {
    return async () => {
        try {
            const id = data?.treatment_id ? `&lead_treatments__treatment__in=${data?.treatment_id}` : '';
            const date = (data?.startTime && data?.endTime) ? data?.isCategoryTable ? `&state_transition_params={"timestamp_gte":"${data?.startTime}","timestamp_lte":"${data?.endTime}"}` : `&created_at__gte=${data?.startTime}&created_at__lte=${data?.endTime}` : '';
            const Status = data?.reportStatus ? `&status__in=${data?.reportStatus}` : '';
            const categoryId = data?.categoryId ? `&lead_treatments__treatment__treatment_item__treatment_category__in=${data.categoryId}` : '';
            const referral = data?.referral ? `&lead_source__referral__in=${data.referral}` : '';
            const source = data?.source ? `&lead_source__type__in=${data.source}` : '';
            const isArchived = data?.isArchived ? `&is_archived=true&group_by[${data?.archivedCategory}]` : '';
            const parameter = data?.type === 'totalLead' ? 'aggregate[Count]=id' :
                data?.type === 'totalTreatments' ? 'aggregate[Count]=lead_treatments' :
                    data?.type === 'categorySummary' || data?.type === 'referralSummary' || data?.type === 'sourceTypeSummary' ? (data.isArchived ? (`&aggregate[Count]=id${categoryId}${isArchived}${referral}${source}`) : (`group_by[${data?.category}]&group_by[${data?.categoryStatus}]&aggregate[Count]=id&aggregate[Sum]=lead_treatments__custom_price${categoryId}${isArchived}`)) :
                        data?.type === 'archiveSummary' ? `group_by[${data?.category}]&aggregate[Count]=id&is_archived=true` :
                            (data.isArchived ? `&aggregate[Count]=id&${isArchived}&group_by[${data?.type}]` : `group_by[${data?.type}]&aggregate[Count]=id&aggregate[Sum]=lead_treatments__custom_price`);
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/reports/leads/?${parameter}${date}${id}${Status}`);
            if (response.status === 200) {
                if (data?.type === AllLeadData) {
                    dispatch(slice.actions.addReportsAllLead(response?.data));
                }
                if (data?.type === reportLeadStatuses) {
                    dispatch(slice.actions.addReportsLeadStatuses(response?.data));
                }
                if (data?.type === treatmentCategory) {
                    dispatch(slice.actions.addTreatmentCategoryReport(response?.data));
                }
                if (data?.type === isLeadArchived) {
                    dispatch(slice.actions.addArchivedLeadReport(response?.data));
                }
                if (data?.type === leadSourceType) {
                    dispatch(slice.actions.addleadSourceType(response?.data));
                }
                if (data?.type === leadSourceReferral && !data?.isArchived) {
                    dispatch(slice.actions.addleadSourceReferral(response?.data));
                }
                if (data?.type === leadSourceReferral && data?.isArchived) {
                    dispatch(slice.actions.archivedTreatmentCategory(response?.data));
                }
                if (data?.type === AllTreatments) {
                    dispatch(slice.actions.addReportsAllTreatments(response?.data));
                }
                if (data?.type === categorySummary && !data?.isArchived) {
                    dispatch(slice.actions.addCategorySummary(response?.data));
                }
                if (data?.type === categorySummary && data?.isArchived) {
                    dispatch(slice.actions.archivedCategoryData(response?.data));
                }
                if (data?.type === sourceTypeSummary && !data?.isArchived) {
                    dispatch(slice.actions.addSourceTypeSummary(response?.data));
                }
                if (data?.type === sourceTypeSummary && data?.isArchived) {
                    dispatch(slice.actions.archivedSourceCategory(response?.data));
                }
                if (data?.type === referralSummary && !data?.isArchived) {
                    dispatch(slice.actions.addReferralSummary(response?.data));
                }
                if (data?.type === referralSummary && data?.isArchived) {
                    dispatch(slice.actions.archivedReferralCategory(response?.data));
                }
                if (data?.type === archiveSummary) {
                    dispatch(slice.actions.addArchiveSummary(response?.data));
                }
            }
            else {
                dispatch(updateError({ message: JSON.stringify(response?.data?.message) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

const getQuery = (data) => {
    let query = '';
    if (data?.groupByFilter) {
        query += '?aggregate[Count]=id&group_by[lead_treatments__treatment__treatment_item__treatment_category]';
    }
    if (data?.isNotBookedTable) {
        query += '&lead_treatments__status__in=NEW';
    }
    if (data?.startTime) {
        query += `&created_at__gte=${data?.startTime}`;
    }
    if (data?.endTime) {
        query += `&created_at__lte=${data?.endTime}`;
    }
    return query;
};

export function getReportsListing(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/reports/leads/${getQuery(data)}`);
            if (response.status === 200) {
                if (data?.type === 'enquiry') {
                    dispatch(slice.actions.addEnquiryColumnData(response?.data));
                    return;
                }
                dispatch(slice.actions.addNotBookedColumnData(response?.data));
            }
            else {
                dispatch(updateError({ message: JSON.stringify(response?.data?.message) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

const getReportAppointmentQuery = (data) => {
    let query = '?group_by[status]&aggregate[Count]=id';
    if (data?.startTime && data?.endTime) {
        query += `&datetime__gte=${data?.startTime}&datetime__lte=${data?.endTime}`;
    }
    if (data.status) {
        query += `&lead_treatment__status__in=${data?.status}`;
    }
    if (data.status__in) {
        query += `&status__in=${data?.status__in}`;
    }
    return query;
};

const getCounter = (response) => {
    if (isArray(response?.data) && response.data.length > 0) {
        response.data.forEach((ele) => {
            if (ele.status === 'BOOKED') {
                setCounter({ key: 'BOOKED' }, response.data.filter(ele => ele.status === 'BOOKED')
                    .reduce((total, currentValue) => total + (currentValue.count_id || 0), 0));
            }
            if (ele.status === 'ATTENDED') {
                setCounter({ key: 'ATTENDED' }, response.data.filter(ele => ele.status === 'ATTENDED')
                    .reduce((total, currentValue) => total + (currentValue.count_id || 0), 0));
            }
            if (ele.status === 'FAILED_TO_ATTEND') {
                setCounter({ key: 'FAILED_TO_ATTEND' }, response.data.filter(ele => ele.status === 'CANCELLED' || ele.status === 'FAILED_TO_ATTEND')
                    .reduce((total, currentValue) => total + (currentValue.count_id || 0), 0));
            }
        });
    }
};

export function getReportsAppointmentListing(data, isCacheApi) {
    return async () => {
        try {

            const cacheKey = `api/v1/practices/${getPracticeId()}/reports/appointments/${getReportAppointmentQuery(data)}`;

            let result;

            const { cacheReportsAppointmentListing } = store.getState().analytics;

            const currentTime = new Date().getTime();
            

            result = await isCacheValid(cacheReportsAppointmentListing, cacheKey, cacheTimer, currentTime, isCacheApi);

            if (!result || !isCacheApi) {

                const response = await callApi(`/api/v1/practices/${getPracticeId()}/reports/appointments/${getReportAppointmentQuery(data)}`);
                if (response.status === 200) {
                    result = response?.data;
                    dispatch(slice.actions.updateCacheReportsAppointmentListing({ [cacheKey]: { data: result, timestamp: currentTime } }));
                }
                else {
                    dispatch(updateError({ message: JSON.stringify(response?.data?.message) || 'Something went wrong', success: false }));
                }
            }

            if (data.isWidgetCounter) {
                getCounter(result);
                return;
            }
            dispatch(slice.actions.addReportAppointmentData(result));

        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function reportsUserListing(data) {
    return async () => {
        try {
            const startTime = data?.startTime ? `?lead_treatment__created_at__gte=${data?.startTime}` : '';
            const endTime = data?.endTime ? `&lead_treatment__created_at__lte=${data?.endTime}` : '';
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/reports/practice-user/${startTime}${endTime}`);
            if (response.status === 200) {
                dispatch(slice.actions.addUserLeads(response?.data));
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data?.message) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function reportUserLead(data) {
    return async () => {
        try {
            const startTime = data?.startTime ? `?created_at__gte=${data?.startTime}` : '';
            const endTime = data?.endTime ? `&created_at__lte=${data?.endTime}` : '';
            const treatment = data?.treatment ? `&treatment__in=${data?.treatment}` : '';
            const status = data?.status ? `&status__in=${data?.status}` : '';
            const limit = data?.limit ? `&limit=${data?.limit}` : '';
            const offset = data?.offset === 0 || data?.offset ? `&offset=${data?.offset}` : '';
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/reports/practice-user/${data?.id}/leads/${startTime}${endTime}${treatment}${status}${limit}${offset}`);
            if (response.status === 200) {
                dispatch(slice.actions.addPatientList(response?.data));
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data?.message) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function getScheduleSettings(practiceId, limit, offset,) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/report-schedule-settings/?limit=${limit}&offset=${offset}&ordering=-created_at`);
            if (response.status === 200) {
                dispatch(slice.actions.getScheduleSettingList(response?.data));
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data?.message) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function addScheduleSettings(practiceId, data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/report-schedule-settings/`, 'post', data);
            if (response.status === 201) {
                handleClose();
                dispatch(updateError({ message: 'Schedule settings added successfully', success: true }));
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data.message) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function updateScheduleSettings(practiceId, id, data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/report-schedule-settings/${id}/`, 'patch', data);
            if (response.status === 200) {
                handleClose();
                dispatch(updateError({ message: 'Schedule settings update successfully', success: true }));

            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data?.message) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function deleteScheduleSettings(practiceId, id, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/report-schedule-settings/${id}/`, 'delete');
            if (response.status === 204) {
                handleClose();
                dispatch(updateError({ message: 'Schedule settings delete successfully', success: true }));
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data?.message) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

const handleReportsLoading = (state) => {
    if (state === false) {
        setTimeout(() => {
            dispatch(slice.actions.setReportsLoading(false));
        }, 500);
    }
    dispatch(slice.actions.setReportsLoading(state));
};

export function getLead({ id, categoryId, status, limit, offset, order, orderId, source, startDate, endDate, treatment, referral }) {
    return async () => {
        try {
            handleReportsLoading(true);
            const orderingLead = order === 'desc' ? `&ordering=-${orderId}` : `&ordering=${orderId}`;
            const ordering = orderId ? orderingLead : '';
            const customStartDate = `${startDate} 00:00:00`;
            const customEndDate = `${endDate} 23:59:00`;
            const date = (startDate && endDate) ? `&state_transition_params={${status !== 'All' ? `"state": "${status}",` : ''}"timestamp_gte": "${customStartDate}", "timestamp_lte": "${customEndDate}"}` : '';
            const leadSource = source?.length > 0 ? `&lead_source__type__in=${source}` : '';
            const leadReferral = referral?.length > 0 ? `&lead_source__referral__in=${referral}` : '';
            const categoryQuery = categoryId ? `lead_treatments__treatment__treatment_item__treatment_category__in=${categoryId}` : '';
            const treatmentQuery = treatment ? `&lead_treatments__treatment__in=${treatment}` : '';
            const response = await callApi(
                `/api/v1/practices/${id}/leads/?limit=${limit}&offset=${offset}&${categoryQuery}${ordering}${leadReferral}${leadSource}${date}${treatmentQuery}&is_archived_included=true`
            );
            if (response.status === 200) {
                dispatch(slice.actions.getLeadList(response.data));
                handleReportsLoading(false);
            }
            else {
                dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
                handleReportsLoading(false);
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            handleReportsLoading(false);
        }
    };
}