import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Drawer, IconButton, Tooltip } from '@mui/material';
// hooks
import AddUserPopup from 'src/components/dialogPopUp/AddUserPopup';
import { userIconWrapperStyle, userIconInnerStyle } from 'src/styles/NavBarVerticalStyle';
import { shallowEqual, useSelector } from 'react-redux';
import { useResponsive } from '../../../hooks';
// config
import { NAV } from '../../../config';
// components
import { Iconify, Label, Scrollbar } from '../../../components';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './NavConfig';
import NavbarDocs from './NavbarDocs';
import CollapseButton from './CollapseButton';

// ----------------------------------------------------------------------

export default function NavbarVertical({ openNav, onCloseNav }) {
    const [userOpen, setUserOpen] = useState(false);
    const { pathname } = useLocation();
    const {
        whatsaAppNotifCount,
    } = useSelector(
        (state) => ({
            whatsaAppNotifCount: state?.whatsApp?.whatsaAppNotifCount,
        }),
        shallowEqual
    );

    const isDesktop = useResponsive('up', 'lg');

    const [whatsappConfig, setWhatsappConfig] = React.useState(navConfig);

    React.useEffect(() => {
        let whatsappCount = 0;
        Object.values(whatsaAppNotifCount ?? {})?.forEach((notif) => {
            whatsappCount += notif ?? 0;
        });
        const newNavConfig = [];
        navConfig?.forEach((config) => {
            if (config?.subheader === 'dashboard') {
                const items = [];
                config?.items?.forEach((item) => {
                    if (item?.title === 'Whatsapp') {
                        items.push({ ...item,  info:whatsappCount>0 ?(
                            <Label sx={{color:'rgba(0, 171, 85, 1)'}} startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
                                {`${whatsappCount} NEW`}
                            </Label>
                        ): '' });
                    } else {
                        items.push(item);
                    }
                });
                newNavConfig.push({ subheader: 'dashboard', items });
            } else {
                newNavConfig.push(config);
            }
        });
        setWhatsappConfig(newNavConfig);
    }, [whatsaAppNotifCount]);

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column'
                },
            }}
        >

            <NavSectionVertical navConfig={whatsappConfig} />

            <Box sx={{ flexGrow: 1, ...userIconWrapperStyle }} >
                <Box sx={userIconInnerStyle}>
                    <Tooltip title="Add User" arrow>
                        <IconButton onClick={() => setUserOpen(true)} sx={{ p: 0 }}>
                            <Iconify icon={'wpf:adduser'} width={18} height={18} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

            <NavbarDocs />
        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV.W_DASHBOARD},
            }}
        >
            <CollapseButton />

            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            zIndex: 0,
                            width: NAV.W_DASHBOARD,
                            bgcolor: 'common.white',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: {
                            width: NAV.W_DASHBOARD,
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
            <AddUserPopup userOpen={userOpen} setUserOpen={setUserOpen} />
        </Box>
    );
}

NavbarVertical.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};