/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
// mui
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useGridApiRef } from '@mui/x-data-grid-pro';
import { Box, Button, IconButton, Tooltip, Typography, MenuItem, Popper, Grow, Paper, MenuList, Zoom, ClickAwayListener } from '@mui/material';
// other libraries
import moment from 'moment';
import { isArray } from 'lodash';
import { useNavigate } from 'react-router';
// components
import { fetchMergeTagValueForLeads, fetchMergeTagValueForTreatments } from 'src/redux/slices/mergeTags';
import { Iconify } from '../../../../components';
import { DataGridTableMoreMenu } from '../../../../components/table';
import ContactCell from './CotactCell';
import BookedWithField from './BookedWithField';
import ChoicesField from './ChoicesField';
import CustomTreatmentNameCell from './CustomTreatmentNameCell';
import CustomTreatmentPriceCell from './CustomTreatmentPriceCell';
import CustomTreatmentPriceEditCell from './CustomTreatmentPriceEditCell';
import CustomViewDetailComponent from './CustomViewDetailComponent';
import CustomArchivingButton from './CustomArchivingButton';
import CustomRecordAttendanceBtn from './CustomRecordAttendanceBtn';
import CustomFollowUpBtn from './CustomFollowUpBtn';
import CustomCompleteTreatmentBtn from './CustomCompleteTreatmentBtn';
import CustomFollowUpBtnV2 from './CustomFollowUpBtnV2';
import CustomSelectTreatmentCell from './CustomSelectTreatmentCell';
import GetDetailPanelContent from './GetDetailPanelContent';
import CustomDepositPaymentCell from './CustomDepositPaymentCell';
import { ApptMethodCell, ApptTypeCell, AssigneeCell, AttendedDateCell, AttendedTableColumnActions, BookedWithCell, ClincheckStatusCell, ConsultationBookedWithCell, ConsultationDateCell, ContactActionCell, CreatedAtDateCell, DateOfAppointmentCell, DepositPendingCell, EmailCell, FirstNameCell, LastContactCell, LastNameCell, ReferralCell, StartedTableColumnActions, StatusCell, TagCell, TransitionDateCell, TreatmentPriceCell, ViewDetailCell } from './tableCells';
import DataGridSlots from './DataGridSlots';
import { ArchivedLeadsColumns } from './tableColumns';
import { CompleteTreatmentMenuItem, StartTreatmentMenuItem } from './tableMenuItems';
// redux
import { setCurrentTreatmentDataGlobal } from '../../../../redux/slices/multiTreatment';
import { followUpSlice, getAppointment, leadPage, tableLead } from '../../../../redux/slices/lead';
import { dispatch, useSelector } from '../../../../redux/store';
// utils
import { getTextColor, getTreatmentCellClassName } from '../../../../utils/getClassName';
import { UpdateTableData } from '../../../../utils/allTableTab';
import { applyTablePreferences, ChangePinnedColumns, getCurrentTableData, handleColumnWidthChange, handleSortingChange, handleTablePreference } from '../../../../utils/tablePreferences';
import { getAssignees } from '../../../../utils/multiAsignees';
import capitalizeFirstLetter from '../../../../utils/capitalizedFunc';
// hooks/utils/constants
import { useResponsive } from '../../../../hooks';
import useGetCurrentUserId from '../../../../hooks/useGetCurrentUserId';
import {
    formatData,
    getColumns,
    getRowClassName,
    getTableColumns,
    handleGetVisibleColumns,
    processRowUpdate,
    showDate,
} from '../../../../utils/leadTableUtils';
import { getTransitionDate, handleChangeDateFormat } from '../../../../utils/dataGridUtils';
import { commonColumns } from '../../../../constants/newLead';
import { actionColumnData, initialPaginationData, phoneNumberCell, treatmentFieldColumnData } from '../../../../constants/dataGridConstants';
// style
import { common } from '../../../../theme/palette';
import {
    menuListStyle,
    tableMoreMenuBoxStyle,
    tableCellInnerBoxStyle,
    treatmentNameStyle
} from '../../../../styles/NewLeadTableRowStyle';
import { leadDetailTabsGridStyle } from '../../../../styles/NewLead';
import { treatmentNameCellTypoStyle } from '../../../../styles/LeadTableStyle';

const NewLeadDataGrid = ({
    tableData,
    typed,
    isReportData,
    selectedRow,
    tableTitle,
    tablePath,
    paginationModel,
    setPaginationModel,
    handlePaginationChange,
    pageCount,
    handleChangePerSize,
    OpenAssignTask,
    reloadCounter,
    updateLeadPage,
    openBulkArchive,
    onSelectionModelChange,
    forceUpdate,
    sortingApiCall,
    columnVisibilityModel,
    handleColumnVisibilityChange,
    handleColumnOrderChange,
    openAppointmentAssignTaskFuc,
    handleReloadAPI,
    setTableData,
    handleSetTableData,
    setCurrentTreatmentData,
    isMultiTreatmentTable,
    setColumnVisibilityModel,
    pinProps,
    forceLoading
}) => {
    const { slateGray } = common;
    const [selectData, setSelectedData] = useState({});
    const [open, setOpen] = useState(false);
    const [moreMenuOpen, setMoreMenuOpen] = useState(false);
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([]);

    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { tablePreferences: { results }, tablePreferenceLoading, globalTablePreferences } = useSelector((state) => state.tablePreferences);
    const { archivedLoading, loadingForLead, loadingForClincheckLead } = useSelector((state) => state.lead);
    const choicesData = useSelector((state) => state.practiceTreatment.choicesData.results);

    const isDesktop = useResponsive('down', 'sm');
    const anchorRef = useRef(null);
    const anchorMoreMenuRef = useRef(null);
    const [openMenu, setOpenMenuActions] = useState(null);
    const [menuCurrentTarget, setMenuCurrentTarget] = useState(null);
    const [sortModel, setSortModel] = useState([]);
    const apiRef = useGridApiRef();

    const isFirstRender = useRef(true);

    const handleSorting = ({ key, order }) => {
        setSortModel([{ field: key, sort: order }]);
        sortingApiCall(key, order, isFirstRender.current);
        UpdateTableData(typed, { order, orderValue: key, perPage: 10, pageNumber: 0 });
        setPaginationModel({ pageSize: 10, page: 0 });
    };

    useEffect(() => {
        const tableData = getCurrentTableData(results, tableTitle);
        const currentGlobalTableData = getCurrentTableData(globalTablePreferences?.results || [], tableTitle);
        const currentTableData = isArray(tableData) && tableData.length > 0 ? tableData : currentGlobalTableData;
        if (!isMultiTreatmentTable && isArray(currentTableData) && currentTableData.length > 0) {
            const allColumns = apiRef?.current?.getAllColumns()?.map((ele) => ele?.field) || [];
            handleTablePreference({ allColumns, setColumnVisibilityModel, tableTitle, setPinnedColumns: pinProps.setPinnedColumns, handleSorting });
        }
        isFirstRender.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results, globalTablePreferences]);

    const showTransitionDate = (data, row) => {
        const treatmentData = row?.treatmentData?.find((ele) => ele?.id === row?.treatment_id);
        const transitionDateTime = getTransitionDate(treatmentData?.state_transition_logs || []);
        if (transitionDateTime) {
            const formattedDate = moment(transitionDateTime, 'DD-MM-YYYY HH:mm:ss').format('ddd DD MMM YYYY');
            const formattedTime = moment(transitionDateTime, 'DD-MM-YYYY HH:mm:ss').format('hh:mm A');
            return `${formattedDate} <span>at ${formattedTime}</span>`;
        }
        return '';
    };

    // useEffect(() => {
    //     setTableRefresh(Math.random())
    // }, [tableData]);

    const navigate = useNavigate();

    const setCustomTimeOut = (row, key, id) => {
        handleCurrentTreatmentData(row);
        dispatch(followUpSlice(id, practiceId));
        setTimeout(() => {
            dispatch(getAppointment(practiceId, id));
            selectedRow(row, key);
            dispatch(tableLead(row));
        }, 800);
    };

    const handleAttendedFailedClick = (id, row) => {
        setCustomTimeOut(row, 'recordUnAttended', id);
    };

    const handleAttendedClick = (id, row) => {
        setCustomTimeOut(row, 'recordAttended', id);
    };

    const handleAttendedPopup = (id, row) => {
        setCustomTimeOut(row, 'consultationAttended', id);
    };

    const handleCurrentTreatmentData = (row) => {
        const payload = {
            lead_id: Number(row?.id?.toString()?.split("-")?.[0] || '0'),
            first_name: row.first_name || '',
            last_name: row.last_name || '',
            treatmentData: row?.treatmentData?.find((ele) => ele.id === row?.treatment_id)
        };
        setCurrentTreatmentData(payload);
        dispatch(setCurrentTreatmentDataGlobal(payload));
    };

    const buttonHandleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        if (anchorMoreMenuRef.current && anchorMoreMenuRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
        setMoreMenuOpen(false);
    };

    const handlePopup = (id, status, row) => {
        handleCurrentTreatmentData(row);
        dispatch(fetchMergeTagValueForLeads(id));
        dispatch(fetchMergeTagValueForTreatments(id, row?.treatment_id ?? row?.lead_treatments?.[0]?.id ?? null));
        if (status === 'FAILED_TO_ATTEND_CONSULTATION') {
            handleAttendedFailedClick(id, row);
            return;
        }
        dispatch(tableLead(row));
        dispatch(followUpSlice(id, practiceId));
        setTimeout(() => {
            selectedRow(row, 'contactLead', true);
        }, 800);
    };

    const viewDetailBtn = (id, status, row) => {
        handleCurrentTreatmentData(row);
        if (status === 'CLOSED') {
            dispatch(leadPage({ name: tableTitle, path: tablePath }));
            navigate(`/dashboard/practice/${practiceId}/lead-detail/${id}`);
            return;
        }
        if (status === 'TREATMENT_STARTED') {
            selectedRow(row, 'TreatmentConfirmation');
        }
    };

    const handleNavigate = (row) => {
        if (typed !== 'ArchiveTable' && !row?.is_archive_pending) {
            dispatch(leadPage({ name: tableTitle, path: tablePath }));
            navigate(`/dashboard/practice/${practiceId}/lead-detail/${row?.lead_id}`);
        }
    };

    const handleArchivedNavigate = (row) => {
        if (typed === 'ArchiveTable' && !row.is_archive_pending) {
            dispatch(leadPage({ name: tableTitle, path: tablePath }));
            navigate(`/dashboard/practice/${practiceId}/archived/${row?.lead_id}`);
        }
    };

    const handleOpenMenu = (event, params) => {
        if (!params.is_archive_pending) {
            setOpenMenuActions(params.row.id);
            setMenuCurrentTarget(event.currentTarget);
        }
    };

    const archiveViewDetails = (params) => {
        dispatch(leadPage({ name: tableTitle, path: tablePath }));
        navigate(`/dashboard/practice/${practiceId}/archived/${params.row.lead_id}`);
    };

    const handleStartTreatment = (row) => {
        setOpenMenuActions(null);
        selectedRow(row, 'StartTreatmentConfirmation');
    };
    const handleCompleteTreatment = (row) => {
        setOpenMenuActions(null);
        selectedRow(row, 'TreatmentConfirmation');
    };

    const handleEditAppointment = (row) => {
        setOpenMenuActions(null);
        selectedRow(row, 'EditAppointment');
    };

    const handleAddAppointment = (row) => {
        setOpenMenuActions(null);
        selectedRow(row, 'AddAppointment');
    };
    const handleDeleteTreatmentPayment = (row) => {
        setOpenMenuActions(null);
        selectedRow(row, 'deleteTreatmentPayment');
    };

    const handleUnMarkAsDuplicate = (row) => {
        setOpenMenuActions(null);
        dispatch(tableLead(row));
        selectedRow(row, 'unMarkAsDuplicate');
    };

    const handleCompletedToStartTreatment = (row) => {
        setOpenMenuActions(null);
        selectedRow(row, 'CompletedToStartTreatment');
    };

    const handleMarkAsDuplicate = (row) => {
        setOpenMenuActions(null);
        dispatch(tableLead(row));
        selectedRow(row, 'markAsDuplicate');
    };

    const handleBacktoAttendedPopup = (id, row) => {
        dispatch(followUpSlice(id, practiceId));
        setOpenMenuActions(null);
        dispatch(tableLead(row));
        selectedRow(row, 'backToAttended');
    };

    const handleArchivedDetails = (id, row) => {
        dispatch(followUpSlice(id, practiceId));
        setOpenMenuActions(null);
        dispatch(tableLead(row));
        selectedRow(row, 'archivedLead');
    };

    const handleRestore = (row) => {
        setTimeout(() => {
            setOpenMenuActions(null);
        }, 800);
        dispatch(tableLead(row));
        selectedRow(row, 'restoreArchived');
    };

    const handleSelect = (data) => {
        setSelectedData(data);
        return data;
    };

    const handleNext = (row, event) => {
        selectedRow(row, 'addTreatment', '', event);
        dispatch(tableLead(row));
    };

    const defaultValues = {};

    const methods = useForm({
        defaultValues,
    });

    const { setValue } = methods;

    const handleClinCheckStatusShow = (row, event) => {
        selectedRow(row, 'ClinCheckStatusOpen', '', event);
    };

    const handleMoreMenuTogglePopper = (id) => {
        anchorMoreMenuRef.current = document.getElementById(`menuButton-${id}`);
        setMoreMenuOpen((prevStates) => ({
            ...prevStates,
            [id]: !prevStates[id],
        }));
    };

    const isMoreMenuPopperOpen = (id) => Boolean(moreMenuOpen[id]);

    const toggleCell = {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        hideable: false,
        renderCell: () => (
            <></>
        ),
    };

    const handleTreatmentClick = (e, row) => {
        if (typed === 'ArchiveTable') {
            handleArchivedNavigate(row);
            return;
        }
        selectedRow(row, 'editTreatment', '', e);
    };

    const treatmentCell = {
        ...treatmentFieldColumnData,
        cellClassName: (params) => getTreatmentCellClassName(params?.row?.treatmentBgColor),
        renderCell: (params) => {
            if ((params?.row?.treatment_name === '' || null) && typed !== 'ArchiveTable' && isReportData) {
                return (
                    <Box onClick={(e) => handleNext(params?.row, e)} sx={tableCellInnerBoxStyle} disabled={params?.row?.is_archive_pending}>
                        <Button
                            color='primary'
                            variant='outlined'
                            disabled={params?.row?.is_archive_pending}
                        >
                            Select Treatment
                        </Button>
                    </Box>
                );
            }
            return (
                <CustomTreatmentNameCell
                    params={params}
                    handleTreatmentClick={handleTreatmentClick}
                    ref={apiRef}
                />
            );
        },
    };

    const renderPriceEditInputCell = (params) => <CustomTreatmentPriceEditCell params={params} />;
    const renderPriceCell = (params) => <CustomTreatmentPriceCell params={params} />;

    const attendedDateCellProps = {
        methods,
        handleChangeDateFormat,
        typed,
        updateLeadPage,
        reloadCounter,
        openAppointmentAssignTaskFuc,
        setValue,
    };

    const attendedDateCell = {
        ...DateOfAppointmentCell({ ...attendedDateCellProps, label: 'FTA Date' })
    };

    const editAppointmentMenuItem = (params) => {
        if (params.row.status === 'CONSULTATION_BOOKED' && params.row?.appointments?.length > 0) {
            return <MenuItem
                onClick={() => handleEditAppointment(params.row)}
            >
                <Iconify icon={'solar:pen-bold'} />
                Edit appointment
            </MenuItem>;
        }
        return null;
    };
    const backToAttendedMenuItem = (params) => {
        if (params.row.status === 'TREATMENT_STARTED') {
            return <MenuItem
                onClick={() => handleBacktoAttendedPopup(params.row.lead_id, params.row)}
            >
                <Iconify icon={'jam:refresh-reverse'} />
                Back to Attended
            </MenuItem>;
        }
        return null;
    };
    const archiveLeadMenuItem = (params) => <MenuItem
        sx={{ color: 'error.main' }}
        onClick={() => handleArchivedDetails(params.row.lead_id, params.row)}
    >
        <Iconify icon={'eva:archive-fill'} />
        Archive Lead
    </MenuItem>;
    const restoreLeadMenuItem = (params) => <MenuItem
        onClick={() => handleRestore(params.row)}
    >
        <Iconify icon={'ic:sharp-restore'} />
        Restore
    </MenuItem>;
    const markUnMarkDuplicateMenuItem = (params) => {
        if (params.row?.is_duplicate) {
            return <MenuItem
                onClick={() => handleUnMarkAsDuplicate(params.row)}
            >
                <Iconify icon={'pepicons-pop:duplicate-off'} />
                Unmark as duplicate
            </MenuItem>;
        }
        return <MenuItem
            onClick={() => handleMarkAsDuplicate(params.row)}
        >
            <Iconify icon={'ion:duplicate'} />
            Mark as duplicate
        </MenuItem>;
    };

    const completeToStartMenuItem = (params) => {
        if (tableTitle === 'Completed Leads' && params.row.status === 'CLOSED') {
            return (
                <Tooltip title='Move back to start treatment' placement='bottom' arrow>
                    <MenuItem
                        onClick={() => handleCompletedToStartTreatment(params.row)}>
                        <Iconify icon={'bxs:flag'} /> Start Treatment
                    </MenuItem>
                </Tooltip>
            );
        }
        return null;

    };

    const handleViewDetailClick = (params) => {
        dispatch(leadPage({ name: tableTitle, path: tablePath }));
        navigate(`/dashboard/practice/${practiceId}/lead-detail/${params.row.lead_id}`);
    };

    const handleTagCellClick = (params) => {
        if (typed === 'ArchiveTable') {
            handleArchivedNavigate(params.row);
            return;
        };
        setSelectedData(params?.row);
    };
    const commonTagsProops = {
        handleTagCellClick,
        typed,
        selectData,
        handleSelect,
        reloadCounter,
        updateLeadPage,
        setTableData,
        handleArchivedNavigate
    };

    const contactCellProps = {
        anchorRef,
        open,
        setOpen,
        isDesktop,
        buttonHandleClose,
        handlePopup,
        viewDetailBtn,
        handleAttendedClick,
        handleAttendedFailedClick,
        handleAttendedPopup
    };

    const createdAtDateCellProps = {
        handleChangeDateFormat,
        showDate,
        handleNavigate,
        formatData,
        typed,
        methods,
        setValue
    };

    const columns = [
        { ...CreatedAtDateCell(createdAtDateCellProps) },
        { ...FirstNameCell() },
        { ...LastNameCell() },
        { ...EmailCell() },
        { ...phoneNumberCell },
        { ...treatmentCell },
        { ...toggleCell },
        { ...StatusCell({ onChange: handleNavigate }) },
        { ...ReferralCell({ updateLeadPage, reloadCounter, typed, setTableData }) },
        {
            field: 'contact',
            headerName: 'Action',
            sortable: false,
            minWidth: 210,
            align: 'center',
            renderCell: (params) => {
                if ((params?.row?.treatment_name === '' || null) && typed !== 'ArchiveTable' && isReportData) {
                    return (
                        <CustomSelectTreatmentCell params={params} handleNext={handleNext} />
                    );
                }
                return <ContactCell row={params?.row} {...contactCellProps} />;
            },
        },
        { ...LastContactCell({ methods, handleChangeDateFormat, setValue, handleNavigate }) },
        { ...AssigneeCell({ getAssignees, OpenAssignTask }) },
        { ...TagCell({ ...commonTagsProops }) },
        { ...ViewDetailCell({ leadPage, tableTitle, tablePath }) },
        {
            ...actionColumnData,
            getActions: (params) => [
                <div className='action-custom-cell' key={params.row.id}>
                    <DataGridTableMoreMenu
                        open={openMenu === params.row.id}
                        sx={{ width: '180px' }}
                        anchorEl={menuCurrentTarget}
                        type={params.row.status === 'CONSULTATION_BOOKED' ? 'FinancialTableRow' : ''}
                        onOpen={(event) => handleOpenMenu(event, params)}
                        onClose={() => setOpenMenuActions(null)}
                        actions={
                            <>
                                {typed !== 'ArchiveTable' ?
                                    <Box sx={tableMoreMenuBoxStyle}>
                                        <CustomViewDetailComponent handleClick={() => handleViewDetailClick(params)} />
                                        {params.row.status !== 'CLOSED' && params.row.status !== 'TREATMENT_STARTED' && params?.row?.appointments?.[0]?.status !== 'CANCELLED' && (typed !== 'ArchiveTable' && isReportData && params.row.status !== '' && <MenuItem onClick={() => params.row.status !== 'CONSULTATION_BOOKED' && setOpenMenuActions(null)}>
                                            {params.row.is_archive_pending ?
                                                <CustomArchivingButton params={params} />
                                                :
                                                params.row.status === 'CONSULTATION_BOOKED' && !params.row.is_archive_pending ?
                                                    <Box>
                                                        <CustomRecordAttendanceBtn params={params} handleMoreMenuTogglePopper={handleMoreMenuTogglePopper} moreMenuOpen={moreMenuOpen} ref={anchorMoreMenuRef} />
                                                        <Popper
                                                            sx={{
                                                                zIndex: 1399,
                                                                marginLeft: '10px !important'
                                                            }}
                                                            open={isMoreMenuPopperOpen(params.row.lead_id)}
                                                            anchorEl={anchorMoreMenuRef.current}
                                                            role={undefined}
                                                            transition
                                                            placement={isDesktop ? 'left-start' : 'right-start'}
                                                        >
                                                            {({ TransitionProps }) => (
                                                                <Grow
                                                                    {...TransitionProps}
                                                                >
                                                                    <Paper>
                                                                        <ClickAwayListener onClickAway={buttonHandleClose}>
                                                                            <MenuList id='split-button-menu' autoFocusItem sx={menuListStyle}>
                                                                                <MenuItem
                                                                                    key='Attended'
                                                                                    onClick={() => { setOpen(false); setMoreMenuOpen(false); setOpenMenuActions(null); handleAttendedClick(params.row.lead_id, params.row); }}
                                                                                    sx={{ borderRadius: '8px', minHeight: '40px !important', color: slateGray }}
                                                                                >
                                                                                    <Iconify style={{ color: slateGray, marginRight: 15 }} width={20} height={20} icon={'akar-icons:check'} /> Attended
                                                                                </MenuItem>
                                                                                <MenuItem
                                                                                    key='Failed to attend'
                                                                                    onClick={() => { setOpen(false); setMoreMenuOpen(false); setOpenMenuActions(null); handleAttendedFailedClick(params.row.lead_id, params.row); }}
                                                                                    sx={{ borderRadius: '8px', minHeight: '40px !important', color: slateGray }}
                                                                                >
                                                                                    <Iconify style={{ color: slateGray, marginRight: 15 }} width={20} height={20} icon={'ci:close-big'} /> Failed to attend
                                                                                </MenuItem>
                                                                            </MenuList>
                                                                        </ClickAwayListener>
                                                                    </Paper>
                                                                </Grow>
                                                            )}
                                                        </Popper>
                                                    </Box>
                                                    : params.row.status === 'CONSULTATION_ATTENDED' && !params.row.is_archive_pending ?
                                                        <CustomFollowUpBtn params={params} handleAttendedPopup={handleAttendedPopup} />
                                                        : params.row.status === 'CLOSED' && !params.row.is_archive_pending || params.row.status === 'TREATMENT_STARTED' && !params.row.is_archive_pending ?
                                                            <CustomCompleteTreatmentBtn params={params} viewDetailBtn={viewDetailBtn} />
                                                            : params.row.treatment_name === '' || null ? '' :
                                                                <CustomFollowUpBtnV2 params={params} handlePopup={handlePopup} />
                                            }
                                        </MenuItem>)}
                                        <StartTreatmentMenuItem handleStartTreatment={handleStartTreatment} params={params} />
                                        <CompleteTreatmentMenuItem handleCompleteTreatment={handleCompleteTreatment} params={params} />
                                        {params?.row?.appointments?.[0]?.status === 'CANCELLED' ? null : editAppointmentMenuItem(params)}
                                        {markUnMarkDuplicateMenuItem(params)}
                                        {backToAttendedMenuItem(params)}
                                        {archiveLeadMenuItem(params)}
                                    </Box>
                                    :
                                    restoreLeadMenuItem(params)
                                }
                            </>
                        }
                    />
                </div>
            ]
        }
    ];

    const getMultiTreatmentColumns = (column) => {
        if (tableTitle === 'Consultation Attended Leads' || tableTitle === 'Treatment Started Leads') {
            return commonColumns?.map((ele) => column.find((element) => element.field === ele));
        }
        return commonColumns?.filter((ele) => ele !== 'price').map((ele) => column.find((element) => element.field === ele));
    };

    const multiTreatmentColumn = [
        {
            ...treatmentFieldColumnData,
            cellClassName: (params) => getTreatmentCellClassName(params?.row?.treatmentBgColor),
            renderCell: (params) =>
                <Tooltip
                    title={`${params?.row?.treatment_name || ''} ${params?.row?.leadTreatmentSpecification?.teeth_count
                        ? `(x${params?.row?.leadTreatmentSpecification?.teeth_count})` : ''}`}
                    placement='bottom'
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                >
                    <Box onClick={(e) => handleTreatmentClick(e, params?.row)} sx={tableCellInnerBoxStyle}>
                        <Typography
                            variant='body2'
                            sx={{ ...treatmentNameCellTypoStyle, color: getTextColor(params?.row?.treatmentBgColor) }}
                        >
                            <span style={treatmentNameStyle(params?.row?.treatmentData?.length > 1)}>
                                {params?.row?.treatment_name || ''}
                                {'  '}
                                {params?.row?.leadTreatmentSpecification?.teeth_count ? `(x${params?.row?.leadTreatmentSpecification?.teeth_count})` : ''}
                            </span>
                        </Typography>
                    </Box></Tooltip>,
        },
        { ...TreatmentPriceCell({ renderPriceEditInputCell, renderPriceCell }) },
        { ...StatusCell({ onChange: typed !== 'ArchiveTable' ? handleNavigate : handleArchivedNavigate }) },
        { ...DepositPendingCell({ updateLeadPage, reloadCounter, typed, handleSetTableData }) },
        { ...ApptMethodCell({ typed, handleSetTableData }) },
        { ...ApptTypeCell({ handleSetTableData, reloadCounter, typed, updateLeadPage }) },
        { ...DateOfAppointmentCell({ ...attendedDateCellProps }) },
        { ...BookedWithCell({ openAppointmentAssignTaskFuc, typed, updateLeadPage, reloadCounter }) },
        {
            ...actionColumnData,
            getActions: (params) => [
                <div className='action-custom-cell' key={params.row.id}>
                    <DataGridTableMoreMenu
                        open={openMenu === params.row.id}
                        sx={{ width: '180px' }}
                        anchorEl={menuCurrentTarget}
                        type={params.row.status === 'CONSULTATION_BOOKED' ? 'FinancialTableRow' : ''}
                        onOpen={(event) => handleOpenMenu(event, params)}
                        onClose={() => setOpenMenuActions(null)}
                        actions={typed !== 'ArchiveTable' ?
                            <Box sx={tableMoreMenuBoxStyle}>
                                <CustomViewDetailComponent handleClick={() => handleViewDetailClick(params)} />
                                {completeToStartMenuItem(params)}
                                {params.row.status !== 'CLOSED' && params.row.status !== 'TREATMENT_STARTED' && (typed !== 'ArchiveTable' && isReportData && <MenuItem onClick={() => params.row.status !== 'CONSULTATION_BOOKED' && setOpenMenuActions(null)}>
                                    {
                                        params.row.status === 'CONSULTATION_BOOKED' ?
                                            <Box>
                                                <CustomRecordAttendanceBtn params={params} handleMoreMenuTogglePopper={handleMoreMenuTogglePopper} moreMenuOpen={moreMenuOpen} ref={anchorMoreMenuRef} />
                                                <Popper
                                                    sx={{
                                                        zIndex: 1399,
                                                        marginLeft: '10px !important'
                                                    }}
                                                    open={isMoreMenuPopperOpen(params.row.lead_id)}
                                                    anchorEl={anchorMoreMenuRef.current}
                                                    role={undefined}
                                                    transition
                                                    placement={isDesktop ? 'left-start' : 'right-start'}
                                                >
                                                    {({ TransitionProps }) => (
                                                        <Grow
                                                            {...TransitionProps}
                                                        >
                                                            <Paper>
                                                                <ClickAwayListener onClickAway={buttonHandleClose}>
                                                                    <MenuList id='split-button-menu' autoFocusItem sx={menuListStyle}>
                                                                        <MenuItem
                                                                            key='Attended'
                                                                            onClick={() => { setOpen(false); setMoreMenuOpen(false); setOpenMenuActions(null); handleAttendedClick(params.row.lead_id, params.row); }}
                                                                            sx={{ borderRadius: '8px', minHeight: '40px !important', color: slateGray }}
                                                                        >
                                                                            <Iconify style={{ color: slateGray, marginRight: 15 }} width={20} height={20} icon={'akar-icons:check'} /> Attended
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            key='Failed to attend'
                                                                            onClick={() => { setOpen(false); setMoreMenuOpen(false); setOpenMenuActions(null); handleAttendedFailedClick(params.row.lead_id, params.row); }}
                                                                            sx={{ borderRadius: '8px', minHeight: '40px !important', color: slateGray }}
                                                                        >
                                                                            <Iconify style={{ color: slateGray, marginRight: 15 }} width={20} height={20} icon={'ci:close-big'} /> Failed to attend
                                                                        </MenuItem>
                                                                    </MenuList>
                                                                </ClickAwayListener>
                                                            </Paper>
                                                        </Grow>
                                                    )}
                                                </Popper>
                                            </Box>
                                            : params.row.status === 'CONSULTATION_ATTENDED' ?
                                                <CustomFollowUpBtn params={params} handleAttendedPopup={handleAttendedPopup} />
                                                : params.row.status === 'CLOSED' && params.row.status === 'TREATMENT_STARTED' ?
                                                    <CustomCompleteTreatmentBtn params={params} viewDetailBtn={viewDetailBtn} />
                                                    : params.row.treatment_name === '' || null ? '' :
                                                        <CustomFollowUpBtnV2 params={params} handlePopup={handlePopup} />
                                    }
                                </MenuItem>)}
                                {(params.row.status === 'TREATMENT_STARTED') &&
                                    <MenuItem
                                        onClick={() => handleCompleteTreatment(params.row)}
                                    >
                                        <Iconify icon={'fluent:cloud-sync-complete-24-filled'} />
                                        Complete Treatment
                                    </MenuItem>
                                }
                            </Box>
                            :
                            <>
                                <MenuItem
                                    onClick={() => handleRestore(params.row)}
                                >
                                    <Iconify icon={'ic:sharp-restore'} />
                                    Restore
                                </MenuItem>
                                <MenuItem
                                    onClick={() => archiveViewDetails(params)}
                                >
                                    <Iconify icon={'mdi:user-box'} />
                                    View Details
                                </MenuItem>
                            </>
                        }
                    />
                </div>
            ]
        }
    ];

    const newLeadsColumns = [
        { ...CreatedAtDateCell(createdAtDateCellProps) },
        { ...FirstNameCell() },
        { ...LastNameCell() },
        { ...EmailCell() },
        { ...phoneNumberCell },
        { ...treatmentCell },
        { ...ReferralCell({ updateLeadPage, reloadCounter, typed, setTableData }) },
        { ...toggleCell },
        {
            field: 'contact',
            headerName: 'Action',
            sortable: false,
            minWidth: 180,
            align: 'center',
            renderCell: (params) => {
                if ((params?.row?.treatment_name === '' || null) && typed !== 'ArchiveTable' && isReportData) {
                    return (
                        <CustomSelectTreatmentCell params={params} handleNext={handleNext} />
                    );
                }
                return <ContactCell row={params?.row} {...contactCellProps} />;
            },
        },
        { ...LastContactCell({ methods, handleChangeDateFormat, setValue, handleNavigate }) },
        { ...AssigneeCell({ getAssignees, OpenAssignTask }) },
        { ...TagCell({ ...commonTagsProops }) },
        { ...ViewDetailCell({ leadPage, tableTitle, tablePath }) },
        {
            ...actionColumnData,
            getActions: (params) => [
                <div className='action-custom-cell' key={params.row.id}>
                    <DataGridTableMoreMenu
                        open={openMenu === params.row.id}
                        sx={{ width: '180px' }}
                        anchorEl={menuCurrentTarget}
                        type={params.row.status === 'CONSULTATION_BOOKED' ? 'FinancialTableRow' : ''}
                        onOpen={(event) => handleOpenMenu(event, params)}
                        onClose={() => setOpenMenuActions(null)}
                        actions={
                            <>
                                {typed !== 'ArchiveTable' ?
                                    <Box sx={tableMoreMenuBoxStyle}>
                                        <CustomViewDetailComponent handleClick={() => handleViewDetailClick(params)} />
                                        {params.row.status !== 'CLOSED' && params.row.status !== '' && (typed !== 'ArchiveTable' && isReportData && <MenuItem onClick={() => setOpenMenuActions(null)}>
                                            {params.row.is_archive_pending ?
                                                <CustomArchivingButton params={params} />
                                                :
                                                params.row.status === 'CONSULTATION_BOOKED' && !params.row.is_archive_pending ?
                                                    <Box>
                                                        <CustomRecordAttendanceBtn params={params} handleMoreMenuTogglePopper={handleMoreMenuTogglePopper} moreMenuOpen={open} ref={anchorMoreMenuRef} />
                                                        <Popper
                                                            sx={{
                                                                zIndex: 1,
                                                                marginLeft: '10px !important'
                                                            }}
                                                            open={isMoreMenuPopperOpen(params.row.lead_id)}
                                                            anchorEl={anchorMoreMenuRef.current}
                                                            role={undefined}
                                                            transition
                                                            placement={isDesktop ? 'left-start' : 'right-start'}
                                                        >
                                                            {({ TransitionProps }) => (
                                                                <Grow
                                                                    {...TransitionProps}
                                                                >
                                                                    <Paper>
                                                                        <ClickAwayListener onClickAway={buttonHandleClose}>
                                                                            <MenuList id='split-button-menu' autoFocusItem sx={menuListStyle}>
                                                                                <MenuItem
                                                                                    key='Attended'
                                                                                    onClick={() => handleAttendedClick(params.row.lead_id, params.row)}
                                                                                    sx={{ borderRadius: '8px', minHeight: '40px !important', color: slateGray }}
                                                                                >
                                                                                    <Iconify style={{ color: slateGray, marginRight: 15 }} width={20} height={20} icon={'akar-icons:check'} /> Attended
                                                                                </MenuItem>
                                                                                <MenuItem
                                                                                    key='Failed to attend'
                                                                                    onClick={() => handleAttendedFailedClick(params.row.lead_id, params.row)}
                                                                                    sx={{ borderRadius: '8px', minHeight: '40px !important', color: slateGray }}
                                                                                >
                                                                                    <Iconify style={{ color: slateGray, marginRight: 15 }} width={20} height={20} icon={'ci:close-big'} /> Failed to attend
                                                                                </MenuItem>
                                                                            </MenuList>
                                                                        </ClickAwayListener>
                                                                    </Paper>
                                                                </Grow>
                                                            )}
                                                        </Popper>
                                                    </Box>
                                                    : params.row.status === 'CONSULTATION_ATTENDED' && !params.row.is_archive_pending ?
                                                        <CustomFollowUpBtn params={params} handleAttendedPopup={handleAttendedPopup} />
                                                        : params.row.status === 'CLOSED' && !params.row.is_archive_pending || params.row.status === 'TREATMENT_STARTED' && !params.row.is_archive_pending ?
                                                            <CustomCompleteTreatmentBtn params={params} viewDetailBtn={viewDetailBtn} />
                                                            : params.row.treatment_name === '' || null ? '' :
                                                                <CustomFollowUpBtnV2 params={params} handlePopup={handlePopup} />
                                            }
                                        </MenuItem>)}
                                        <StartTreatmentMenuItem handleStartTreatment={handleStartTreatment} params={params} />
                                        <CompleteTreatmentMenuItem handleCompleteTreatment={handleCompleteTreatment} params={params} />
                                        {editAppointmentMenuItem(params)}
                                        {markUnMarkDuplicateMenuItem(params)}
                                        {completeToStartMenuItem(params)}
                                        {backToAttendedMenuItem(params)}
                                        {archiveLeadMenuItem(params)}
                                    </Box>
                                    :
                                    restoreLeadMenuItem(params)
                                }
                            </>
                        }
                    />
                </div>
            ]
        }
    ];

    const consultationBookedLeadsColumns = [
        { ...DateOfAppointmentCell({ ...attendedDateCellProps }) },
        { ...CreatedAtDateCell(createdAtDateCellProps) },
        { ...FirstNameCell() },
        { ...LastNameCell() },
        { ...EmailCell() },
        { ...DepositPendingCell({ updateLeadPage, reloadCounter, typed, handleSetTableData }) },
        { ...CustomDepositPaymentCell(selectedRow, handleDeleteTreatmentPayment) },
        { ...ApptMethodCell({ typed, handleSetTableData }) },
        { ...ApptTypeCell({ handleSetTableData, reloadCounter, typed, updateLeadPage }) },
        { ...BookedWithCell({ openAppointmentAssignTaskFuc, typed, updateLeadPage, reloadCounter }) },
        { ...phoneNumberCell },
        { ...treatmentCell },
        { ...toggleCell },
        { ...ReferralCell({ updateLeadPage, reloadCounter, typed, setTableData }) },
        { ...ContactActionCell({ ...contactCellProps, minWidth: 210 }) },
        { ...LastContactCell({ methods, handleChangeDateFormat, setValue, handleNavigate }) },
        { ...AssigneeCell({ getAssignees, OpenAssignTask }) },
        { ...TagCell({ ...commonTagsProops }) },
        { ...ViewDetailCell({ leadPage, tableTitle, tablePath, classNames: 'view_details' }) },
        {
            ...actionColumnData,
            getActions: (params) => [
                <div className='action-custom-cell' key={params.row.id}>
                    <DataGridTableMoreMenu
                        open={openMenu === params.row.id}
                        sx={{ width: '180px' }}
                        anchorEl={menuCurrentTarget}
                        type={params.row.status === 'CONSULTATION_BOOKED' ? 'FinancialTableRow' : ''}
                        onOpen={(event) => handleOpenMenu(event, params)}
                        onClose={() => setOpenMenuActions(null)}
                        actions={
                            <>
                                {typed !== 'ArchiveTable' ?
                                    <Box sx={tableMoreMenuBoxStyle}>
                                        <CustomViewDetailComponent handleClick={() => handleViewDetailClick(params)} />
                                        {params.row.status !== 'CLOSED' && (typed !== 'ArchiveTable' && isReportData && params?.row?.appointments?.[0]?.status !== 'CANCELLED' && <MenuItem onClick={() => params.row.status !== 'CONSULTATION_BOOKED' && setOpenMenuActions(null)}>
                                            {params.row.is_archive_pending ?
                                                <CustomArchivingButton params={params} />
                                                :
                                                params.row.status === 'CONSULTATION_BOOKED' && !params.row.is_archive_pending ?
                                                    <Box>
                                                        <CustomRecordAttendanceBtn params={params} handleMoreMenuTogglePopper={handleMoreMenuTogglePopper} moreMenuOpen={moreMenuOpen} ref={anchorMoreMenuRef} />
                                                        <Popper
                                                            sx={{
                                                                zIndex: 1399,
                                                                marginLeft: '10px !important'
                                                            }}
                                                            open={isMoreMenuPopperOpen(params.row.lead_id)}
                                                            anchorEl={anchorMoreMenuRef.current}
                                                            role={undefined}
                                                            transition
                                                            placement={isDesktop ? 'left-start' : 'right-start'}
                                                        >
                                                            {({ TransitionProps }) => (
                                                                <Grow
                                                                    {...TransitionProps}
                                                                >
                                                                    <Paper>
                                                                        <ClickAwayListener onClickAway={buttonHandleClose}>
                                                                            <MenuList id='split-button-menu' autoFocusItem sx={menuListStyle}>
                                                                                <MenuItem
                                                                                    key='Attended'
                                                                                    onClick={() => { setOpen(false); setMoreMenuOpen(false); setOpenMenuActions(null); handleAttendedClick(params.row.lead_id, params.row); }}
                                                                                    sx={{ borderRadius: '8px', minHeight: '40px !important', color: slateGray }}
                                                                                >
                                                                                    <Iconify style={{ color: slateGray, marginRight: 15 }} width={20} height={20} icon={'akar-icons:check'} /> Attended
                                                                                </MenuItem>
                                                                                <MenuItem
                                                                                    key='Failed to attend'
                                                                                    onClick={() => { setOpen(false); setMoreMenuOpen(false); setOpenMenuActions(null); handleAttendedFailedClick(params.row.lead_id, params.row); }}
                                                                                    sx={{ borderRadius: '8px', minHeight: '40px !important', color: slateGray }}
                                                                                >
                                                                                    <Iconify style={{ color: slateGray, marginRight: 15 }} width={20} height={20} icon={'ci:close-big'} /> Failed to attend
                                                                                </MenuItem>
                                                                            </MenuList>
                                                                        </ClickAwayListener>
                                                                    </Paper>
                                                                </Grow>
                                                            )}
                                                        </Popper>
                                                    </Box>
                                                    : params.row.status === 'CONSULTATION_ATTENDED' && !params.row.is_archive_pending ?
                                                        <CustomFollowUpBtn params={params} handleAttendedPopup={handleAttendedPopup} />
                                                        : params.row.status === 'CLOSED' && !params.row.is_archive_pending || params.row.status === 'TREATMENT_STARTED' && !params.row.is_archive_pending ?
                                                            <CustomCompleteTreatmentBtn params={params} viewDetailBtn={viewDetailBtn} />
                                                            : params.row.treatment_name === '' || null ? '' :
                                                                <CustomFollowUpBtnV2 params={params} handlePopup={handlePopup} />
                                            }
                                        </MenuItem>)}
                                        <StartTreatmentMenuItem handleStartTreatment={handleStartTreatment} params={params} />
                                        <CompleteTreatmentMenuItem handleCompleteTreatment={handleCompleteTreatment} params={params} />
                                        {params?.row?.appointments?.[0]?.status === 'CANCELLED' ? null : editAppointmentMenuItem(params)}
                                        {markUnMarkDuplicateMenuItem(params)}
                                        {backToAttendedMenuItem(params)}
                                        {archiveLeadMenuItem(params)}
                                    </Box>
                                    :
                                    restoreLeadMenuItem(params)
                                }
                            </>
                        }
                    />
                </div>
            ]
        }
    ];

    const commonMenuProps = {
        openMenu,
        menuCurrentTarget,
        handleOpenMenu,
        setOpenMenuActions,
        typed,
        handleViewDetailClick,
        isReportData,
        anchorMoreMenuRef,
        setOpen,
        open,
        handleAttendedClick,
        handleAttendedFailedClick,
        buttonHandleClose,
        handleAttendedPopup,
        viewDetailBtn,
        handlePopup,
        handleStartTreatment,
        handleCompleteTreatment,
        editAppointmentMenuItem,
        backToAttendedMenuItem,
        restoreLeadMenuItem,
    };

    const attendedColumnActionProps = {
        ...commonMenuProps,
        markUnMarkDuplicateMenuItem,
        archiveLeadMenuItem,
    };

    const consultationAttendedLeadsColumns = [
        {
            ...AttendedDateCell({
                methods,
                handleChangeDateFormat,
                setValue,
                typed,
                updateLeadPage,
                reloadCounter,
                openAppointmentAssignTaskFuc
            })
        },
        { ...CreatedAtDateCell(createdAtDateCellProps) },
        { ...FirstNameCell() },
        { ...LastNameCell() },
        { ...EmailCell() },
        { ...DepositPendingCell({ updateLeadPage, reloadCounter, typed, handleSetTableData }) },
        { ...CustomDepositPaymentCell(selectedRow, handleDeleteTreatmentPayment) },
        { ...ClincheckStatusCell({ typed, handleClinCheckStatusShow }) },
        { ...ApptMethodCell({ typed, handleSetTableData }) },
        { ...ApptTypeCell({ handleSetTableData, reloadCounter, typed, updateLeadPage }) },
        {
            field: 'booked_with',
            headerName: 'Booked with',
            width: 150,
            minWidth: 140,
            editable: false,
            sortable: false,
            renderCell: (params) => <BookedWithField
                rowData={params.row}
                OpenAssignTask={openAppointmentAssignTaskFuc}
                tableType={typed}
                updateLeadPage={updateLeadPage}
                reloadCounter={reloadCounter}
            />
        },
        { ...phoneNumberCell },
        { ...treatmentCell },
        { ...toggleCell },
        { ...TreatmentPriceCell({ renderPriceEditInputCell, renderPriceCell }) },
        { ...ReferralCell({ updateLeadPage, reloadCounter, typed, setTableData }) },
        { ...ContactActionCell({ ...contactCellProps, minWidth: 180 }) },
        { ...LastContactCell({ methods, handleChangeDateFormat, setValue, handleNavigate }) },
        { ...AssigneeCell({ getAssignees, OpenAssignTask }) },
        { ...TagCell({ ...commonTagsProops }) },
        { ...ViewDetailCell({ leadPage, tableTitle, tablePath }) },
        { ...AttendedTableColumnActions(attendedColumnActionProps) }
    ];

    const consultationClincheckLeadsColumns = [
        {
            ...AttendedDateCell({
                methods,
                handleChangeDateFormat,
                setValue,
                typed,
                updateLeadPage,
                reloadCounter,
                openAppointmentAssignTaskFuc
            })
        },
        { ...CreatedAtDateCell(createdAtDateCellProps) },
        { ...FirstNameCell() },
        { ...LastNameCell() },
        { ...EmailCell() },
        { ...DepositPendingCell({ updateLeadPage, reloadCounter, typed, handleSetTableData }) },
        { ...CustomDepositPaymentCell(selectedRow, handleDeleteTreatmentPayment) },
        { ...ClincheckStatusCell({ typed, handleClinCheckStatusShow }) },
        { ...ApptMethodCell({ typed, handleSetTableData }) },
        { ...ApptTypeCell({ handleSetTableData, reloadCounter, typed, updateLeadPage }) },
        {
            field: 'booked_with',
            headerName: 'Booked with',
            width: 150,
            minWidth: 140,
            editable: false,
            sortable: false,
            renderCell: (params) => <BookedWithField
                rowData={params.row}
                OpenAssignTask={openAppointmentAssignTaskFuc}
                tableType={typed}
                updateLeadPage={updateLeadPage}
                reloadCounter={reloadCounter}
            />
        },
        { ...phoneNumberCell },
        {
            ...treatmentFieldColumnData,
            cellClassName: (params) => getTreatmentCellClassName(params?.row?.treatmentBgColor),
            renderCell: (params) => {
                if ((params?.row?.treatment_name === '' || null) && typed !== 'ArchiveTable' && isReportData) {
                    return (
                        <IconButton
                            color='primary'
                            aria-label='upload picture'
                            component='label'
                            onClick={() => handleNext(params?.row)}
                            disabled={params?.row?.is_archive_pending}
                        >
                            <Iconify
                                icon='material-symbols:add-circle'
                                style={{ color: 'primary' }}
                                sx={{ mr: '0' }}
                                width={24}
                                height={24}
                            />
                        </IconButton>
                    );
                }
                return (
                    <CustomTreatmentNameCell
                        params={params}
                        handleTreatmentClick={handleTreatmentClick}
                        ref={apiRef}
                    />
                );
            },
        },
        { ...toggleCell },
        { ...TreatmentPriceCell({ renderPriceEditInputCell, renderPriceCell }) },
        { ...ReferralCell({ updateLeadPage, reloadCounter, typed, setTableData }) },
        { ...ContactActionCell({ ...contactCellProps, minWidth: 180 }) },
        { ...LastContactCell({ methods, handleChangeDateFormat, setValue, handleNavigate }) },
        { ...AssigneeCell({ getAssignees, OpenAssignTask }) },
        { ...TagCell({ ...commonTagsProops }) },
        { ...ViewDetailCell({ leadPage, tableTitle, tablePath }) },
        { ...AttendedTableColumnActions(attendedColumnActionProps) }
    ];

    const treatmentStartedLeadsColumns = [
        {
            ...TransitionDateCell({
                typed,
                showTransitionDate,
                updateLeadPage,
                reloadCounter,
                getTransitionDate
            })
        },
        { ...CreatedAtDateCell(createdAtDateCellProps) },
        { ...FirstNameCell() },
        { ...LastNameCell() },
        { ...EmailCell() },
        { ...CustomDepositPaymentCell(selectedRow, handleDeleteTreatmentPayment) },
        {
            ...ConsultationDateCell({
                typed,
                openAppointmentAssignTaskFuc,
                methods,
                setValue,
                handleChangeDateFormat,
                updateLeadPage,
                reloadCounter,
            })
        },
        { ...ConsultationBookedWithCell({ openAppointmentAssignTaskFuc }) },
        { ...DateOfAppointmentCell({ ...attendedDateCellProps }) },
        { ...BookedWithCell({ openAppointmentAssignTaskFuc, typed, updateLeadPage, reloadCounter }) },
        { ...phoneNumberCell },
        { ...treatmentCell },
        { ...toggleCell },
        { ...TreatmentPriceCell({ renderPriceEditInputCell, renderPriceCell }) },
        { ...ReferralCell({ updateLeadPage, reloadCounter, typed, setTableData }) },
        { ...ContactActionCell(contactCellProps) },
        { ...LastContactCell({ methods, handleChangeDateFormat, setValue, handleNavigate }) },
        { ...AssigneeCell({ getAssignees, OpenAssignTask }) },
        { ...TagCell({ ...commonTagsProops }) },
        { ...ViewDetailCell({ leadPage, tableTitle, tablePath }) },
        {
            ...StartedTableColumnActions({
                ...commonMenuProps,
                handleAddAppointment
            })
        }
    ];

    const archiveTableProps = {
        methods,
        handleChangeDateFormat,
        setValue,
        handleArchivedNavigate,
        typed,
        handleTreatmentClick,
        toggleCell,
        handleRestore,
        commonTagsProops,
        archiveViewDetails,
        openMenu,
        menuCurrentTarget,
        handleOpenMenu,
        setOpenMenuActions,
        isReportData,
        anchorMoreMenuRef,
        setOpen,
        open,
        handleAttendedClick,
        buttonHandleClose,
        handleAttendedFailedClick,
        handleAttendedPopup,
        viewDetailBtn,
        handlePopup,
        handleViewDetailClick,
        handleStartTreatment,
        editAppointmentMenuItem,
        markUnMarkDuplicateMenuItem,
        backToAttendedMenuItem,
        archiveLeadMenuItem,
        apiRef,
        handleCompleteTreatment
    };

    const getLoadingState = (type) => {
        if (type === 'ConsultantClinCheckAttend') return (pageCount === undefined || archivedLoading || loadingForClincheckLead || tablePreferenceLoading || forceLoading);
        return (pageCount === undefined || archivedLoading || loadingForLead || tablePreferenceLoading || forceLoading);
    };

    const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
        setDetailPanelExpandedRowIds(
            newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
        );
    }, []);

    const getDetailPanelHeight = useCallback(() => 'auto', []);

    const handlePageChange = (pageData) => {
        setPaginationModel(pageData);
        handlePaginationChange(pageData);
    };

    const handleSortModelChange = (sortModel, details) => {
        if (sortModel?.[0]?.field || sortModel?.[0]?.sort) {
            handleSortingChange({ userId, key: sortModel?.[0]?.field, order: sortModel?.[0]?.sort, details, tableName: tableTitle });
        } else {
            setSortModel(sortModel);
            sortingApiCall('created_at', 'desc');
            UpdateTableData(typed, { order: 'desc', orderValue: 'created_at', perPage: 10, pageNumber: 0 });
            setPaginationModel({ pageSize: 10, page: 0 });
        }
    };

    const getDetailPanel = () => {
        if (isMultiTreatmentTable) return {};
        return {
            getDetailPanelContent: ({ row }) => <GetDetailPanelContent
                row={row}
                tableTitle={tableTitle}
                typed={typed}
                isReportData={isReportData}
                selectedRow={selectedRow}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                handlePaginationChange={handlePaginationChange}
                pageCount={pageCount}
                handleChangePerSize={handleChangePerSize}
                OpenAssignTask={OpenAssignTask}
                reloadCounter={reloadCounter}
                updateLeadPage={updateLeadPage}
                openBulkArchive={open}
                onSelectionModelChange={onSelectionModelChange}
                forceUpdate={forceUpdate}
                tablePath={tablePath}
                sortingApiCall={sortingApiCall}
                columnVisibilityModel={columnVisibilityModel}
                handleColumnVisibilityChange={handleColumnVisibilityChange}
                handleColumnOrderChange={handleColumnOrderChange}
                isMultiTreatmentTable
                handleReloadAPI={handleReloadAPI}
                setCurrentTreatmentData={setCurrentTreatmentData}
                openAppointmentAssignTaskFuc={openAppointmentAssignTaskFuc}
                setTableData={setTableData}
                handleSetTableData={handleSetTableData}
            />,
            getDetailPanelHeight,
            detailPanelExpandedRowIds,
            onDetailPanelExpandedRowIdsChange: handleDetailPanelExpandedRowIdsChange
        };
    };

    const userId = useGetCurrentUserId();

    const handlePinnedColumnsChange = useCallback((updatedPinnedColumns) => {
        ChangePinnedColumns({ updatedPinnedColumns, setPinnedColumns: pinProps.setPinnedColumns, userId, tableName: tableTitle });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const addDynamicColumns = (columns, choicesData) => {
        if (isMultiTreatmentTable) return columns;
        choicesData?.forEach((choice) => {
            if (choice.metadata && choice.metadata.tables && choice.metadata.tables.includes(tableTitle)) {
                columns.push({
                    field: choice.name,
                    headerName: capitalizeFirstLetter(choice.name),
                    sortable: false,
                    editable: false,
                    minWidth: 200,
                    renderCell: (params) => (
                        <ChoicesField
                            rowData={params.row}
                            updateLeadPage={updateLeadPage}
                            reloadCounter={reloadCounter}
                            tableType={typed}
                            handleSetTableData={handleSetTableData}
                            choiceValue={choice}
                            choicesData={choicesData}
                        />
                    ),
                });
            }
        });
        return columns;
    };

    const dynamicColumns = getColumns(
        addDynamicColumns(
            applyTablePreferences({
                columns: getTableColumns({
                    tableTitle,
                    newLeadsColumns,
                    consultationBookedLeadsColumns,
                    consultationAttendedLeadsColumns,
                    consultationClincheckLeadsColumns,
                    treatmentStartedLeadsColumns,
                    attendedDateCell,
                    archivedLeadsColumns: ArchivedLeadsColumns(archiveTableProps),
                    columns,
                }),
                tableName: tableTitle,
                isMultiTreatmentTable
            }), choicesData) || [], tableTitle);

    const getProps = () => {
        if (isMultiTreatmentTable) return {};
        return { onColumnWidthChange: (data) => handleColumnWidthChange({ data, tableName: tableTitle, userId }) };
    };

    return (
        <DataGridPro
            key={`${forceUpdate}`}
            checkboxSelection={!isMultiTreatmentTable}
            disableRowSelectionOnClick
            keepNonExistentRowsSelected
            rowHeight={150}
            autoHeight
            rows={tableData}
            columns={isMultiTreatmentTable ? getMultiTreatmentColumns(multiTreatmentColumn) : dynamicColumns}
            apiRef={apiRef}
            editMode='cell'
            processRowUpdate={(newRow, oldRow) => processRowUpdate(newRow, oldRow, handleSetTableData)}
            sortingMode='server'
            disableColumnMenu={isMultiTreatmentTable}
            pinnedColumns={pinProps?.pinnedColumns}
            onPinnedColumnsChange={handlePinnedColumnsChange}
            {...getProps()}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            getRowClassName={(params) => getRowClassName(params, tableTitle)}
            getRowHeight={() => 'auto'}
            pageSizeOptions={[10, 25]}
            pagination={pageCount > 10}
            paginationMode='server'
            paginationModel={paginationModel}
            className={`leads-datagrid-table ${isMultiTreatmentTable ? 'detail-panel-table' : ''}`}
            scrollbarSize={500}
            initialState={initialPaginationData}
            rowCount={pageCount}
            onRowSelectionModelChange={(newSelectionModel) => onSelectionModelChange(newSelectionModel)}
            disableColumnFilter
            disableColumnReorder={isMultiTreatmentTable}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
            onColumnOrderChange={() => handleColumnOrderChange(handleGetVisibleColumns(apiRef))}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={(newPageSize) => handleChangePerSize(newPageSize)}
            loading={getLoadingState(typed)}
            sx={leadDetailTabsGridStyle(getLoadingState())}
            {...getDetailPanel()}
            slots={{ ...DataGridSlots({ isMultiTreatmentTable, tableTitle, openBulkArchive }) }}
            hideFooterPagination={isMultiTreatmentTable}
        />
    );
};

export { NewLeadDataGrid };

NewLeadDataGrid.propTypes = {
    tableData: PropTypes.array,
    typed: PropTypes.string,
    isReportData: PropTypes.bool,
    selectedRow: PropTypes.object,
    tableTitle: PropTypes.string,
    tablePath: PropTypes.string,
    paginationModel: PropTypes.object,
    setPaginationModel: PropTypes.object,
    handlePaginationChange: PropTypes.func,
    pageCount: PropTypes.number,
    handleChangePerSize: PropTypes.func,
    OpenAssignTask: PropTypes.func,
    reloadCounter: PropTypes.func,
    updateLeadPage: PropTypes.func,
    openBulkArchive: PropTypes.func,
    onSelectionModelChange: PropTypes.func,
    forceUpdate: PropTypes.func,
    sortingApiCall: PropTypes.func,
    columnVisibilityModel: PropTypes.object,
    handleColumnVisibilityChange: PropTypes.func,
    handleColumnOrderChange: PropTypes.func,
    openAppointmentAssignTaskFuc: PropTypes.func,
    handleReloadAPI: PropTypes.func,
    setTableData: PropTypes.func,
    handleSetTableData: PropTypes.func,
    setCurrentTreatmentData: PropTypes.func,
    isMultiTreatmentTable: PropTypes.bool,
    setColumnVisibilityModel: PropTypes.func,
    pinProps: PropTypes.object,
    forceLoading: PropTypes.bool
};
