import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { m } from 'framer-motion';
// use @mui
import {
    Stack,
    MenuItem,
    IconButton,
    Box
} from '@mui/material';
// use components
import { Iconify, MenuPopover } from '../../../components';
import { varHover } from '../../../components/animate';
import { MENU_OPTIONS } from '../../../constants/SettingsMenuOptions';
import { useGetCurrentUser } from '../../../hooks';
import { getMenuOptions } from '../../../utils/settingUtils';
import { settingsPopoverIconStyle, settingsMenuPopoverStyle } from '../../../styles/SettingsMenuStyle';

export default function SettingsPopover() {
    const [open, setOpen] = useState(null);
    const { isSuperUser } = useGetCurrentUser();

    return (
        <>
            <Box
                component={m.div}
                animate={{
                    rotate: [0,0]
                }}
                transition={{
                    duration: 12,
                    ease: 'linear',
                    repeat: Infinity,
                }}
            >
                <IconButton
                    component={m.button}
                    whileTap="tap"
                    whileHover="hover"
                    variants={varHover(1.05)}
                    aria-label="settings"
                    onClick={(event) => setOpen(event.currentTarget)}
                    sx={{
                        width: 40,
                        height: 40,
                    }}
                    id='setting-icon'
                >
                    <Iconify icon="solar:settings-bold-duotone" width={24} />
                </IconButton>
            </Box>
            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={() => setOpen(null)}
                id='setting-menu'
                sx={settingsMenuPopoverStyle}
            >
                <Stack sx={{ p: 1 }}>
                    {getMenuOptions(MENU_OPTIONS, isSuperUser)?.map((option) => (
                        <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={() => setOpen(null)} className='icon' sx={{ whiteSpace: 'normal' }}>
                            <Iconify icon={option.icon} width={22} height={22} sx={settingsPopoverIconStyle} /> {option.label}
                        </MenuItem>
                    ))}
                </Stack>
            </MenuPopover>
        </>
    );
}
