import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Typography, Popover, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Iconify } from '../../../components';
import { LeadTaskReminder } from '../leadDetail';
import {
    useTabs,
} from '../../../hooks';
import { TASK_ADDITIONAL_FILTER } from '../../../constants/LeadDetailConst';
import { getLeadReminderData, setTodoListFilter } from '../../../redux/slices/lead';
import { dispatch, useSelector } from '../../../redux/store';
import { listHeaderBoxStyle, listContentBoxStyle } from '../../../styles/ContactLeadPopup';

// ------------------------------------------------------------------------------------------------------------

export default function ToDoList({ setExpandLayout, expandLayout, user, handleChange }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { todoListFilter, leadFlowReminder } = useSelector((state) => state.lead);
    const { currentTab, onChangeTab } = useTabs(todoListFilter || 'All');
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFilterTask = (event, newValue) => {
        dispatch(setTodoListFilter(newValue));
        onChangeTab(event, newValue);
    };

    const handleScroll = (e) => {
        const bottom = parseInt(e.target.scrollHeight - e.target.scrollTop, 10) === e.target.clientHeight;
        if (bottom && leadFlowReminder?.count > leadFlowReminder?.results?.length) {
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', 10, leadFlowReminder?.results?.length, 'NEW', user?.lead_id, '', '', '', '', currentTab, undefined, true));
        }
    };

    return (
        <Box>
            <Box sx={listHeaderBoxStyle}>
                <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>To-do</Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Iconify icon={'mdi:filter'} onClick={handleClick} sx={{ display: 'none' }} width={24} height={24} />
                    <Iconify icon={expandLayout ? 'fluent:arrow-swap-28-filled' : 'fluent:arrow-fit-24-filled'} onClick={() => setExpandLayout(!expandLayout)} sx={{ color: '#637381', mr: 0, cursor: 'pointer', display: 'none' }} width={24} height={24} />
                    <Iconify icon={'zondicons:close-solid'} onClick={() => handleChange('', '')} sx={{ color: '#637381', mr: 0, cursor: 'pointer' }} width={24} height={24} />
                </Box>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box sx={{ p: 1 }}>
                        <Box>
                            <Typography component={'h6'} sx={{ fontWeight: '600' }}>Filter to-do</Typography>
                        </Box>
                        <Box sx={{ mt: '8px', p: '0 36px 0 2px' }}>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={currentTab}
                                    onChange={(event, newValue) => handleFilterTask(event, newValue)}
                                >
                                    {TASK_ADDITIONAL_FILTER.map((item, index) => (
                                        <FormControlLabel key={index} value={item.value} control={<Radio />} label={item.label} sx={{ mr: 0, mb: '6px' }} />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                </Popover>
            </Box>
            <Box sx={listContentBoxStyle} onScroll={handleScroll}>
                <LeadTaskReminder type="LeadDetail" selectedLead={user} currentTab={currentTab} />
            </Box>
        </Box>
    );
};

ToDoList.propTypes = {
    setExpandLayout: PropTypes.any,
    expandLayout: PropTypes.any,
    handleChange: PropTypes.func,
    user: PropTypes.any,
};