// redux
import { dispatch, useSelector } from '../redux/store';
import {
    updateDashboardTable,
    updateNewTable,
    updateBookedTable,
    updateAttendTable,
    updateTreatmentStartedTable,
    updateArchiveTable,
    updateDashNewLeadsTable,
    updateFailedToAttendTable,
    updateClosedTable,
    updateClincheckTable,
    updateDashNewLeadsWidgetTable,
    updateNoTaskTable,
    updateAlignerTable
} from '../redux/slices/lead';

export function SelectTableTab(type) {
    const {
        dashNewContact: { count: dashNewCount },
        newContact: { count: newCount },
        dashContact: { count: dashStatus },
        attendContact: { count: attendCount },
        treatmentStarted: { category: treatmentStartedCategory }
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'DashNewLeadTable':
            return dashNewCount;
        case 'NewLeadTable':
            return newCount;
        case 'ConsultantAttend':
            return attendCount;
        case 'treatmentStarted':
            return treatmentStartedCategory || '0';
        default:
            return dashStatus;
    }
}

export function TabCount(type) {
    const {
        dashNewContact: { count: dashNewCount },
        newContact: { count: newCount },
        attendContact: { count: attendCount },
        clincheckContact: { count: clinCheckCount },
        bookedContact: { count: bookedCount },
        treatmentStarted: { category: treatmentStartedCategory },
        noTaskTableData: { count: noTaskTableCount },
        alignerTable: { count: alignerTableCount },
        dashContact: { count: dashContactTableCount },
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'DashNewLeadTable':
            return dashNewCount;
        case 'NewLeadTable':
            return newCount;
        case 'ConsultantAttend':
            return attendCount;
        case 'ConsultantClinCheckAttend':
            return clinCheckCount;
        case 'ConsultantBooked':
            return bookedCount;
        case 'treatmentStarted':
            return treatmentStartedCategory;
        case 'NoTaskTable':
            return noTaskTableCount;
        case 'treatmentStartedWithInvisalign':
            return alignerTableCount;
        case 'Dashboard':
            return dashContactTableCount;
        default:
            return null;
    }
}

export function TablePageNumber(type) {
    const {
        dashNewContact: { pageNumber: dashNewPageNumber },
        dashContact: { pageNumber: dashPageNumber },
        newContact: { pageNumber: newPageNumber },
        bookedContact: { pageNumber: bookedPageNumber },
        attendContact: { pageNumber: attendPageNumber },
        treatmentStarted: { pageNumber: treatmentStartedPageNumber },
        failedToAttendContact: { pageNumber: failedToAttendPageNumber },
        closedContact: { pageNumber: closedContactPageNumber },
        archivedContact: { pageNumber: archivedPageNumber },
        noTaskTableData: { pageNumber: noTaskTablePageNumber },
        alignerTable: { pageNumber: alignerTablePageNumber },
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'DashNewLeadTable':
            return dashNewPageNumber;
        case 'NewLeadTable':
            return newPageNumber;
        case 'ConsultantBooked':
            return bookedPageNumber;
        case 'ConsultantAttend':
            return attendPageNumber;
        case 'treatmentStarted':
            return treatmentStartedPageNumber;
        case 'FailedToAttend':
            return failedToAttendPageNumber;
        case 'Closed':
            return closedContactPageNumber;
        case 'ArchiveTable':
            return archivedPageNumber;
        case 'NoTaskTable':
            return noTaskTablePageNumber;
        case 'treatmentStartedWithInvisalign':
            return alignerTablePageNumber;
        default:
            return dashPageNumber;
    }
}

export function TablePerPage(type) {
    const {
        dashNewContact: { perPage: dashNewPerPage },
        dashContact: { perPage: dashPerPage },
        newContact: { perPage: newPerPage },
        bookedContact: { perPage: bookedPerPage },
        attendContact: { perPage: attendPerPage },
        treatmentStarted: { perPage: treatmentStartedPerPage },
        failedToAttendContact: { perPage: failedToAttendPerPage },
        closedContact: { perPage: closedContactPerPage },
        archivedContact: { perPage: archivedPerPage },
        noTaskTableData: { perPage: noTaskTablePerPage },
        alignerTable: { perPage: alignerTablePerPage },
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'DashNewLeadTable':
            return dashNewPerPage;
        case 'NewLeadTable':
            return newPerPage;
        case 'ConsultantBooked':
            return bookedPerPage;
        case 'ConsultantAttend':
            return attendPerPage;
        case 'treatmentStarted':
            return treatmentStartedPerPage;
        case 'FailedToAttend':
            return failedToAttendPerPage;
        case 'Closed':
            return closedContactPerPage;
        case 'ArchiveTable':
            return archivedPerPage;
        case 'NoTaskTable':
            return noTaskTablePerPage;
        case 'treatmentStartedWithInvisalign':
            return alignerTablePerPage;
        default:
            return dashPerPage;
    }
}

export function TableOrder(type) {
    const {
        dashNewContact: { order: dashNewOrder },
        dashContact: { order: dashOrder },
        newContact: { order: newOrder },
        bookedContact: { order: bookedOrder },
        attendContact: { order: attendOrder },
        treatmentStarted: { order: treatmentStartedOrder },
        failedToAttendContact: { order: failedToAttendOrder },
        closedContact: { order: closedContactOrder },
        noTaskTableData: { order: noTaskTableOrder },
        alignerTable: { order: alignerTableOrder },
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'DashNewLeadTable':
            return dashNewOrder;
        case 'NewLeadTable':
            return newOrder;
        case 'ConsultantBooked':
            return bookedOrder;
        case 'ConsultantAttend':
            return attendOrder;
        case 'treatmentStarted':
            return treatmentStartedOrder;
        case 'FailedToAttend':
            return failedToAttendOrder;
        case 'Closed':
            return closedContactOrder;
        case 'NoTaskTable':
            return noTaskTableOrder;
        case 'treatmentStartedWithInvisalign':
            return alignerTableOrder;
        default:
            return dashOrder;
    }
}

export function TableOrderBy(type) {
    const {
        dashNewContact: { orderValue: dashNewOrderValue },
        dashContact: { orderValue: dashOrderValue },
        newContact: { orderValue: newOrderValue },
        bookedContact: { orderValue: bookedOrderValue },
        attendContact: { orderValue: attendOrderValue },
        treatmentStarted: { orderValue: treatmentStartedOrderValue },
        failedToAttendContact: { orderValue: failedToAttendOrderValue },
        closedContact: { orderValue: closedContactOrderValue },
        noTaskTableData: { orderValue: noTaskTableOrderValue },
        alignerTable: { orderValue: alignerTableOrderValue },
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'DashNewLeadTable':
            return dashNewOrderValue;
        case 'NewLeadTable':
            return newOrderValue;
        case 'ConsultantBooked':
            return bookedOrderValue;
        case 'ConsultantAttend':
            return attendOrderValue;
        case 'treatmentStarted':
            return treatmentStartedOrderValue;
        case 'FailedToAttend':
            return failedToAttendOrderValue;
        case 'Closed':
            return closedContactOrderValue;
        case 'NoTaskTable':
            return noTaskTableOrderValue;
        case 'treatmentStartedWithInvisalign':
            return alignerTableOrderValue;
        default:
            return dashOrderValue;
    }
}

export function TableSearch(type) {
    const {
        dashNewContact: { search: dashNewSearch },
        dashContact: { search: dashSearch },
        newContact: { search: newSearch },
        bookedContact: { search: bookedSearch },
        failedToAttendContact: { search: failedToAttendContactSearch },
        closedContact: { search: closedContactSearch },
        attendContact: { search: attendSearch },
        treatmentStarted: { search: treatmentStartedSearch },
        archivedContact: { search: archivedContactSearch },
        clincheckContact: { search: clincheckContactSearch },
        noTaskTableData: { search: noTaskTableSearch },
        alignerTable: { search: alignerTableSearch },
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'DashNewLeadTable':
            return dashNewSearch;
        case 'NewLeadTable':
            return newSearch;
        case 'ConsultantBooked':
            return bookedSearch;
        case 'FailedToAttend':
            return failedToAttendContactSearch;
        case 'Closed':
            return closedContactSearch;
        case 'ConsultantAttend':
            return attendSearch;
        case 'ConsultantClinCheckAttend':
            return clincheckContactSearch;
        case 'treatmentStarted':
            return treatmentStartedSearch;
        case 'ArchiveTable':
            return archivedContactSearch;
        case 'NoTaskTable':
            return noTaskTableSearch;
        case 'treatmentStartedWithInvisalign':
            return alignerTableSearch;
        default:
            return dashSearch;
    }
}

export function TableTreatment(type) {
    const {
        dashNewContact: { treatment: dashNewTreatment },
        dashContact: { treatment: dashTreatment },
        newContact: { treatment: newTreatment },
        bookedContact: { treatment: bookedTreatment },
        attendContact: { treatment: attendTreatment },
        clincheckContact: { treatment: clincheckAttendTreatment },
        treatmentStarted: { treatment: treatmentStartedTreatment },
        failedToAttendContact: { treatment: failedToAttendTreatment },
        closedContact: { treatment: closedContactTreatment },
        archivedContact: { treatment: archivedContactTreatment },
        noTaskTableData: { treatment: noTaskTableTreatment },
        alignerTable: { treatment: alignerTableTreatment },
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'DashNewLeadTable':
            return dashNewTreatment;
        case 'NewLeadTable':
            return newTreatment;
        case 'ConsultantBooked':
            return bookedTreatment;
        case 'ConsultantAttend':
            return attendTreatment;
        case 'ConsultantClinCheckAttend':
            return clincheckAttendTreatment;
        case 'treatmentStarted':
            return treatmentStartedTreatment;
        case 'FailedToAttend':
            return failedToAttendTreatment;
        case 'Closed':
            return closedContactTreatment;
        case 'ArchiveTable':
            return archivedContactTreatment;
        case 'NoTaskTable':
            return noTaskTableTreatment;
        case 'treatmentStartedWithInvisalign':
            return alignerTableTreatment;
        default:
            return dashTreatment;
    }
}
export function TableAssignees(type) {
    const {
        dashNewContact: { assignee: dashNewAssignee },
        dashContact: { assignee: dashAssignee },
        newContact: { assignee: newAssignee },
        bookedContact: { assignee: bookedAssignee },
        attendContact: { assignee: attendAssignee },
        clincheckContact: { assignee: clincheckAssignee },
        treatmentStarted: { assignee: treatmentStartedAssignee },
        failedToAttendContact: { assignee: failedToAttendAssignee },
        closedContact: { assignee: closedContactAssignee },
        archivedContact: { assignee: archivedContactAssignee },
        noTaskTableData: { assignee: noTaskTableAssignee },
        alignerTable: { assignee: alignerTableAssignee },
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'DashNewLeadTable':
            return dashNewAssignee;
        case 'NewLeadTable':
            return newAssignee;
        case 'ConsultantBooked':
            return bookedAssignee;
        case 'ConsultantAttend':
            return attendAssignee;
        case 'ConsultantClinCheckAttend':
            return clincheckAssignee;
        case 'treatmentStarted':
            return treatmentStartedAssignee;
        case 'FailedToAttend':
            return failedToAttendAssignee;
        case 'Closed':
            return closedContactAssignee;
        case 'ArchiveTable':
            return archivedContactAssignee;
        case 'NoTaskTable':
            return noTaskTableAssignee;
        case 'treatmentStartedWithInvisalign':
            return alignerTableAssignee;
        default:
            return dashAssignee;
    }
}

export function TableFilterDate(type) {
    const {
        dashNewContact: { date: dashNewDate },
        dashNewContactForWidget: { date: dashNewDateForWidget },
        dashContact: { date: dashDate },
        newContact: { date: newDate },
        bookedContact: { date: bookedDate },
        failedToAttendContact: { date: failedToAttendContactDate },
        closedContact: { date: closedContactDate },
        attendContact: { date: attendDate },
        treatmentStarted: { date: treatmentStartedDate },
        archivedContact: { date: archivedContactedDate },
        clincheckContact: { date: clincheckContactedDate },
        noTaskTableData: { date: noTaskTableContactedDate },
        alignerTable: { date: alignerTableContactedDate },
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'DashNewLeadTable':
            return ((dashNewDate.length > 0 && dashNewDate) || null);
        case 'DashNewLeadTableForWidget':
            return ((dashNewDateForWidget.length > 0 && dashNewDateForWidget) || null);
        case 'NewLeadTable':
            return ((newDate.length > 0 && newDate) || null);
        case 'ConsultantBooked':
            return ((bookedDate.length > 0 && bookedDate) || null);
        case 'FailedToAttend':
            return ((failedToAttendContactDate.length > 0 && failedToAttendContactDate) || null);
        case 'Closed':
            return ((closedContactDate.length > 0 && closedContactDate) || null);
        case 'ConsultantAttend':
            return ((attendDate.length > 0 && attendDate) || null);
        case 'ConsultantClinCheckAttend':
            return ((clincheckContactedDate.length > 0 && clincheckContactedDate) || null);
        case 'treatmentStarted':
            return ((treatmentStartedDate.length > 0 && treatmentStartedDate) || null);
        case 'ArchiveTable':
            return ((archivedContactedDate.length > 0 && archivedContactedDate) || null);
        case 'NoTaskTable':
            return ((noTaskTableContactedDate.length > 0 && noTaskTableContactedDate) || null);
        case 'treatmentStartedWithInvisalign':
            return ((alignerTableContactedDate.length > 0 && alignerTableContactedDate) || null);;
        default:
            return ((dashDate.length > 0 && dashDate) || null);
    }
}

export function TableStatus(type) {
    const {
        dashNewContact: { status: dashNewStatus },
        dashContact: { count: dashStatus },
        newContact: { status: newStatus },
        bookedContact: { status: bookedStatus },
        attendContact: { status: attendStatus },
        treatmentStarted: { status: treatmentStartedStatus },
        failedToAttendContact: { status: failedStatus },
        closedContact: { status: closedContactStatus },
        noTaskTableData: { status: noTaskTableStatus },
        alignerTable: { status: alignerTableStatus },
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'DashNewLeadTable':
            return dashNewStatus;
        case 'NewLeadTable':
            return newStatus;
        case 'ConsultantBooked':
            return bookedStatus;
        case 'ConsultantAttend':
            return attendStatus;
        case 'treatmentStarted':
            return treatmentStartedStatus;
        case 'FailedToAttend':
            return failedStatus;
        case 'Closed':
            return closedContactStatus;
        case 'NoTaskTable':
            return noTaskTableStatus;
        case 'treatmentStartedWithInvisalign':
            return alignerTableStatus;
        default:
            return dashStatus;
    }
}

export function TableTags(type) {
    const {
        dashNewContact: { tag: dashNewTags },
        dashContact: { tag: dashTags },
        newContact: { tag: newTags },
        bookedContact: { tag: bookedTags },
        closedContact: { tag: closedContactTags },
        failedToAttendContact: { tag: failedToAttendContactTags },
        attendContact: { tag: attendTags },
        treatmentStarted: { tag: treatmentStartedTags },
        archivedContact: { tag: archivedContactTags },
        clincheckContact: { tag: clincheckAttendedContactTags },
        noTaskTableData: { tag: noTaskTableContactTags },
        alignerTable: { tag: alignerTableContactTags },
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'DashNewLeadTable':
            return dashNewTags;
        case 'NewLeadTable':
            return newTags;
        case 'ConsultantBooked':
            return bookedTags;
        case 'FailedToAttend':
            return failedToAttendContactTags;
        case 'Closed':
            return closedContactTags;
        case 'ConsultantAttend':
            return attendTags;
        case 'ConsultantClinCheckAttend':
            return clincheckAttendedContactTags;
        case 'treatmentStarted':
            return treatmentStartedTags;
        case 'ArchiveTable':
            return archivedContactTags;
        case 'NoTaskTable':
            return noTaskTableContactTags;
        case 'treatmentStartedWithInvisalign':
            return alignerTableContactTags;
        default:
            return dashTags;
    }
}

export function TableFilterTags(type) {
    const {
        dashNewContact: { filterTags: dashNewFilterTags },
        dashContact: { filterTags: dashFilterTags },
        newContact: { filterTags: newFilterTags },
        bookedContact: { filterTags: bookedFilterTags },
        failedToAttendContact: { filterTags: failedToAttendContactFilterTags },
        closedContact: { filterTags: closedContactFilterTags },
        attendContact: { filterTags: attendFilterTags },
        treatmentStarted: { filterTags: treatmentStartedFilterTags },
        archivedContact: { filterTags: archivedContactFilterTags },
        clincheckContact: { filterTags: clincheckContactFilterTags },
        noTaskTableData: { filterTags: noTaskTableFilterTags },
        alignerTable: { filterTags: alignerTableFilterTags },
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'DashNewLeadTable':
            return dashNewFilterTags;
        case 'NewLeadTable':
            return newFilterTags;
        case 'ConsultantBooked':
            return bookedFilterTags;
        case 'FailedToAttend':
            return failedToAttendContactFilterTags;
        case 'Closed':
            return closedContactFilterTags;
        case 'ConsultantAttend':
            return attendFilterTags;
        case 'ConsultantClinCheckAttend':
            return clincheckContactFilterTags;
        case 'treatmentStarted':
            return treatmentStartedFilterTags;
        case 'ArchiveTable':
            return archivedContactFilterTags;
        case 'NoTaskTable':
            return noTaskTableFilterTags;
        case 'treatmentStartedWithInvisalign':
            return alignerTableFilterTags;
        default:
            return dashFilterTags;
    }
}

export function TableDepositPending(type) {
    const {
        attendContact: { depositPendingStatus: attendedDepositStatus },
        bookedContact: { depositPendingStatus: bookedDepositStatus },
        clincheckContact: { depositPendingStatus: clincheckDepositStatus },
    } = useSelector((state) => state.lead);
    switch (type) {
        case 'ConsultantAttend':
            return attendedDepositStatus;
        case 'ConsultantClinCheckAttend':
            return clincheckDepositStatus;
        case 'ConsultantBooked':
            return bookedDepositStatus;
        default:
            return '';
    }
}
export function TableReferral(type) {
    const {
        dashNewContact: { referral: dashNewReferral },
        dashContact: { referral: dashReferral },
        newContact: { referral: newReferral },
        bookedContact: { referral: bookedReferral },
        failedToAttendContact: { referral: failedToAttendContactReferral },
        closedContact: { referral: closedContactReferral },
        attendContact: { referral: attendReferral },
        treatmentStarted: { referral: treatmentStartedReferral },
        archivedContact: { referral: archivedContactReferral },
        clincheckContact: { referral: clincheckAttendedReferral },
        noTaskTableData: { referral: noTaskTableReferral },
        alignerTable: { referral: alignerTableReferral },
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'DashNewLeadTable':
            return dashNewReferral;
        case 'NewLeadTable':
            return newReferral;
        case 'ConsultantBooked':
            return bookedReferral;
        case 'FailedToAttend':
            return failedToAttendContactReferral;
        case 'Closed':
            return closedContactReferral;
        case 'ConsultantAttend':
            return attendReferral;
        case 'ConsultantClinCheckAttend':
            return clincheckAttendedReferral;
        case 'treatmentStarted':
            return treatmentStartedReferral;
        case 'ArchiveTable':
            return archivedContactReferral;
        case 'NoTaskTable':
            return noTaskTableReferral;
        case 'treatmentStartedWithInvisalign':
            return alignerTableReferral;
        default:
            return dashReferral;
    }
}

export function TableCategory(type) {
    const {
        treatmentStarted: { category: treatmentStartedCategory }
    } = useSelector((state) => state.lead);

    return type === 'treatmentStarted' ? treatmentStartedCategory : null;
}

export function TableCategoryName(type) {
    const {
        treatmentStarted: { categoryName: treatmentStartedCategoryName }
    } = useSelector((state) => state.lead);

    return type === 'treatmentStarted' ? treatmentStartedCategoryName : null;
}

export function TableBookedWith(type) {
    const {
        bookedContact: { bookedWith: bookedBookedWith },
        attendContact: { bookedWith: attendBookedWith },
        clincheckContact: { bookedWith: clincheckBookedWith },
        treatmentStarted: { bookedWith: treatmentStartedBookedWith },
        alignerTable: { bookedWith: alignerTableBookedWith },
    } = useSelector((state) => state.lead);

    switch (type) {
        case 'ConsultantBooked':
            return bookedBookedWith;
        case 'ConsultantAttend':
            return attendBookedWith;
        case 'ConsultantClinCheckAttend':
            return clincheckBookedWith;
        case 'treatmentStarted':
            return treatmentStartedBookedWith;
        case 'treatmentStartedWithInvisalign':
            return alignerTableBookedWith;
        default:
            return '';
    }
}
export function TableConsultationBookedWith(type) {
    const {
        treatmentStarted: { consultationBookedWith, apptType },
    } = useSelector((state) => state.lead);

    if (type !== 'treatmentStarted') return [];

    return { consultationBookedWith, apptType };
}

export function UpdateTableData(type, data) {

    switch (type) {
        case 'DashNewLeadTable':
            dispatch(updateDashNewLeadsTable({ ...data }));
            break;
        case 'DashNewLeadTableForWidget':
            dispatch(updateDashNewLeadsWidgetTable({ ...data }));
            break;
        case 'NewLeadTable':
            dispatch(updateNewTable({ ...data }));
            break;
        case 'ConsultantBooked':
            dispatch(updateBookedTable({ ...data }));
            break;
        case 'ConsultantAttend':
            dispatch(updateAttendTable({ ...data }));
            break;
        case 'ConsultantClinCheckAttend':
            dispatch(updateClincheckTable({ ...data }));
            break;
        case 'treatmentStarted':
            dispatch(updateTreatmentStartedTable({ ...data }));
            break;
        case 'ArchiveTable':
            dispatch(updateArchiveTable({ ...data }));
            break;
        case 'FailedToAttend':
            dispatch(updateFailedToAttendTable({ ...data }));
            break;
        case 'Closed':
            dispatch(updateClosedTable({ ...data }));
            break;
        case 'NoTaskTable':
            dispatch(updateNoTaskTable({ ...data }));
            break;
        case 'treatmentStartedWithInvisalign':
            dispatch(updateAlignerTable({ ...data }));
            break;
        default:
            dispatch(updateDashboardTable({ ...data }));
            break;
    }
}

export function getMatchedCount(data) {
    const rootStatus = data?.status;
    const leadContactedCounts = data?.lead_contacted_counts || [];
    const matchedCountObject = leadContactedCounts?.find((item) => item.status === rootStatus);
    return matchedCountObject ? matchedCountObject.count : 0;
}